export default {
  user: {
    userLogin: {
      balance: 'Balance',
      star: 'Star',
      shareAndEarn: 'Share & Earn',
      depositFiat: 'Deposit USD',
      depositCrypto: 'Deposit crypto',
      transfer: 'Transfer',
      withdrawFiat: 'Withdraw USD',
      withdrawCrypto: 'Withdraw crypto',
      withdrawGiftCards: 'Withdraw as gift card',
      cryptoSwap: 'Crypto swap',
    },
    accountActivities: 'Account Activity',
    prices: 'Pricing & Service times',
    settings: 'Settings',
    logout: 'Sign out',
    memberShip: 'MCT Membership',
    prizeWheel: 'MCT Prize Wheel',
    login: {
      popupTitle: 'Sign in to MyConstant',
      popupTitleMobile: 'Sign in',
      loginSocialTitle: 'Log in instantly',
      loginEmailTitle: 'or log in with email',
      email: 'Email',
      password: 'Password',
      forgetPassword: 'Forgot password?',
      loginButton: 'Log in',
      registerText: "Don't have an account?",
      registerButton: 'Sign up for free',
      requiredUsername: 'Please enter your email',
      requiredPassword: 'Please enter your password',
      loginFailure: 'Please make sure your email and password are correct.',
      username: 'Your email',
      loginFailureCaptchaV2: "Please check I'm not a robot.",
      notValidUsername: 'Invalid email address',
      twoFaRequired: '2FA authentication is required for all users with balance over 1000 USD',
      loginFailed: 'Failed while logging in, please try again',
      letSignIn: 'Let’s Sign You In',
      welcomeBack: 'Welcome back, you’ve been missed!',
    },
    forgetPassword: {
      title: 'Forgot your password?',
      submitButton: 'Submit',
      description: 'Enter the email you originally signed up with. If it seems to be taking a while, please check your spam folder.',
      requiredUsername: 'Email is required',
      username: 'Your email',
      notValidUsername: 'Invalid email address',
      resetPasswordIntro: 'We have sent a link to your email. Please follow the instructions to sign in with your new password.',
      newPasswordTitle: 'Enter a new password for your {email} account.',
      notRecoverPass: 'I can’t recover my account using this page',
      password: 'New password',
      confirmPassword: 'Retype new password',
      requiredPassword: 'Password is required',
      notValidConfirmPassword: 'Confirm password not match'
    },
    register: {
      title: 'Create your account',
      registerSocialTitle: 'Sign up instantly',
      registerEmailTitle: 'or sign up with email',
      referrerReferralCode: 'Invitation code (optional)',
      agreementText: "By ticking this box, I agree to MyConstant's",
      agreementTextLink: 'Terms and Conditions.',
      loginText: 'Already have an account?',
      loginButton: 'Log in',
      registerButton: 'Sign up for free',
      email: 'Your email',
      password: 'Password',
      registerFailed: 'Failed while creating your account, please try again',
      passwordTooWeak: 'At least <span class="green">8 characters</span>, a capital letter, a lowercase character, and a number',
      notValidEmail: 'Invalid email address',
      requiredEmail: 'Email is required',
      requiredPassword: 'Password is required',
      invalidReferralCode: 'Invalid referral code. Please check your code and try again',
      invalidEmail: 'This email address does not exist. Please check again',
      confirmStrangeEmailMessage: 'The email address <strong>{email}</strong> looks like it contains a typo or spelling mistake. Are you sure you want to sign up with this email address?',
      popupTitle: 'Sign up to MyConstant',
      popupTitleMobile: 'Sign up',
    },
    account: {
      currentBalance: 'Current Balance',
      totalBalance: 'Total Balance',
      member: 'Member',
      membershipReward: 'MEMBERSHIP REWARDS >>',
      membership: 'MEMBERSHIP >>',
    }
  },
  registerBonus: {
    title: 'Fancy $10 to get started?',
    description: "Create your account and verify your ID to get a free $1500 Instant-access investing trial in your first month. We'll take the money back after 30 days, but you keep the interest.",
    numLeft: '{numLeft} BONUSES LEFT',
    forUsOnly: 'Promotion for US customers only',
  },
  footer: {
    referral: 'Share & Earn',
    affiliate: 'Affiliate',
    blog: 'Blog',
    blogDesc: 'News, guides, and opinion.',
    personalBudget: 'Personal budget',
    social: {
      fb: {
        link: 'https://www.facebook.com/myconstantp2p',
        title: 'Facebook'
      },
      tw: { link: 'https://twitter.com/myconstantp2p', title: 'Twitter' },
      md: { link: 'https://myconstantp2p.medium.com', title: 'Medium' },
      yt: {
        link: 'https://www.youtube.com/channel/UCedaWJPf9sgsy5JajmqBAtg',
        title: 'Youtube'
      },
      tele: { title: 'Telegram', link: 'https://t.me/constantp2p' },
      email: { title: 'Email' },
      linkedin: {
        link: 'https://www.linkedin.com/company/constantp2p/',
        title: 'Linkedin'
      },
      discord: { link: 'https://discord.gg/ncjPApdgBz', title: 'Discord' },
    },
    cta: {
      deposit: { title: '<div class="h3">Start earning interest</div>', btn: 'Make an investment' },
      signUp: { title: '<div class="h3">Open an account and<br/><strong>grow your money</strong></div>', btn: 'Open an account' },
      invest: { title: '<div class="h3">Earn passive income with<br/><strong>fully secured investments</strong></div>', btn: 'Make an investment' },
      borrow: { title: '<div class="h3">Get <strong>fixed-rate loans</strong><br/>of any amount</div>', btn: 'Borrow now' },
      invite: { title: '<div class="h3">Invite your friends<br/><strong>Earn a bonus</strong></div>', btn: 'Invite now' },
      investSignUp: { title: '<div class="h3">Invest the way you want</div>', btn: 'Sign up now' },
      borrowSignUp: { title: '<div class="h3">Borrow the way you want</div>', btn: 'Sign up now' },
    },
    company: {
      name: ' ',
      address1: ' ',
      address2: ' '
    },
    // register: '2020 MyConstant, Inc.',
    termOfService: 'Terms of Service',
    privacy: 'Privacy Policy',
    serviceAgreement: 'Loan Matching Service Agreement',
    AMLPolicy: 'AML Policy',
    kycGuidelines: 'KYC guidelines',
    openSource: 'Open Source',
    prices: 'Fee and service times',
    pricesDesc: 'Fees, schedules, and limits.',
    developers: 'Developers',
    contactUs: 'Contact us',
    phone: {
      us: {
        time: '<strong>Mon - Fri</strong> 9am - 5pm<br /><strong>Mon-Thurs</strong> 6pm - 3am PST',
        phone: '+1 646 809 8338',
        timeMobile: '<p><span>(Mon - Fri)</span> 9am - 5pm</p><p><span>(Mon - Thurs)</span> 6pm - 3am PST</p>',
      }
    },
    support: 'Help & Support',
    supportCenter: 'Support Center',
    supportCenterDesc: 'Help when you need it.',
    help: 'Help',
    getSocial: 'Download now',
    copyright: '{term} and {privacy}',
    copyright2: '{term}',
    securityAndRisk: 'Security & Risk',
    riskStatement: 'Investment Risk Statement',
    achievement: 'Achievement',
    badges: 'Your Badges',
    heading: 'Experience multi-market <br/> <span style="color:#F5A623;">peer-to-peer</span> lending.',
    follow: 'Follow',
    statistics: 'Statistics',
    statisticsDesc: 'View historical loan performance and more.',
    giftCard: 'Gift Cards',
    giftCardDesc: 'Save up to 3% at 40+ retailers.',
  },
  memberShip: {
    pointNeed2GetGold: 'You still need {value} points to reach Gold Member status',
    getBackReward: 'Because you placed your loan that brought your account to the next level of membership status on the secondary market, rewards for this level have been removed from your account. This is to prevent fraud. Get your points back to earn back your rewards.',
    intro: {
      title: 'Membership Rewards',
      details: 'Get exclusive platform benefits the more you invest or borrow',
      howItWork: 'How it works',
      yourMember: 'Your membership'
    },
    howItWorks: {
      title: 'How membership works',
      details: 'For every dollar you invest or borrow, you earn 1 membership point. Each point expires after 180 days. Once you invest or borrow the minimum amount for membership your rewards continue until your points expire. To keep your benefits, simply invest or borrow again to top up your points.',
      everyDollar: 'EVERY DOLLAR',
      borrowInvest: 'you invest or borrow',
      point: 'membership point',
      headLine: 'You invest<br />or borrow',
      endDate: '180 days',
      levelInfo: { desc: 'You need', point: '{point} points' }
    },
    memberShipLevel: {
      level1: 'Gold',
      level2: 'Platinum',
      level3: 'Diamond',
      level0: 'Member'
    },
    desc: {
      level1: '<h4>What you get:</h4>              <p><i class="fal fa-check"></i> ${referralReward} credit to you everytime you refer a friend who makes a crypto-backed loan(s) of $500 or deposits at least $10 (US citizens only).</p>              <p><i class="fal fa-check"></i> {referralFlexReward}% of all earned interest from Instant-access investing accounts of referred friends during their first year.</p>            <p><i class="fal fa-check"></i> 1,000 Membership points.</p>',
      level2: '<h4>What you get:</h4>              <p><i class="fal fa-check"></i> ${cashback} credit to your MyConstant account the first time you reach Platinum (after leaving a review on Trustpilot).</p>              <p><i class="fal fa-check"></i> ${referralReward} credit to you everytime you refer a friend who makes a crypto-backed loan(s) of $500 or deposits at least $10 (US citizens only).</p>              <p><i class="fal fa-check"></i> {referralFlexReward}% of all earned interest from Instant-access investing accounts of referred friends during their first year.</p>            ',
      level3: '<h4>What you get:</h4>              <p><i class="fal fa-check"></i> ${cashback} credit to your MyConstant account the first time you reach Diamond (after leaving a review on Trustpilot).</p>              <p><i class="fal fa-check"></i> ${referralReward} credit to you everytime you refer a friend who makes a crypto-backed loan(s) of $500 or deposits at least $10 (US citizens only).</p>              <p><i class="fal fa-check"></i> {referralFlexReward}% of all earned interest from Instant-access investing accounts of referred friends during their first year.</p>              <p><i class="fal fa-check"></i> Up to 6 ACH transfers per month.</p>              <p><i class="fal fa-check"></i> {bonus} STAR token to redeem for a {bonus}% bonus or discounted interest.</p>            '
    },
    membershipPoints: 'Your Membership Points',
    title: {
      level0: 'Member',
      level1: 'Gold',
      level2: 'Platinum',
      level3: 'Diamond'
    },
    tierProgress: 'Don’t lose your benefits',
    tierProgressDescMember: 'For every dollar you invest or borrow, you earn 1 membership point. Each point expires after 180 days.',
    remaining: '{type} ${value} in {date} days',
    history: {
      title: 'History',
      level: 'Status',
      reward: 'Reward',
      date: 'Date',
      redeem: 'Redeem',
      redeemDesc: "Membership rewards are paid after you've reviewed us on TrustPilot. If you've reviewed us already, your rewards will go straight into your account. Otherwise, click the Unlock Your Rewards button below to submit your TrustPilot username and claim your rewards."
    },
    investOrBorrow: 'Invest or Borrow',
    membershipPointsDesc: 'Your total active membership points. You get 1 point for every dollar you invest or borrow. Points expire 180 days from the date you earn them, but you can top them up with new orders. ',
    member: 'Member',
    whatYouHad: 'Your membership activity',
    whatYouHadDone: '{type} ${total} in {date}.',
    invest: 'Invest',
    or: 'Or',
    borrow: 'Borrow',
    whatYouHadDesc: 'Your investment and borrowing activity for the past six months. Here you can see how much you need to invest or borrow to qualify for membership rewards.',
    invested: 'Invested',
    pointExpiredDate: '{value} points will expire on {date}',
    tierProgressDesc: 'Earn {points} points by {date} to maintain {level} membership',
    fakeDiamondRemain: 'Your free Diamond upgrade ends on {date}. Refer {number_upgrades_left} more people to extend your membership to {extend_date}.',
    fakeDiamondNoRemain: 'Your sixth and final 30-day Diamond upgrade ends on {date}. To get full Diamond membership and benefits, please continue using the platform until you earn 100,000 points in 180 days.',
    getLevelDate: 'From {date}',
    claimDaily: 'Claim <strong>{amount} points</strong> Daily Login',
    reviewMemberShip: {
      redeemButton: 'Unlock your rewards',
      redeemButtonMobile: 'Rewards',
      redeemPopup: {
        messageConfirm: 'To unlock your rewards, please <a rel="nofollow" href="https://www.trustpilot.com/review/myconstant.com" class="underline" target="_blank">review us on TrustPilot</a>. Then enter your TrustPilot username below and click Submit. Your rewards will be released to your account immediately.',
        confirm: 'Submit',
        cancel: 'Cancel'
      }
    },
    pointHistory: {
      title: 'Prize Wheel points record',
      headers: {
        date: 'Date and time',
        pointEarned: 'Points earned',
        status: 'Status',
        activity: 'Activity',
        membershipStatus: 'Membership status',
        releaseDate: 'Release time',
      },
      activity: {
        collateral_loan_matched: 'Borrowed ${amount}',
        lo_investment_investment: 'Invested ${amount}',
        saving_termdeposit: 'Invested',
        sold_saving_termdeposit: 'Sold ${amount}',
        saving_lottery_term: 'Invested ${amount}',
        user_badge_result: 'Achieved {badgeName} Badge',
        user_referral_kyc_passed: 'Your referee passed KYC – you\'ve earned 200 points',
        users__referee: 'Your referee deposited at least $50 – you\'ve upgraded to Diamond for 30 days',
        gift_ticket_transaction: 'Prize Wheel spin',
        user_tickets: 'Prize Wheel reward',
        gift_reward: 'Prize Wheel reward',
        reward_membershiphistory: 'Achieved Gold status',
        saving_userquizanswer: 'Won the mini-quiz',
        misc_survey: 'Referral Survey Bonus',
      },
    },
  },
  exchangeInputDepositForm: {
    submitBtn: {
      depositNow: 'Start growing',
      startEarning: 'Start earning',
      growMore: 'Earn more',
      depositWithAmount: 'Start growing {fiatAmount} {fiatCurrency}'
    },
    todayRate: 'Today’s rate.',
    todayRateValues: '{rate}% APY',
    howMuchDoYouWantToInvest: 'Which cryptocurrency would you like to invest?',
    howMuchDoYouWantToDeposit: 'How much do you want to grow?',
    howMuchDoYouWantToDepositFiat: 'How much do you want to deposit?',
    howMuchDoYouWantToDepositCrypto: 'What do you want to deposit?',
    minDepositRequired: 'Please enter an amount larger than {amount} {currency}',
    minAmountRequired: 'All investments must be at least {amount} {currency}',
    minDepositAmount: 'Minimum investment amount: {amount}',
    interestRate: 'Interest rate: {interest}% {interestType} {inCollateral}',
    getAmountFailed: 'Failed while getting amount, please try again',
    generateAddress: 'Generate address',
    deposit: 'Deposit',
    depositSuccess: 'Your deposit was completed successfully',
    depositCryptoDescription: 'Choose a crypto or stablecoin below and then send your funds to the wallet address shown. There is no minimum or maximum deposit.',
    getCurrencyListFailed: 'Failed while getting supported currencies',
    minDeposit: 'Please transfer a minimum of {amount} to the following wallet address',
    prvDescription: `PRV is the native token of privacy network, Incognito. It enables you to digitally transact crypto in private and is readily exchanged for other cryptocurrencies through Incognito’s pDEX (decentralized exchange). We negotiated a special interest rate in PRV that helps you take advantage of private transactions over the Incognito network. To learn more about PRV, <a class="underline" href="https://incognito.org/" target="_blank">visit the Incognito community</a>.`,
    payInterestIn: 'Pay interest in:',
    depositCollateralSuccess: `Thanks – you'll now earn {interest}% {interestType} interest on your {collateral} balance, paid in {inCollateral}.`,
    trc20Note: 'Transfer USDT over the Tron Network (TRC20) to reduce fees and transfer times.',
    lowFeeSupport: 'Make sure you send your token to the correct network to reduce fees and transfer times.',
    incorrectNetworkWarning: 'Make sure you send your tokens to the correct network or else your assets might be lost indefinitely.',
    stableCredit: 'It will be credited into your {symbol} balance and you can\'t swap it to USD balance directly.',
    makeLocalOrderFailed: "Sorry, we couldn't create your order this time. Please try again. If you continue experiencing problems, please contact us at hello@myconstant.com. Thank you.",
    maxAmountRequired: 'Please enter an amount smaller than {amount} {currency}',
  },
  history: {
    deposit: {
      title: 'Deposits',
      titleHistory: "Deposit USD History",
      tabs: { fiat: 'Fiat', crypto: 'Crypto', stablecoin: 'Stablecoin', openOrders: 'Open Orders', collateral: 'Collateral' },
      headers: {
        date: 'Date',
        receivedAmount: 'Received amount',
        cryptoAddress: 'Crypto Address',
        status: 'Status',
        refNo: 'Reference no.',
        action: 'Action',
        coin: 'Coin',
        amount: 'Amount',
        collateralAction: 'Action',
        txHash: 'TxHash'
      },
      noHistoryData: 'Make a deposit to get started.',
      status: {
        cancelled: 'Cancelled',
        system_cancelled: 'Order timed out',
        system_rejected: 'Order timed out',
        transferred: 'Successful',
        matching: 'Matching',
        picked: 'In progress',
        pending: 'Confirming',
        transferred_fiat: 'Your account will be credited once we have received your transfer.',
        transferred_fiat_invest: 'Your existing balance is: {balance} USD. To invest of {investAmount} USD, please top up {remainAmount} USD.',
        transferring_fiat: 'Transferring Fiat',
        approving: 'Waiting for admin approval'
      }
    },
    depositCrypto: {
      header: 'Crypto - Deposits',
      titleHistory: "Deposit Crypto History",
      status: { done: 'Done' },
      checkBalance: 'Check Balance',
      headers: {
        date: 'Date',
        amount: 'Amount',
        address: 'Address',
        status: 'Status',
        action: ' '
      },
      title: 'Crypto Lend - Deposits',
      noHistoryData: 'Make a deposit to get started.'
    },
    support: {
      messageConfirm: 'How can we help you with this withdrawal?',
      confirm: 'Send',
      cancel: 'No',
      messageDepositConfirm: 'How can we help you with this deposit?',
      wireReceipt: 'Wire receipt',
      wireReceiptRemove: 'Remove receipt'
    },
    transferConstant: {
      title: 'Transfer',
      titleHistory: 'Transfer History',
      tabs: {
        sent: 'Sent',
        received: 'Received',
        openOrders: 'Open Orders',
      },
      headers: {
        send: 'Send',
        received: 'Received',
        type: 'Type',
        amount: 'Amount',
        fromEmail: 'From email',
        toEmail: 'To email',
        time: 'Time',
        date: 'Date',
        status: 'Status',
        action: 'Action',
      },
      noHistoryData: 'Make a transfer to get started.'
    },
    withdrawFiat: {
      title: 'Withdrawals',
      titleHistory: "Withdraw USD History",
      tabs: { fiat: 'Fiat', openOrders: 'Open Orders' },
      headers: {
        orderNo: '#',
        usdAmount: 'Amount',
        currencyAmount: 'Currency Amount',
        paymentMethod: 'Method',
        date: 'Date',
        status: 'Status',
        action: 'Action',
        type: 'Type',
        accountNumber: 'Account number',
        disbursementTo: 'Disbursement to',
      },
      noHistoryData: 'Make a withdrawal to get started.',
      bankName: 'Bank name',
      beneficiaryName: 'Beneficiary name',
      accountNumber: 'Account number'
    },
    withdrawCrypto: {
      title: 'Withdrawals',
      titleHistory: "Withdraw Crypto History",
      tabs: { fiat: 'Fiat', crypto: 'Crypto', stablecoin: 'Stablecoin', openOrders: 'Open Orders' },
      headers: {
        orderNo: '#',
        usdAmount: 'USD Amount',
        currencyAmount: 'Currency Amount',
        fee: 'Fee',
        address: 'Send to Address',
        time: 'Time',
        date: 'Date',
        status: 'Status',
        action: 'Action',
        amount: 'Amount',
        coin: 'Coin',
        type: 'Type',
        payOut: 'Payment Method / Send to Address',
        partialWithdrawn: 'Partially withdrawn',
      },
      noHistoryData: 'Make a withdrawal to get started.'
    },
    openOrders: {
      withdrawals: 'Withdrawals',
      transfers: 'Transfers',
    },
    shortSelling: {
      title: 'Crypto - Short selling (Advanced)',
      tabs: {
        transfer: 'Transfer',
        borrow: 'Borrow',
        repay: 'Repay',
        interest: 'Interest',
      },
      headers: {
        date: 'Date',
        pair: 'Pair',
        interest: 'Interest',
        amount: 'Amount',
        borrowAmount: 'Borrowed',
        collateralAmount: 'Sold amount',
        triggerPrice: 'Trigger price',
        fee: 'Fee',
        from: 'From',
        to: 'To',
        status: 'Status',
      },
      cancelSuccess: 'Done! Your order to short {amount} {crypto} once its price reaches {price} USD has been canceled. To short another cryptocurrency or create a new order, please visit our short selling page.',
    },
    lendingLottery: {
      headers: {
        orderNo: '#',
        time: 'Time',
        date: 'Released date',
        expiredDate: 'Ends',
        status: 'Status',
        investDate: 'Invest date',
        investAmount: 'Invest amount',
        // amount: 'Amount',
        // rewardAmount: 'Reward Amount',
        reward: 'Reward',
        ticket: 'Ticket',
        ticketPending: 'Tickets pending',
        ticketType: 'Ticket type',
        ticketTypeDesc: 'There are two types of tickets: invest and reward. The Invest ticket is the ticket you earn from investing in Lending Lottery, which can be used for 4 weeks (there’s a new drawing each week). The reward ticket you earn from the lottery prize, or any promotion on the platform which can be used for only one week.',
        systemPickOn: 'System pick on',
        systemPickOnDesc: 'Your numbers will be automatically chosen numbers for you if you don’t pick them yourself after 2 weeks.',
        ticketDuration: 'Ticket Duration',
        ticketDurationDesc: 'The number on the left indicates how many weeks you’ve used the ticket. And the number on the right indicates how many weeks the ticket is valid for.',
        action: ' '
      }
    },
    constantAirdrop: {
      headers: {
        date: 'Airdrop date',
        amount: 'Amount',
        releaseTime: 'Release time',
        status: 'Status',
        action: ' ',
      }
    },
    mctPrizeWheel: {
      headers: {
        date: 'Date',
        reward: 'Reward'
      }
    },
    constantInteralBonus: {
      headers: {
        date: 'Date',
        amount: 'Amount',
        action: 'Action'
      }
    },
    mascotNFT: {
      headers: {
        txHash: 'Tx Hash',
        date: 'Date',
        price: 'Price',
        type: 'Type',
        status: 'Status',
        action: 'Action',
        mascot: 'Mascot'
      }
    }
  },
  me: {
    history: {
      source: 'Source',
      local: 'Location',
      ip: 'IP',
      invalidDate: 'You can\'t export over six months of your account activity.',
      noData: 'No data available to download of times selected',
      exportToExcel: 'Export to excel',
      filterHistory: 'Filter',
      depositNow: 'Deposit Now',
      cancel: 'Cancel',
      backToOrder: 'View detail',
      balances: 'Available balance',
      balancesDesc: 'Funds currently available for investments, transfers, and withdrawals. It doesn\'t include pending transactions, such as deposits that have yet to clear.',
      holdings: 'Pending balance',
      holdingsDesc: 'Funds received but not yet cleared or credited to your account. For example, if undergoing a manual review.',
      totalIns: 'Incoming funds',
      totalInsDesc: 'Funds received, including deposits, interest payments, refunds, and incoming transfers.',
      totalOuts: 'Outgoing funds',
      totalOutsDesc: 'Funds paid out, including withdrawals to a bank account or external wallet, investments, fees, and outgoing transfers.',
      amountText: 'Amount',
      topup: 'Top up',
      topUpStatus: { '1': 'Done', '3': 'Expired', '5': 'Cancelled', '7': 'Pending' },
      loanId: 'Loan ID',
      loanDate: 'Loan date',
      loanStatus: 'Loan status',
      balance: 'Total balance: {balance} USD',
      name: 'Wallet name',
      crypto: 'Crypto',
      network: 'Network',
      address: 'Wallet address',
      memo: 'Memo',
      user: {
        status: {
          transferredMoneyToAgent: 'Your account will be credited once we have received your transfer.',
          canceled: 'Cancelled',
          timeout: 'Order timed out',
          waitAgentTransferMoney: 'Processing',
          approving: 'Waiting for admin approval',
          done: 'Successful',
          transferMoneyToAgent: 'Please make a transfer to complete your deposit',
          transferredMoneyToAgentInvest: 'Your existing balance is: {balance} USD. To invest of {investAmount} USD, please top up {remainAmount} USD.',
          inProgress: 'Order in progress',
          transferring_fiat: 'Transferring Fiat',
          orderProcessing: 'Transaction is being processed',
        },
        account: 'User',
        fullName: 'Full name',
        userName: 'User name',
        email: 'Email',
        phone: 'Phone',
        paymentMethods: {
          auto_bank: 'Checkbook',
          email: 'Zelle',
          google_pay: 'Google Pay',
          venmo: 'Venmo',
          paypal: 'Paypal',
          fiat: 'Bank Transfer',
          plaid: 'Linked Bank'
        }
      },
      time: 'Time',
      date: 'Date',
      reference_number: 'Reference No.',
      value: 'Currency Amount',
      amount: 'USD Amount',
      withdrawals: 'Withdrawals',
      fiat: 'Fiat',
      collateral: 'Collateral',
      openOrders: 'Open orders',
      withdraw: 'Withdraw',
      withdrawStatus: {
        0: 'Pending',
        1: 'Done',
        2: 'Failed',
        3: 'Approving',
        4: 'Rejected',
        5: 'Confirming',
        6: 'Order timed out',
        7: 'Waiting Approving',
      },
      sendToAddress: 'Send To Address',
      orderType: {
        '0': 'Buy',
        '1': 'Convert',
        '2': 'Transfer',
        '3': 'Investments',
        '4': 'Interest',
        '5': 'Fee',
        '6': 'Import',
        '7': 'Deposit',
        '8': 'Withdraw',
        '9': 'Risk',
        '10': 'Borrow',
        '11': 'Pay',
        '12': 'Reward',
        '13': 'Interest',
        '14': 'Earned from referral flex',
        '15': 'Membership Reward',
        '16': 'Referral Term Deposit',
        '17': 'Referral Reward',
        '18': 'Service Time Interest',
        '19': 'Ajust Increase',
        '20': 'Ajust Descrease',
        '21': 'Referral Bonus',
        '22': 'Purchase',
        '23': 'Refund',
        '24': 'LO investment',
        '25': 'LO repayment',
        '26': 'LO Investment',
        '27': 'LO Investment Interest',
        '33': 'Badge reward',
        '101': 'Bonus',
        '102': 'Coupon Signup',
        '105': 'Instant Credit Interest',
        received: 'Received'
      },
      orderStatus: {
        '0': 'Pending',
        '1': 'Payment pending',
        '2': 'Coin minting',
        '3': 'Coin burning',
        '4': 'Transferring',
        '5': 'Processing',
        '6': 'Cancelled',
        '7': 'Successful',
        '8': 'In progress',
        '11': 'Transfer failed',
        '12': 'Waiting Approving',
        '13': 'Waiting Approving',
        '14': 'Transferring',
        '15': 'Confirming',
        '16': 'Order timed out'
      },
      reserveType: {
        verifying: {
          label: 'Verifying',
        },
        pending: {
          label: 'In progress',
        },
        purchasing: {
          label: 'In progress',
          desc: 'Your deposit order was created successfully. Once we receive your funds, it might take up to 1 business day for your balance to update. ',
        },
        coinMinting: {
          label: 'In progress',
        },
        coinBurning: {
          label: 'In progress',
        },
        transferring: {
          label: 'In progress',
        },
        redeemingAch: {
          label: 'Processing (ACH)',
          desc: 'Your withdrawal is being processed. ACH withdrawals may take 3-5 business days (weekends not included) to reach your account.',
        },
        redeemingWire: {
          label: 'Processing (Wire)',
          desc: 'Your withdrawal is being processed. Wires may take 2-5 business days (not including weekends) to reach your account.',
        },
        redeemingZelle: {
          label: 'Processing (Zelle)',
          desc: 'Your withdrawal is being processed. Zelle withdrawals may take up to 1 business day to reach your account (weekends not included).',
        },
        redeemingFiat: {
          label: 'In progress',
          desc: 'Your withdrawal is being processed. It may take 3-5 business days (not including weekends) to reach your account.',
        },
        cancelled: {
          label: 'Canceled',
          desc: 'Your transaction was canceled.',
        },
        done: {
          label: 'Successful',
          desc: 'Your transaction was approved and has completed successfully.',
        },
        holding: {
          label: 'In progress',
          desc: 'Your funds are currently being held for another order. This could be because they\'re allocated to an investment order that hasn\'t started yet. For more info, please email us on hello@myconstant.com.',
        },
        transferringFailed: {
          label: 'Failed',
          desc: 'Your crypto transfer failed and the funds returned to your balance. This could be for many reasons, including network or wallet issues. Please recreate your order or email us on hello@myconstant.com for support.',
        },
        approving: {
          label: 'In progress',
          desc: 'Your withdrawal request was flagged for additional review before we can process your withdrawal. This added security measure is our system’s way to prevent against suspicious account activity and protect your crypto.',
        },
        agentApproving: {
          label: 'In progress',
          desc: 'Your withdrawal has been flagged for manual review. It might take a little longer to process your withdrawal, but it helps protects your crypto and the security of our platform. Please email us on hello@myconstant.com if you have any questions.',
        },
        tokenTransferring: {
          label: 'Transferring',
          desc: 'Your cryptocurrency is on its way to the receiving wallet address. This should take no longer than an hour for small amounts of certain cryptos, but others or larger amounts may take up to 1 business day. Please view our <a class="underline bold" href="/getPrices">service times</a> for more information. ',
        },
        emailConfirming: {
          label: 'Awaiting confirmation',
          desc: 'Please confirm your transaction by clicking the link in the email we sent you. For your security, this transaction will time-out unless you confirm it within the next 15 minutes.',
        },
        timeout: {
          label: 'Order timed out',
          desc: 'Your order timed out. This happens when you don\'t confirm a transaction in time (such as an email confirmation) or when we never receive funds for an investment order you created.',
        },
        reversed: {
          label: 'Correction',
          desc: 'This corrects a previous deposit error such as an overpayment or duplicate transaction. For more information, please email us on hello@myconstant.com.',
        },
        affiliateRewardUser: {
          label: 'Reward user',
        },
        affiliateRewardVolume: {
          label: 'Reward volume',
        },
      },
      requestExecute: 'Help',
      transactionID: '#',
      type: 'Type',
      fee: 'Fee',
      status: 'Status',
      collateralPay: 'Pay: ',
      description: 'Description: ',
      toAddress: 'To Address',
      holdingBalance: 'Holding Balance: {balance} USD',
      noHistoryData: 'Make a deposit to get started.',
      withdrawAndTransfer: 'Withdrawals and Transfers',
      investmentOrder: 'Investments',
      fromEmail: 'From Email',
      alert: {
        requestSuccess: 'Successfully Requested',
        cancelSuccess: 'Successfully Cancelled'
      },
      toEmail: 'To Email',
      exportToExcelNote: 'You can export up to twelve months of your account activity.',
      fromDate: 'From date',
      toDate: 'To date',
      download: 'Download',
      filter: 'Filter',
      filterNote: 'Filter your account activity.',
      reserveTypeGroup: 'Type',
      cancelMess: 'Are you sure you want to cancel this order?',
      cryptoAddress: 'Crypto address',
      bankInfo: {
        bankName: 'Bank Name',
        bankCountryAndCurrency: 'Country/Currency',
        bankAccountCurrency: 'Account Currency',
        bankCountry: 'Bank Country',
        bankAccountName: 'Beneficiary name'
      },
      note: 'Note',
      received: 'received'
    },
    twoFa: {
      input2Fa: {
        popupTitle: 'Two-factor Authentication',
        newTitle: 'Google Authentication',
        newInputCode: 'Please input the 6-digit code from your Google Authenticator app.',
        cantAccess: 'If 2FA is inactive or you’d like to go back, click here.'
      },
      googleAuth: 'Google Authentication',
      labelOn: 'Disable',
      noteOn: '<p>Looking good.</p>            <p>You’re using Google 2FA to protect your account with your phone as well as your password.</p>',
      disableModal: {
        title: 'Disabled Google authenticator',
        inputPassword: 'Enter password',
        password: 'Password',
        inputAuthCode: 'Google authentication code',
        cancel: 'Cancel',
        confirm: 'Confirm',
        requiredPassword: 'Password is required',
        facebook: 'Sign-in with your facebook account.',
        google: 'Sign-in with your google account.',
      },
      invalidatePassword: 'Password not matched, please try again',
      labelOff: 'Turn on now?',
      noteOff: `
          <p>Yikes – you’re not using Google 2FA.</p>
          <p>Google 2FA uses your phone as well as your password to protect your account.</p>
          <p>We strongly recommend you enable 2FA to protect your account from fraud, identity theft, and other abuse.</p>
          <p>Enable 2FA now to double the security protection on your account.</p>
          <p><a href="https://myconstant.com/blog/how-to-constant-2fa/" class="underline" target="_blank">Click here</a> to learn how to set up your 2FA, and to understand how it keeps you safe.</p>
        `,
      qrScanModal: {
        step1: 'Step 1',
        description: 'Please scan this code with Google Authenticator or Authy, then enter the code sent to you.',
        canNotScanCode: 'If you are unable to scan the QR code, please enter this code manually into the app.',
        step2: 'Step 2',
        inputPassword: 'Enter password',
        password: 'Password',
        inputAuthCode: 'Google authentication code',
        cancel: 'Cancel',
        confirm: 'Confirm',
        requiredCode: 'Google authentication code is required',
        requiredPassword: 'Password is required',
        requiredGoogle: 'You must sign-in with your google account',
        requiredFacebook: 'You must sign-in with your facebook account.',
        message: 'Code copied to clipboard.',
        facebook: 'Sign-in with your facebook account.',
        google: 'Sign-in with your google account.',
        discord: 'Sign-in with your discord account.',
        requireVerifiedEmail: '<p>Please <a class="underline bold" href="/me/information">update your email</a> and verify it to generate QR code for scanning with Google Authenticator or Authy.</p>',
      },
      invalidateOtp: 'Google authentication code not matched, please try again',
      invalidateOtpSms: 'SMS code not matched, please try again',
      disable2FaSuccess: 'Disable 2FA successfully!',
      enable2FaSuccess: '2FA successfully enabled.',
      backupModal: {
        title: 'Enabled Google authenticator',
        label: 'Please save this Key on paper',
        description: 'This Key will allow you to recover your Google Authenticator in case of phone loss.',
        ok: 'Done'
      },
      facebookButton: 'Login with Facebook',
      googleButton: 'Login with Google',
    },
    proSaving: {
      listApplications: {
        principal: 'Amount',
        interest_to_date: 'Interest',
        shareAndEarn: 'Invite friends, earn 10% and more',
        anyTime_saving: 'Balance',
        balanceCoin: 'Coin balance',
        saving: 'Invest now',
        deposit: 'Deposit',
        depositDesc: `Store your money in USD. Earn 4% APY <br/>with Instant-access investing.`,
        withdraw: 'Withdraw',
        withdrawDesc: `Withdraw funds to your bank account <br/>or wallet address.`,
        withdrawFiat: 'USD',
        withdrawCrypto: 'Crypto',
        withdrawGiftCards: 'Gift cards',
        transfer: 'Transfer',
        transferDesc: `Send money across the world - to as <br/>many people as you like.`,
        saving_title: 'Investments',
        loanOriginatorInvestments: 'Loan Originator Investments',
        loans_title: 'Loans',
        secondInvestments: 'Secondary Market',
        secondaryMarketHistory: 'Secondary Market History',
        secondaryMarketHistoryDetail: 'Secondary Market History Detail',
        secondInvestmentsLO: 'Loan Originator Secondary Market',
        secondInvestmentsLODesc: 'Buy and sell matched investments on our secondary market. Sell your investment to end your term early or buy another investor’s order to earn their interest. View or cancel your secondary market orders below.',
        spendings: 'Gift Cards',
        spendingsDesc: 'Gift cards and other items or services bought using your MyConstant balance.',
        shortSelling: 'Short selling (Advanced)',
        lendingLottery: 'Lending Lottery',
        lendingLotteryDesc: 'Lending Lottery investments are fixed-term investments of 30 days. You earn lottery tickets for every $100 you invest. You can cancel Lending Lottery investments before your term ends, but you’ll lose all tickets, winnings, and interest earned.',
        termStatusFilter: {
          investment: {
            pending: 'Pending',
            matching: 'Matching',
            matched: 'Matched',
            done: 'Done',
            closed: 'Cancelled',
            allStatus: 'All'
          },
          secondary_investment: {
            pending: 'Pending',
            voiding: 'Selling',
            voided: 'Sold',
            matched: 'Bought',
            done: 'Done'
          }
        },
        createdDate: 'Created Date',
        amount: 'Principal',
        totalInterest: 'Total interest',
        interest: 'Interest to date',
        rate: 'Rate',
        term: 'Term',
        endTerm: 'Term ends',
        noInvestment: 'Make your investment of any amount today.',
        noMoreInvestment: 'Make your first investment of any amount today.',
        autoTopup: 'Auto top-up',
        apr: 'APR',
        loanOriginatorInvestmentsDesc: 'Investments backed by the loan originator’s buy-back guarantee. Unlike crypto-backed investments, there might not always be collateral securing the loan. Instead, the loan originator guarantees to buy back the loan should the borrower default for 60 days or more, returning your principal and earned profit.',
        headingNote: 'We will send you notifications via email if your collateral falls in value. Sometimes, these may wind up in your spam folder, so do check, and drag the email to your main inbox to prevent this from happening in the future. Do also frequently check our site - the values shown here are updated real-time.',
        secondInvestmentsDesc: 'Buy and sell matched investments on our secondary market. Sell your investment to end your term early or buy another investor’s order to earn their interest. View or cancel your secondary market orders below.',
        ref_number: 'Ref number',
        delete: 'Delete',
        totalInterestDesc: 'This is the interest you will earn for the full term. If a borrower repays early, you will earn the interest to date.',
        cashBack: 'Cashback',
        termStatus: {
          matching: 'Matching',
          pending: 'Matching',
          closed: 'Done',
          picked: 'Pending',
          transferred_fiat: 'Pending',
          created: 'Matched',
          voided: 'Sold',
          matched: 'Matched',
          voiding: 'Selling',
          rejected: 'Closed',
          pending_kyc: 'Pending',
          cancelled: 'Closed',
          partially_cancelled: 'Partially cancelled',
          approving: 'Approving',
          transferring_fiat: 'Pending',
          closed_soon: 'Matched',
          undefined: '',
          locked: 'Verifying'
        },
        balanceStarDesc: '        A STAR is a reward token you can redeem for 1% bonus interest on an investment or a 1% discount on a loan.',
        referralDesc: '        Get a 10% reward when your friends earn interest – paid out every second. So if your friend earns $100, we’ll give you $10. There’s no limit to the number of friends you can refer.        ',
        matched: 'matched',
        cancel: 'Cancel',
        autoRenew: 'Auto reinvest',
        autoRenewDesc: 'Tired of manual labour? Turn this on to automatically reinvest at the same interest rate & term!',
        disableAutoRenewDesc: 'Please note that auto-reinvest is not available for funds deposited under the 10% APR promotion. Once the term ends you can manually invest with the normal rates.',
        dialog: {
          cancelInvestment: {
            messageConfirm: 'Are you sure you want to cancel your investment order before a match is found?',
            confirm: 'Yes, cancel',
            cancel: 'Go back'
          },
          cancelDeposit: {
            confirm: 'Yes, cancel',
            cancel: 'Go back',
            messageConfirm: 'Are you sure you want to cancel your deposit order?'
          },
          autoRenewInvestment: {
            principal: 'Principal',
            principal_interest: 'Principal + Interest',
            messageConfirm: {
              false: 'When your term ends, you can automatically reinvest your principal and interest or just your principal. Please select which you’d like to reinvest and then click Enable auto invest.',
              true: 'Are you sure you want to disable Auto Reinvest? With Auto Reinvest, your money earns interest around the clock, with no downtime, and no extra effort from you.'
            },
            confirm: 'Yes',
            cancel: 'Cancel',
            autoRenewInvestmentSuccess: {
              principal: 'Thank you. We will automatically reinvest your principal only at the same rate and term. You can pause auto reinvest or change its settings at any time from your accounts page.',
              principal_interest: 'Thank you. We will automatically reinvest your principal and interest at the same rate and term. You can pause auto reinvest or change its settings at any time from your accounts page.',
              disabled: 'Disabled Auto Reinvest successfully'
            },
            autoRenewInvestmentFailed: {
              true: 'Failed to disable Auto Reinvest',
              false: 'Failed to enable Auto Reinvest'
            }
          },
          autoTopup: {
            messageConfirm: {
              enable: 'Auto top-up enabled. Collateral value that falls to {AutoTopupPer}% will be restored to {AutoTopupToPer}%.',
              false: 'Auto top-up enabled. Collateral value that falls to {AutoTopupPer}% will be restored to {AutoTopupToPer}%.',
              true: 'Are you sure you want to turn off auto top-up? Your collateral will be liquidated if it falls to {LiqPer}%.',
              disable: 'Are you sure you want to turn off auto top-up? Your collateral will be liquidated if it falls to {LiqPer}%.'
            },
            confirm: 'Yes',
            cancel: 'No',
            autoTopUpCollateralSuccess: {
              true: 'Auto top-up turned on successfully',
              false: 'Auto top-up turned off successfully'
            },
            autoTopUpCollateralFailed: {
              true: 'Turn auto top up on failed',
              false: 'Turn auto top up off failed'
            }
          },
          cancelLoan: {
            messageConfirm: 'Are you sure you want to cancel this loan request?',
            confirm: 'Yes',
            cancel: 'No'
          },
          deleteLoan: {
            messageConfirm: 'Are you sure you want to delete this loan request?',
            confirm: 'Yes',
            cancel: 'No'
          },
          payment: {
            caption: 'Payment required.',
            amount: 'Amount',
            label: 'Transfer to address',
            message: 'Address has been copied!',
            description: 'To repay your loan, please transfer {amount} {currency} to the address above.',
            ok: 'Close'
          },
          autoRenew: {
            messageConfirm: {
              true: 'Are you sure you want to turn off auto renew?',
              false: 'When you repay your loan, your collateral balance will be used to secure a new loan order at the same amount, interest rate, and term.'
            },
            autoRenewCollateralSuccess: {
              true: 'Auto-renew turned on successfully',
              false: 'Auto-renew turned off successfully'
            },
            autoRenewCollateralFailed: {
              true: 'Turn auto top up on failed',
              false: 'Turn auto top up off failed'
            },
            confirm: 'Yes',
            cancel: 'No'
          },
          withdrawAccount: {
            title: 'Investments',
            body: 'Want to get more out of your money? Invest in fully secured peer-to-peer lending and choose your own interest rate.',
            termTitle: '{month} month{termType}',
            percentReceive: 'You will earn {percent}% APR.',
            confirm: 'Invest',
            cancel: 'Cancel',
            messageConfirm: 'You are about to invest {amount} Constant.',
            validateMaxAmountError: 'Please top up your MyConstant account with sufficient funds.',
            validateMinAmountError: 'Please enter an amount larger than {amount} USD.'
          },
          checkTrialKyc: {
            messageConfirm: 'For your security, please verify your identity before making a withdrawal.',
            confirm: 'Verify my ID',
            cancel: 'Maybe later'
          },
          deleteInvestment: {
            messageConfirm: 'Are you sure you want to delete your investment order?',
            confirm: 'Yes',
            cancel: 'Go back'
          },
          cancelDepositMatched: {
            message: 'Your successfully cancelled to up.',
            messageConfirm: 'Are you sure you want to cancel your top up?',
            confirm: 'Yes, cancel',
            cancel: 'Go back'
          },
          cancelDepositKyc: {
            messageConfirm: 'Are you sure you want to cancel your investment free trial?',
            confirm: 'Yes, cancel',
            cancel: 'Verify my ID'
          },
          getRemainCollateral: {
            amount: 'Amount',
            requiredAmount: 'Amount is required!',
            maxAmount: 'Amount must less than {max} {symbol}',
            amountDesc: 'How much do you want to remain?',
            messageConfirm: 'Are you sure you want to recall <strong>{valueAvailable} {symbol}</strong> to bring your collateral value back to the minimum required amount?',
            remainCollateralFailed: 'Failed to remain collateral.',
            confirm: 'Yes',
            cancel: 'No'
          },
          payOff: {
            early: {
              messageConfirm: 'Are you sure you want to repay?',
              confirm: 'Repay',
              cancel: 'Cancel'
            },
            onTime: {
              messageConfirm: 'Are you sure you want to repay?',
              confirm: 'Repay',
              cancel: 'Not now'
            },
            payOffSuccess: 'Repayment made. Thanks!',
            payOffFailed: 'Repay failed',
            notEnoughConstantRepay: 'Your current balance is ${balance}. Please deposit ${requireAmount} to cover the amount due, then click repay again. ',
            makeADeposit: 'Make a deposit',
            unableWithCollateral: 'Sorry, you’re unable to repay this loan with collateral. We may restrict repayment using collateral due to the loan amount, collateral type, or market conditions. This is to protect our ability to sell cryptocurrency at a price that covers investor commitment. Please repay using USD.',
          },
          pay: {
            messageConfirm: 'Are you sure you want to pay?',
            confirm: 'Pay',
            cancel: 'Not now',
            paySuccess: 'Payment made. Thanks!',
            payFailed: 'Pay failed',
            notEnoughConstantRepay: 'Your current balance is ${balance}. Please deposit ${requireAmount} to cover the amount due, then click repay again. ',
            makeADeposit: 'Make a deposit'
          },
          cancelBorrow: {
            messageConfirm: 'Are you sure you want to cancel the buying coin process?',
            confirm: 'Yes',
            cancel: 'No',
            cancelC2CError: "Sorry, you cannot cancel this order at the moment. Please try again after 10 minutes.",
          }
        },
        yourInvestments: 'Match{plural} {matched}',
        itemMatched: {
          amount: 'Amount',
          interest: 'Interest rate',
          earned: 'Earned',
          duration: 'Term',
          matchedDate: 'Date matched',
          due: 'Term ends',
          void: 'End your term early',
          dialog: {
            void: {
              messageConfirm: '<p>To end your term early, you can sell all or part of your investment to another investor. Once sold, you get your principal back and 2% APR on elapsed term time, but will lose Prize Wheel points earned on this investment. Selling normally takes around 24 hours.</p>',
              messageConfirmFullAmount: '<p>To end your term early, you can sell your investment to another investor. Once sold, you get your principal of ${amount} back and 2% APR on elapsed term time, but you’ll lose any earned Prize Wheel points on this investment. Selling normally takes around 24 hours.</p><p>Would you like to sell your investment?</p>',
              confirm: 'Yes',
              cancel: 'Go back'
            }
          }
        },
        partialMatchesDesc: 'Some investments may be split into smaller amounts to fill multiple loan orders. The platform will keep trying to match you completely for the duration of your term.',
        autoTopupDesc: 'Automatically trigger a top-up from your available coin balance when the collateral value falls to {LiqPer}%.',
        rewardFrom: {
          rewardTitle: 'STAR balance: {balance}',
          rewardSubTile: 'You can apply a maximum of 3 STAR at one time.',
          amount: 'How many STAR do you want to redeem?',
          apply: 'Redeem',
          cancel: 'Cancel',
          requiredAmount: 'Amount is required!',
          rewardNote: 'This will lower your rate by {rateDown}%',
          maxAmount: 'Your amount must be <= {max}.',
          rewardApplySuccess: 'Rewards redeemed.',
          rewardInvestNote: 'This will increase your rate by {rateUp}%',
          title: 'Deposit more collateral',
          amountDesc: 'How much do you want to apply?',
          close: 'Close',
          notAvailable: 'Uh oh! Something went wrong, Your reward not available. please try again...',
          rewardApplyFailed: 'Something went wrong. Please try again.'
        },
        noData: 'No data available',
        holding: 'balance holding',
        alert: {
          stopSavingSuccess: 'You successfully cancelled your investment.',
          depositSavingSuccess: "Thanks! Your investment order was successful. Your term has begun and you've started earning interest.",
          voidInvestmentSuccess: 'Thanks. Your investment has rejoined the matching queue and we’ll let you know when we find a buyer. In the meantime, you can cancel your sell order from your Accounts page.',
          stopSellingInvestmentSuccess: 'Thanks – we’ve cancelled your sell order and the investment remains yours.',
          cancelBorrowSuccess: 'You successfully cancelled borrow',
          deleteSavingSuccess: 'Investment order deleted',
          deleteSavingFailed: 'Failed To delete Invest Saving',
          depositSavingFailed: 'We’re unable to complete your investment at this time. Please refresh your browser and try again.',
          stopSavingFailed: 'End your term failed.',
          stopSellingInvestmentFailed: 'End your term failed.',
          applyReferralSuccess: 'Thanks! Your rewards have been applied.',
          applyReferralFailed: 'Sorry, something went wrong. Please try again.',
          batchTransferSuccess: 'You successfully sent the batch file',
          batchTransferFailed: 'Failed to sent the batch file',
          confirmBatchTransferSuccess: 'Success',
          confirmBatchTransferFailed: 'Failed',
          voidInvestmentFailed: 'Sorry, we couldn’t create your sell order. Please try again later. If you keep seeing this message, please email us at <a href="mailto:hello@myconstant.com">hello@myconstant.com</a>.',
          voidInvestmentFailedMinAmount: "Sorry, we couldn't create your sell order as your investment is less than ${minAmount}.",
          cancelBuyingBorrowSuccess: 'Cancel buying process successfully',
        },
        rewardApply: 'Redeem STAR',
        investment: 'investment',
        totalInterestEarned: 'Interest earned',
        noRecord: 'You have not made any investments yet.',
        getListFailed: 'Sorry, something went wrong. Please try again.',
        received: 'received',
        interestAmount: 'Total interest',
        haveTimeAccount: '{term}-{termType} investment',
        month: 'month',
        date: 'day',
        anyTimeAccount: 'Anytime deposit',
        referralDescUS: '        Get a $10 reward for you and your friends when you refer them to invest with us. Additionally, get rewarded 10% of their earned interest in their first year - paid out every second. So if your friend earns $100 we’ll give you $10. There’s no limit to the number of friends you can refer.        ',
        trial: 'TRIAL',
        trial_description: 'You keep the interest. Term has ended',
        loanRecords: 'Loan Records',
        repaySuccess: 'Thanks. Please bear with us while we deduct from your balance.Your collateral will be returned to the wallet address it was sent from.',
        balanceInterest: 'Interest earned so far',
        holdingBalance: 'Holding Balance',
        input2FA: 'Input 2FA',
        matchedList: 'Matched List',
        getCollateral: 'Recall excess',
        payCollateral: 'Pay',
        repay: 'Repay',
        getRemainCollateral: 'Get Remaining Collateral',
        payOffLoan: 'Payoff',
        payOffLoanTitle: 'Payoff Loan',
        allStatus: 'All',
        interestInfo: {
          header: {
            title: 'Want to increase interest earnings across all your accounts?'
          },
          body: {
            item1: {
              title: '+{percent}%',
              description: 'Deposit 1,000 USD or more'
            },
            item2: {
              title: '+{total_percent}% {percent_claimed}',
              description: '{refer} Get an extra {referralRate}% each',
              refer: 'Refer up to 15 friends.',
              percent_claimed: '(+{percent}% claimed)'
            },
            item3: {
              title: '+{percent}%',
              description: 'Share MyConstant on {facebook}',
              facebook: 'Facebook',
              twitter: 'Twitter'
            }
          },
          footer: {
            title: 'That’s {percent}%',
            description: 'On top of what you already earn - for every account.'
          },
          shareFbSuccess: 'Success! Thanks for telling your friends about MyConstant.'
        },
        shareAndEarnUS: 'Invite a friend, earn $20 and more.',
        shareAndEarnUSMembership: 'Invite a friend, earn $20 and diamond membership.',
        batchtransfer: 'Transfer to multiple people',
        depositMatched: 'Deposit',
        cancelDepositMatched: 'Cancel Deposit',
        row: 'Row',
        errorMessage: 'Error Message',
        autoRenewLoan: 'Auto renew',
        autoRenewLoanDesc: 'Tired of manual labour? Turn this on to automatically re-loan at the same interest rate & term!',
        confirm: {
          batch: {
            title: 'Confirm Submit',
            desc: 'Are you sure to submit?',
            confirm: 'Confirm',
            cancel: 'Cancel'
          }
        },
        batchTransferDialog: {
          title: '',
          desc: `            <p>              For your convenience, here's a template format you can <a href=https://drive.google.com/open?id=141JCoBRpf7lUAiK0Y3IRBrMX1ChOfLbM&export=download">download</a>. <br />`
        },
        field: {
          amount: 'USD amount number greater than 0',
          to_email: 'Email address is invalid',
          schedule_date: 'Date format must be YYYY-MM-DDTHH:MM',
          invalid: ' is invalid.',
          endline: 'Please ensure all rows are filled.',
          cycle_duration: 'Schedule dates required'
        },
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        status: 'Status',
        note: 'Note',
        investMore: 'Save More',
        depositFrom: {
          title: 'Deposit more collateral',
          collateralRecommended: 'To restore your collateral value to the recommended {LvtPer}%, please top up {amount} {symbol}. <br>You are free to top up any amount you wish.',
          amount: 'Amount',
          requiredAmount: 'Amount is required!',
          amountDesc: 'How much do you want to deposit?',
          bep2Memo: 'Memo',
          bep2MemoDesc: 'Memo maybe is your ID if PEP2 crypto!',
          bep2MemoRequired: 'Memo is required!',
          ok: 'Make deposit',
          cancel: 'Cancel',
          depositFailed: 'Failed while depositing, please try again',
          depositSuccess: 'Your deposit was completed successfully'
        },
        noOpenData: 'No open order available',
        footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
        transaction_id: 'Transaction ID #{id}',
        heading: `Crypto-backed Investments History`,
        heading_detail: 'Crypto-backed Investments History Detail'
      },
      applicationDetail: {
        dueDays: 'Due Days',
        interestAmount: 'Interest Amount',
        originalAmount: 'Original Amount',
        totalAmount: 'Total Amount',
        paid: 'Paid',
        paidDate: 'Paid Date',
        paidStatus: 'Paid Status',
        payDate: 'Pay Date',
        createdAt: 'Created At',
        dueDayValueLeft: '{day} days left',
        dueDayValueOver: '{day} days overdue',
        payConfirmMsg: 'You will pay {amount} from your wallet ({balance})',
        requireTopup: 'Your balance ({balance}) not enough to pay {amount}. Top-up now?',
        backBtn: 'Back to list',
        cancel: 'Cancel',
        payForThisTerm: 'Pay for this term',
        getDetailFailed: 'Failed while getting application detail, please try again',
        cancelSuccessfully: 'Your application was canceled successfully',
        cancelFailed: 'Failed while canceling your application, please try again',
        payFailed: 'Failed while paying your term, please try again',
        paySuccessfully: 'Your paying was processed successfully',
        activities: {
          activityLog: 'Activity Log',
          showActivities: 'Show all activities',
          hideActivities: 'Hide all activities',
          timeAt: 'at'
        }
      }
    },
    navigation: {
      loginHistory: 'Login activities',
      personalInfo: 'About you',
      kycInformation: 'KYC information',
      bankInfo: 'Bank information',
      myInformation: 'Account settings',
      securities: 'Account security',
      notification_settings: 'Notification settings',
      myTransactions: 'Account activity',
      overView: 'Overview',
      deposits: 'Deposits',
      withdraws: 'Withdrawals',
      transfers: 'Transfers',
      batchTransfer: 'Batch transfers',
      openOrders: 'Open Orders',
      interests: 'Interest',
      referral: 'Referral',
      constantWallet: 'MyConstant Wallet',
      viewConstantWallet: 'Receive CONST here',
      fiatStableCoin: 'Fiat - Stablecoins',
      collateral: 'Collateral',
      crypto: 'Crypto',
      stablecoin: 'Stablecoin',
      topupHistory: 'Top-up History',
      localHistory: 'Transfers and interest earnings',
      fiat: 'Fiat',
      flex: 'Flex',
      invest: 'Invest',
      accountActivities: 'Account Activity',
      addressBook: 'Saved wallet addresses',
      shortSelling: 'Short selling (Advanced)',
      lendingLottery: 'Lottery Tickets',
      rewards: 'Rewards',
      pendingTicket: 'Pending tickets',
      availableTicket: 'Available tickets',
      expiredTicket: 'Expired tickets',
      nftRewards: 'Rewards',
      cryptoSwap: 'Crypto Swap',
      mctAirdrop: 'MCT Airdrop',
      mctEarning: 'MCT Earning',
      mctStaking: 'MCT Staking',
      mctPrizeWheel: 'MCT Prize Wheel',
    },
    kyc: {
      verifiedTitle: 'You have verified your ID',
      verifyingTitle: 'Thank you. We\'re processing your KYC details...',
      lFirstName: 'First Name',
      lMiddleName: 'Mid Name (optional)',
      lLastName: 'Last Name',
      lBirthday: 'Date of Birth',
      lGender: 'Gender',
      lTaxCountry: 'Country listed on your ID',
      lPhone: 'Phone Number',
      lIDNumber: 'ID Number',
      lAddrStr1: 'Address Street',
      lAddrCountry: 'Country on your proof of address',
      lRegion: 'State/Province/Region',
      lAddrCity: 'City',
      lZipcode: 'Zip Code',
      lTaxIdNumber: 'Social Security Number',
      needEmail: 'You need to verify your email first.',
      updateSuccess: 'Update successfully',
      plFirstName: 'First Name',
      plMiddleName: 'Mid Name (optional)',
      plLastName: 'Last Name',
      plEmail: 'Email Address',
      lEmail: 'Email Address',
      isUSYes: 'Yes',
      isUSNo: 'No',
      plTaxCountry: 'Select your country',
      plDocType: 'Select your ID type',
      titleStep1: 'Please submit your KYC details',
      whyWeNeed: 'Why do we ask for this information',
      descWhyWeNeed: '<p><strong>Why we need to verify your ID (KYC)</strong></p><p>A Know-Your-Customer (KYC) check is a basic and critical component of an AML/ATF (Anti-Money Laundering and Anti-Terrorist Financing) program set up in compliance with the US regulations. It is also a key tool for fraud prevention and detection. These checks are precautions designed to protect you and the integrity of our platform. Prime Trust, an accredited US financial institution, conducts independent KYC checks on our behalf. Your data is securely stored and never disclosed to any party other than Prime Trust for the purpose of performing the KYC. For more information, review our <a href="/agreements?view=AMLPolicy">AML Policy</a>.</p>',
      isUS: 'Are you a US citizen or resident?',
      lDocType: 'Type of ID',
      lSSN: 'Social Security Number',
      titleExample: 'Before uploading, please make sure that your ID is still valid and crop the image of your ID to make the border as small as possible.',
      titleExampleClick: 'View an example',
      correct: 'Correct',
      incorrect: 'Incorrect',
      lUpload: 'Upload an image of THE {face} of your {type}',
      lUploadOcr: 'Upload an image of THE {face} of your ID (passport, driver\'s license, or other government-issued ID)',
      descIDUpload: 'Please make sure that the photo is complete and clearly visible, in JPG, JPEG, PNG, or PDF format, and is smaller than 10MB.',
      selfie: 'Upload an image of your SELFIE',
      btnSubmit: 'Submit',
      plAddrCountry: 'Select your country',
      plAddrCity: 'Select your city',
      titleStep2: 'Please provide your proof of address',
      lAddrUpload: 'To verify the address above, please upload a document that meets <a rel="nofollow" href="https://myconstantp2p.medium.com/how-to-verify-your-id-on-constant-kyc-1a9d08ae0a" class="underline" target="_blank" >these criteria</a>.',
      descAddrUpload: `
        <p>Provide one of the below proof of address documents, which match the address provided in your KYC application.</p>
        <div class="row">
          <div class="col">
            <ul>
              <li>Utility/Phone bill</li>
              <li>Mortgage statement</li>
              <li>Rent agreement</li>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li>Bank/Credit card statement</li>
              <li>Insurance letter</li>
              <li>Financial statement</li>
            </ul>
          </div>
        </div>
        <div>Be sure the documents are dated within the last 90 days, and in JPG, JPEG PNG or PDF form - smaller than 10MB.</div>
      `,
      btnBack: 'Back',
      verified:`
        <p>You have passed the KYC on {verifiedDate} and <strong>\${trialAmount} trial bonus</strong> has been credit to your account.</p>
      `,
      verifiedNonUS:`
        <p>You have passed the KYC on {verifiedDate}.</p>
      `,
      reviewing:`
        <h2>We are reviewing your application</h2>
        <p>It may takes up to 7 business days to review your application. Once approved, <strong>\${trialAmount} trial bonus</strong> will credit to your account instantly.</p>
      `,
      reviewingNonUS:`
        <h2>We are reviewing your application</h2>
        <p>It may takes up to 7 business days to review your application.</p>
      `,
      rejected: `
        <h2>Your KYC verification is taking longer than expected</h2>
        <p>We were unable to verify your KYC automatically, so we’re manually reviewing your application. We apologize for the delay, but rest assured, we’ll get back to you soon.</p>
      `,
      uploadImageClick: 'Click here to upload your images.',
      discardNoteTitle: 'Your KYC application has not yet been approved due to:',
      discardNote: 'Please make sure all your documents are in order and just click submit again.',
      seeFullDetail: 'See your full details',
      waitForProcessingImage: 'We\'re processing your image. Please wait.',
      waitForUploadingImage: 'We\'re uploading your image. Please wait.',
    },
    bankInfo: {
      subject: 'Bank account details for receiving withdrawals or transfers ',
      btnAdd: 'Add a new bank',
      emptyBank: 'Make a deposit to get started.',
      bankAccountNumber: 'Your account number',
      bankAccountName: 'Beneficiary name',
      action: 'Action',
      editActionLabel: 'Edit',
      explanations: 'For your security, we only use these bank details to process fund withdrawals or transfers. To deposit funds, please enter the amount you want to invest, and follow the instructions to make a wire transfer directly to our FDIC-insured custodian.',
      titleUpdateBank: 'Update bank',
      yourKycName: 'Your KYC name: {name}',
      bankCountry: 'Bank Country',
      swiftCode: 'Swift code',
      bankName: 'Bank Name',
      btnSubmit: 'Add bank',
      btnRemove: 'Delete',
      btnUpdate: 'Save',
      tooltipBankAccountName: 'For your security, the beneficiary name must match the name you registered when signing up with MyConstant. Please enter a valid name or email hello@myconstant.com if you need help',
      titleAddBank: 'Add a new bank',
      routingNumber: 'Routing number',
      achCheckType: 'Ach Check Type',
      bankAccountType: 'Bank Account Type',
      beneficiaryStreet: 'Beneficiary street',
      beneficiaryRegion: 'Beneficiary region',
      beneficiaryPostalCode: 'Beneficiary postal code',
      iban: 'IBAN',
      linkBankSectionTitle: 'Link your bank account',
      linkBankSectionDesc: '<p>Deposit or withdraw using ACH bank transfers with Plaid. Link up to two bank accounts now to save time and money transferring funds to and from your MyConstant account.<br/>Since most savings accounts limit the number of withdrawals, we recommend you link a checking account not a savings account to avoid ACH reversals.<br/><a href="https://myconstant.com/blog/how-to-link-your-bank-account-for-ach-transfers-on-constant/" target="_blank">How to avoid ACH reversals</a>.</p>',
      linkBankImportantNote: '<strong>Please note</strong>: When you link a bank account via Plaid, your bank is assigned a unique ID. For your security, Plaid ACH deposits can only be withdrawn again <strong>via the same method and bank ID</strong> from which you deposited for a period of 60 days. This restriction <strong>does not</strong> apply to deposits via wire or Zelle.',
      newLinkBankSectionTitle: 'Link your bank account to deposit via ACH',
      newLinkBankSectionDesc: `
        <p>To deposit via ACH, you need to link your bank account through our ACH processing partner, Plaid. We recommend linking a checking not a savings account to avoid ACH reversals.</p>
        <p>The maximum you can deposit is <strong>$5,000 per transaction</strong>. Please also note that it can take <strong>up to 10 business days</strong> to process your deposit (but is usually much quicker). In the meantime, your deposit will earn interest under our <a href="https://myconstant.com/blog/what-is-instant-credit-and-how-does-it-work/" target="_blank">instant credit</a> program.</p>
        <p>For your security, a 7-day holding period applies before you can withdraw your deposit again. In addition, you must only withdraw to the same linked account for a period of 60 days.</p>
      `,
      newLinkBankImportantNote: 'Already linked an account? Due to new KYC rules, everyone must link a new account for deposits, however your previously-linked account will work as normal for withdrawals.',
      oldLinkBankSectionTitle: 'How to withdraw to a linked bank account via ACH',
      oldLinkBankSectionDesc: `
      <p>If you’ve previously linked a bank account for ACH transfers, it’ll appear below. You can withdraw to this account and any other account you link for deposits, at any time, for free.</p>
      <p>Please note you can only withdraw via ACH to US bank accounts, and only to those bearing the same name as in your KYC documents. International withdrawals are unavailable at the moment.</p>
      `,
      oldLinkBankImportantNote: 'Already linked an account? Due to new KYC rules, everyone must link a new account for deposits. Both your previously-linked account and any new account you link will work as normal for withdrawals.',
      unlinkBankSectionTitle: 'International Bank',
      unlinkBankSectionDesc: '<p>If you want to send more than $10,000 per transaction, or if your bank is outside of the US or doesn\'t support ACH transfers through Plaid, please add alternative banking details below. You can then preselect these details the next time you create a withdrawal order.</p>',
      nonUSbankSectionTitle: 'Add a bank account for deposits and withdrawals',
      nonUSbankSectionDesc: '<p>Speed up the transfer process by adding your bank account details below. Once saved, you can preselect these details the next time you create a deposit or withdrawal order instead of entering them manually.</p>',
      nonUSbankImportantNote: '<strong>Please note</strong>: We can\'t accept ACH transfers from non-US bank accounts. Please send your funds via wire or Zelle only.',
      getStartedIntruction: '<p>To get started, click <strong>+ Add new bank</strong></p>',
      errorLinkBank: '<p>For further information about the error, please go <a target="_blank" href="https://myconstant.com/blog/plaid-errors-and-what-to-do-about-them/">here</a>.</p>',
      btnCancel: 'Cancel',
      dialog: {
        delete: {
          title: 'Confirm Delete',
          body: 'Are you sure you want to delete this bank account?',
          confirm: 'Yes',
          cancel: 'No'
        },
        update: {
          title: 'Confirm Update',
          body: 'Are you sure to update?',
          confirm: 'Yes',
          cancel: 'No'
        },
        unlink: {
          title: 'Are you sure you want to unlink this bank account?',
          message: '<strong>Please note:</strong> if you re-link this bank account, it will be assigned a new bank ID. You won’t be able to withdraw any deposits made under a previous bank ID to a new bank ID for a period of 60 days. Please consider this before unlinking your account.',
        }
      },
      alert: {
        deleteSuccess: 'Successfully Deleted',
        failed: 'Failed To Update',
        success: 'Successfully Updated',
        deleteFailed: 'Failed To Delete'
      },
      getBankFailed: 'Failed while getting user list banks',
      title: 'Bank Information',
      desc: 'Add your bank account details now to receive attractive commission from referral programs and faster payment',
      bankAccountCurrency: 'Account Currency',
      bankCountryAndCurrency: 'Country/Currency',
      bankBranchName: 'Bank branch name',
      bankNameHolder: 'Enter Bank Name',
      bankAccountNameHolder: 'Enter Bank Account Name',
      bankAccountNumberHolder: 'Enter Bank Account Number',
      input2FA: 'Please, enter your OTP',
      bankStatus: {
        pending_automatic_verification_desc: 'We\'re automatically verifying your bank account. This should only take a few hours and you don\'t need to do anything. Once verified, you\'ll be able to use this account for ACH deposits and withdrawals.',
      },
      blacklistDesc: '<p>We’re sorry, but since there’s been an increase in <a href="https://myconstant.com/blog/how-to-avoid-the-cost-hassle-and-delays-caused-by-ach-reversals/" target="_blank">ACH reversals</a> with {bankName}, we’ve temporarily suspended ACH deposits and withdrawals with {bankName}. Please contact our customer service team at hello@myconstant.com to help fix the problem.</p>',
    },
    accountInfo: {
      title: 'Your account details',
      email: 'Email',
      nickname: 'Name',
      nickname_note: 'What should we call you?',
      enable2Fa: 'Enable 2FA to add extra security to your account',
      alert: { success: 'Your name was changed successfully' },
      emailNotVerified: 'Please verify your email',
      verifyBtn: 'Resend verification',
      enableSms: 'Enable SMS Authentication'
    },
    changeRef: {
      title: 'Change your referral code',
      btnChange: 'Change',
      changeSuccess: 'Your referral code was changed successfully',
      oldPassword: 'Old password'
    },
    changePassword: {
      title: 'Change your password',
      changePassword: 'Change password',
      newPasswordIsRequired: 'New password is required',
      oldPassword: 'Old password',
      newPassword: 'New password',
      changeFailed: 'Please make sure you have typed in your old password correctly',
      changeSuccess: 'Your password was changed successfully'
    },
    flexInterestConfig: {
      title: 'Earn 4% APY on your balance with Instant-access investing',
      desc: 'What would you like to do with your balance?',
      label: 'Earn 4% APY through Instant-access investing',
      noteOn: `<p>Your balance is earning 4% APY through Instant-access investing. You can withdraw anytime for free.</p>`,
      descDesc: '<p>You can choose to earn 4% APY on your balance with Instant-access investing.</p>                <p>Instant-access investing is a lending pool that uses Compound Finance technology to earn you interest. All lending is secured by borrower collateral, which protects your funds, and you can withdraw anytime for free.</p>                <p>If you disable Instant-access investing, your balance will be escrowed with Prime Trust, an accredited US financial institution. Prime Trust store your funds across multiple insured bank accounts, giving total coverage of $130,000,000, but you won’t earn interest.</p>',
      enableFlex: {
        messageConfirm: {
          true: 'Your balance is escrowed with Prime Trust and insured to $130,000,000.',
          false: 'Your balance earns 4% APY through Instant-access investing. You can withdraw anytime for free.'
        },
        confirm: { title: 'Yes' },
        cancel: { title: 'No' },
        enableFlexSuccess: {
          true: '<p>Your balance will be escrowed with Prime Trust, an accredited US financial institution. Prime Trust stores your funds across multiple insured bank accounts, giving total coverage of $130,000,000. You can withdraw anytime for free, but your balance will not earn interest.</p><a href="https://myconstant.com/blog/meet-scott-purcell-—-ceo-of-prime-trust" class="underline" target="_blank">Find out more about Prime Trust</a>',
          false: '<p>Congratulations! Your balance will now earn 4% APY through Instant-access investing. You can withdraw anytime for free and your balance is protected by borrower collateral.</p><a href="https://myconstant.com/blog/flex-or-prime-trust-you-decide!" class="underline" target="_blank">Find out how Instant-access investing works</a>'
        }
      },
      noteOff: `<p>Your balance is escrowed with Prime Trust and insured to $130,000,000.</p>`,
      primeTrustBalance: 'Your balance at Prime Trust',
      primeTrustBalanceDesc: 'As you’ve disabled instant-access investing, your balance is stored with our custodian, Prime Trust. Your funds will be stored across multiple bank accounts and insured to $130 million. However, you won’t earn interest on it. You can enable instant-access investing at any time from this menu.',
    },
    emailSetting: {
      title: 'Email Settings',
      emailConfirmation: {
        title: 'Email Confirmations',
        labelOn: 'Disable',
        noteOn: '<p>Nice – email confirmations are enabled on all withdrawals and transfers. Whenever you withdraw or transfer USD, cryptocurrency, or stablecoins, we’ll email you a link to authorize the transaction.</p>            ',
        desc: '        <p>        Enable email confirmations add extra security to your account. When you withdraw or transfer USD, crypto, or stablecoins, we’ll email you a link to authorize the transaction. This is in addition to 2-Factor Authorization.        </p>        ',
        labelOff: 'Enable',
        noteOff: '            <p>Uh oh – email confirmations are disabled. Enable them now to add extra security to your account.</p>            ',
        messageConfirmDisable: '        <p>Are you sure you want to disable email notifications? Without them, your transactions are authorized using 2-Factor Authorization only.</p>        <p>For your security, you won’t be able to withdraw or transfer for 24 hours after disabling email notifications. Do you want to continue?</p>        ',
        messageConfirmEnable: '<p>Thank you. Email confirmations have been enabled. Anytime you withdraw or transfer, we’ll email you a link to authorize the transaction.</p>',
        confirm: 'Yes',
        cancel: 'No',
        disableSuccess: 'Successfully Disabled',
        two2faRequired: 'Please <a href="/me/securities" class="underline">enable 2-Factor Authorization</a> before {action} email notifications.'
      }
    },
    notificationSetting: {
      title: 'Notification settings',
      secondaryMarketSetting: {
        title: 'Secondary market mobile notifications',
        downloadApp: `<p>Don’t have the app?<br/>Download it now using the links below.</p>`,
        labelOff: 'Enable',
        noteOff: '            <p>Secondary market notifications are off. You won’t be reminded when new orders appear. Enable notifications to avoid missing the best deals.</p>            ',
        desc: '        <p>        Want to be the first to know about new secondary market orders? Enable notifications here and we’ll notify you by mobile when an order appears that matches your criteria.        </p>        ',
        interest: 'Min rate (APR)',
        term: 'Max term (Months)',
        submit: 'Submit',
        messageConfirmEnable: 'We’ll notify you of new orders that match your minimum interest rate of {rate}% and maximum term of {term} months. Do you want to proceed?',
        confirm: 'Yes',
        cancel: 'No',
        enableSuccess: 'Successfully Enabled',
        labelOn: 'Disable',
        noteOn: '<p>Got it. Secondary market notifications are on. You’ll receive a notification whenever a new order appears that matches your criteria below.</p>            ',
        interestDesc: 'You’ll receive notifications for orders matching this interest rate or above.',
        termDesc: 'You’ll receive notifications for orders less than or equal to this term length.',
        messageConfirmDisable: 'Are you sure you want to disable secondary market notifications? You might miss out on the best deals.',
        disableSuccess: 'Successfully Disabled',
        messageConfirmUpdate: 'You’re about to change your notification settings to a minimum interest rate of {rate}% and maximum term of {term} months. Do you want to proceed?',
        interestInRange: 'Min rate must be greater than or equal to {min} and less than or equal to {max}',
        updateSuccess: 'Update successfully',
      }
    },
    batchTransfer: { title: 'Batch Transfers' },
    referralInfo: { title: 'Referral Information' },
    emailVerification: {
      pleaseVerify: 'Please verify your email to activate your account',
      resent: 'Resend email verification',
      sent: 'Sent email',
      verifyFailed: 'Verify failed, please try again!',
      verifySuccess: 'Your email was verified successfully.',
      pleaseUpdateEmail: 'Please update your email to activate your account',
      updateEmail: 'Update Email',
      alreadyVerified: 'Your email address has already been verified. Thank you'
    },
    accountLevel: {
      alert: {
        sendEmailCodeSuccess: 'We\'ve sent a verification link to the email address you used to sign up. Please click the link in this email to verify your email address.'
      }
    },
    smsSettings: {
      disableSms: 'Disable SMS Authentication',
      inputAuthCode: 'Step 1: Enter your Google Authentication code.',
      phoneNumber: 'Step 2: Enter your mobile number and then click “Send code”.',
      cancel: 'Cancel',
      sendCode: 'Send code',
      title: 'SMS Authentication',
      labelOn: 'Disable',
      noteOn: '<p>Nice – SMS authentication is protecting your account.</p>                <p>When you withdraw or transfer, you’ll receive an authorization code via SMS to your registered device. Enter this code when prompted to confirm the transaction. </p>',
      labelOff: 'Turn on now?',
      noteOff: '<p>Hey! You’re not using SMS authentication right now.</p>                <p>Enable it here, follow the on-screen instructions, and add some extra security to your account. </p>',
      smsCode: 'Step 3: Enter the SMS authentication code we sent you and then click “Done”.',
      reSendCode: 'Resend code',
      back: 'Back',
      done: 'Done',
      disableSmsSuccess: 'Thanks for disabling SMS authentication. We recommend using all available security measures to protect your account.',
      enableSms: 'Enable SMS Authentication',
      enableSmsSuccess: 'Thanks for enabling SMS authentication. You’ll receive an authorization code via SMS whenever you request a withdrawal or transfer. You can disable this setting at any time.',
      inputSms: {
        popupTitle: 'SMS Authentication',
        newTitle: 'SMS Authentication',
        newInputCode: 'Please input the 6-digit code sent to your phone.',
        resendCode: 'Resend code',
        cantAccess: 'Can’t get SMS code?',
        resendCodeSuccess: 'Resend code success!'
      },
      invalidateSms: 'Sms authentication code not matched, please try again'
    },
    nftRewards: {
      title: 'NFT Rewards',
      howToWithdraw: `
        <p><strong>How to transfer, sell or trade your NFT?</strong></p>
        <p>Step 1. Transfer your NFT to your Polygon (MATIC) wallet.</p>
        <p>Step 2. Once the NFT is in your wallet, you can go to a marketplace such as OpenSea to sell or trade your NFT.</p>
      `,
      whatNftTitle: 'What’s an NFT?',
      whatNftDesc: 'An NFT (Non-Fungible Token) is a one-of-a-kind digital asset issued on the blockchain. Keep this trophy in your collection or sell it on the market at an attractive price.',
    },
    personalProfile: {
      statusProcessing: 'Your KYC information is being processed. We’ll notify you when you’re good to go.',
      title: 'Personal Detail',
      name: 'Name',
      userName: 'Name',
      optional: 'Optional',
      fullName: 'Full name',
      email: 'Email',
      dob: 'Date of birth',
      gender: 'Gender',
      save: 'Submit',
      genderFemale: 'Female',
      genderMale: 'Male',
      address: 'Address',
      city: 'City'
    },
    withdrawEmailConfirm: {
      alreadyVerified: 'Your withdrawal has already been confirmed. Thank you',
      verifySuccess: 'Your withdrawal order has been confirmed successfully',
      verifyFailed: 'Your confirmation link has expired. Please create a new withdrawal order'
    },
    kycVerification: {
      pleaseComplete: 'Please complete the KYC process to {action}.',
      complete: 'Verify your ID',
      pleaseKYCBeforeAction: 'For your security, we’ll need you to verify your ID before {action}.',
      updatedSuccess: 'Your information was updated successfully',
      updatedFailed: 'Failed while updating your information, please try again!',
      checkVerifyFailed: 'Failed while checking your verify status',
      statusUnverified: 'Please verify your ID to start investing with us',
      statusUnverifiedUS: 'Please verify your ID to get a ',
      statusLinkBanks: 'Please link your bank to get started.',
      action: {
        invest: 'making a deposit',
        transfer: 'making a transfer',
        deposit: 'making a deposit',
        withdraw: 'withdrawing'
      }
    },
    getStarted: {
      steps: {
        verifyEmail: 'Verify email',
        verifyEmailDesc: 'Submit KYC now',
        kyc: 'Submit KYC',
        kycDesc: '<strong>${amount}</strong> trial bonus',
        bank: 'Link bank account',
        bankDesc: '<strong>{value}</strong> {unit}',
        addBank: 'Add bank account',
      },
      verifyEmailMessage: `
        <h2>Verify Your Email</h2>
        <p>Check your email and click the link to activate your account<br/>(or Spam & Junk section).</p>
      `,
      resendVerify: 'Resend email verification',
      title: '<h2>Let\'s get you set up...</h2>',
      suggestTrialBonus: '<p>Get a <strong>free ${amount} trial bonus</strong> when you pass KYC.</p>',
      suggestLinkBank: '<p>Almost there! Link your bank account for easy deposits and withdrawals.</p>',
      suggestInvestBorrow: 'Your\'re all set! <a href="/invest">Invest</a> or <a href="/borrow">borrow</a> now.',
    },
  },
  headerBar: {
    save: 'Invest',
    borrow: 'Borrow',
    spending: 'Spend',
    landingPro: 'Pro',
    about: 'About',
    log_in: 'Log in',
    createAccount: 'Create account',
    home: 'Home',
    account: 'Account',
    ourStory: 'Our story',
    ourStoryDesc: 'Who we are and what we do.',
    testimonial: 'Why people love us',
    testimonialDesc: 'Praise from customers, partners, and press.',
    history: 'History',
    prizeWheel: 'MCT Prize Wheel',
    community: 'Community',
    yourSaving: 'Accounts',
    crypto: 'Crypto',
    setting: 'Settings',
    liveChat: 'Live chat',
    forum: 'Forum',
    communicate: "Communicate",
    programs: 'Programs',
    extraMenu: {
      invest: {
        title: 'Invest',
        desc: '<p>Get a better return on <br/>your money.</p>'
      },
      depositFiat: {
        title: 'Deposit USD',
        desc: 'Automatic {interest}% APY.<br/>Withdraw anytime. No fees.',
      },
      investFlex: {
        title: 'Flex',
        desc: '{interest}% APY. Withdraw anytime.  <br/>No fees.',
      },
      investCustom: {
        title: 'Lend USD',
        desc: 'Your terms. Best rates. <br/>Backed by crypto.'
      },
      investLO: {
        title: 'Loan Originator',
        desc: 'Earn up to 11% APR. Terms from <br/>6-15 months. Accredited investors only.'
      },
      investFlexCrypto: {
        title: 'Lend Crypto',
        desc: 'Get {interest}% APY on USDT, USDC and more',
      },
      depositCrypto: {
        title: 'Deposit Crypto',
        desc: 'Deposit over {numCollateral} supported cryptos. Withdraw anytime.',
      },
      borrow: {
        title: 'Borrow',
        desc: '<p>Get cash for crypto without needing to sell.</p>'
      },
      borrowFiat: {
        title: 'Crypto-backed Loans',
        desc: 'Your terms. Best rates.<br/>No credit checks.'
      },
      cryptoShort: {
        title: 'Short Selling',
        desc: 'Short major cryptos inc. BTC, ETH, BNB & more...'
      },
      cryptoFuture: {
        title: 'Futures',
        desc: 'Predict crypto prices.<br/>Get paid when you\'re right.',
      },
      borrowCoins: {
        title: 'Crypto Credit',
        desc: 'Borrow cash, get crypto.<br/>No exchanges.'
      },
      shortSimple: {
        title: 'Short Selling',
        desc: 'Crypto short-selling made easy. High risk, high reward.'
      },
      lottery: {
        title: 'Lending Lottery',
        desc: 'Earn 1% APR and a chance to win up to $10 million.',
      },
      secondaryMarket: {
        title: 'Secondary Market',
        desc: 'Buy and sell existing investments.',
      },
      institutionalLoans: {
        title: 'Institutional Loans',
        desc: 'Crypto-backed loans tailored for your company - up to $10M.',
      },
      poFinancingLoans: {
        title: 'Invoice Factoring',
        desc: 'Enjoy rates from just 1% to meet your company’s on-the-spot needs and long-term business goals!',
      },
      spending: {
        title: 'Spend',
        desc: '<p>Fast, easy ways to spend your MyConstant balance.</p>',
      },
      giftCard: {
        title: 'Gift Cards',
        desc: '<p>Save up to 3% on everyday purchases with the MyConstant gift card.</p>',
      },
      debitCard: {
        title: 'Debit Card',
        desc: '<p>Spend your money anywhere online with the MyConstant debit card</p>',
      },
      cryptoSwap: {
        title: 'Crypto Swap',
        desc: '<p>Swap your crypto and vice versa.</p>',
      },
      mctStaking: {
        title: 'MCT Staking',
        desc: '<p>Earn up to a bank-busting {max_staking_rate}% APR</p>',
      },
      mctBuying: {
        title: 'Buy MCT',
        desc: '<p>Get our native token today</p>',
      },
    },
  },
  ourPress: {
    title: 'Our <span style="color: #F9A00F">Press</span>',
    desc: '<p>Our high-yield, collateral-backed, peer-to-peer investments have gotten a lot of press. Click <strong>View press</strong> below to find out what finance’s most respected publications think about us.</p>',
  },
  testimonials: {
    title: 'Let’s get to know each other better',
    desc: 'Transparency is important to us. Below, you’ll find reviews, testimonials, case studies, and other important information so you can use our platform with confidence.',
    viewAll: 'View All'
  },
  newHome: {
    crypto: {
      desc: 'Cryptocurrency collateral (held by us or liquidity pool partners) helps protect your investment returns from borrower defaults.',
    },
    whyItBetter: {
      title: 'Why it’s better',
      data: {
        '0': {
          title: 'Reliable returns',
          desc: 'Crypto <a class="glossary-word">collateral</a> helps protect your investments from borrower defaults.',
        },
        '1': {
          title: 'Free withdrawals',
          desc: 'Withdraw any amount in USD for free, straight to your bank account.',
        },
        '2': {
          title: 'Flexible terms',
          desc: 'Enjoy instant access to your funds or the choice of three fixed terms.',
        },
        '3': {
          title: '24/7 customer support',
          desc: 'No call centres, no automated messages, just real people ready to help you.',
        },
        '4': {
          title: 'No credit checks',
          desc: 'As long as you have the <a class="glossary-word">collateral</a> to back your loan, you’re always approved.',
        },
        '5': {
          title: 'No early repayment fees',
          desc: 'Repay your loan early with no penalties. You only pay interest on the days that you borrow.',
        },
      }
    },
    whyItBetter2: {
      title: 'You deserve a better rate',
      desc: '<p>The top US banks lend your deposits but keep most of the profits. With us, you keep all the interest you make – up to {investRate}% APR, which is over 4x better interest than a CD.</p>',
    },
    howItWorks: {
      title: 'How MyConstant works',
      desc: 'MyConstant connects investors with borrowers to do business together. Crypto <a class="glossary-word">collateral</a> backs investors’ funds while our custodial partner safely manages the movement of funds and collateral.',
      data: {
        '0': {
          title: 'Investors',
          desc: 'Investors deposit any amount and choose to either Lend USD or Lend Crypto.'
        },
        '1': {
          title: 'Collateralized borrowers',
          desc: 'Borrowers can access a loan easily by putting up a minimum of 150% of the loan amount in <a class="glossary-word">cryptocurrency</a> or a portfolio of cryptocurrencies to obtain the loan.',
        },
      }
    },
    banner: {
      title: 'Invest in global <span style="color:#F5A623;">peer-to-peer (P2P)</span> lending with USD or crypto.',
      subTitle: `
        <p> P2P lending allows you to invest in people and businesses around the world for up to {investRate}% <a class="glossary-word">APR</a>. All lending is backed by cryptocurrency <a class="glossary-word">collateral</a>. Earn across multiple markets, spreading risk and maximizing reward. No investing fees. Free USD withdrawals. 24-7 customer support.</p>
        <p>Or borrow against {numCollateral} cryptocurrencies from just {borrowRate}% <a class="glossary-word">APR</a>. Get the cash or crypto you need instantly without having to sell your portfolio. Your crypto is securely stored and returned to you when you repay.</p>
      `,
      newMessage: 'Why invest with MyConstant?',
      viewMore: '...more',
      intro: {
        youtube: { title: 'Fully Secured Peer To Peer Lending - How It Works' }
      },
      explore: 'Explore',
      term: 'Term',
      rate: 'Rate',
      invest: {
        header: "Invest",
        title: 'Lend USD',
        desc: "Your term. Best rates.<br/>Backed by crypto.",
      },
      borrow: {
        header: "Borrow",
        title: 'Crypto-backed',
        desc: "Your terms. Best rates.<br/>No credit checks.",
      },
      lendCrypto: {
        header: "Invest",
        title: 'Lend Crypto',
        desc: "Get {rate}% APY on USDC, USDT<br/>and more.",
      },
    }
  },
  whyPeopleLoveUs: {
    customers: {
      trustPilot: {
        desc: 'From great rates to speedy service, learn how we’ve earned our stars.',
        viewAll: 'View us on TrustPilot',
        readMore: 'Read more',
        reviews: 'Reviews',
        excellent: 'Excellent',
      },
      socialMedia: {
        comments: {
          tw: {
            '0': {
              auth: '@traders_insight',
              date: 'Jan 24, 2020',
              desc: `                    <p>I wrote up a full review of <a href="https://twitter.com/myconstantp2p" target="_blank">@Constantp2p</a>, a secured P2P lending platform. Right now I'm earning 8% APR on my 3rd 30-day term loan.</p>                    <p>I'm really excited about what the team has built and love the functionality behind it. Get ready for a podcast coming soon.</p>                  `
            },
            '1': {
              auth: '@traders_insight',
              date: 'Jan 24, 2020',
              desc: '                  <p>it’s great! thanks for launching.</p>                  <p>are you planning to incorporate <a href="https://twitter.com/Plaid" target="_blank">@Plaid</a> into your platform? would be much better for secure direct transfer of funds to/from banks.</p>                '
            },
            '2': {
              auth: '@UJOATcom',
              date: 'Feb 07, 2020',
              desc: '                  <p>you constantly keep exceeding my expectations. Awesome job and hats off to the team.</p>                '
            },
            '3': {
              auth: '@chartist_artist',
              date: 'Feb 05, 2020',
              desc: '                  <p>The platform and good and quickly response by Telegram of all req👍👍👍👍</p>                '
            },
            '4': {
              auth: '@RookieXBT',
              date: 'Dec 24, 2019',
              desc: '                  <p>2/ <a href="https://twitter.com/myconstantp2p" target="_blank">@constantp2p</a> is P2P lending platform that offers customers to use various currencies as collateral. Borrow or Invest, your choice!</p>                  <p>Including, but not limited to: $ENJ, $VET, $FTM, $BNB</p>                  <p>The offer great customer service and have an easy to use website!</p>                  <p><a title="https://www.myconstant.com?r=E6LGDDDH90" href="https://t.co/NODA5sOqAK?amp=1" target="_blank" rel="noopener noreferrer">myconstant.com/?r=E6LGDDDH90</a></p>                '
            },
            '5': {
              auth: '@RookieXBT',
              date: 'Dec 15, 2019',
              desc: '                  <p>You guys are amazing !</p>                '
            },
            '6': {
              auth: '@Christopher_Xii',
              date: 'Dec 06, 2019',
              desc: '                  <p>I hope to be able to staking on myConstant and receive a reward for HBwallet. That is a great feature for those who like passive income</p>                '
            },
            '7': {
              auth: '@Tim88288305',
              date: 'Dec 05, 2019',
              desc: '                  <p>great news! Looking to have more cool features between <a href="https://twitter.com/HBWallet_Ether" target="_blank">@HBWallet_Ether</a> & <a href="https://twitter.com/myconstantp2p" target="_blank">@constantp2p</a></p>                '
            },
            '8': {
              auth: '@anhntv2012',
              date: 'Nov 04, 2019',
              desc: `                  <p>Thank you, <a href="https://twitter.com/myconstantp2p" target="_blank">@constantp2p</a>, for making my Monday brighter 😍 Let's go staking $TOMO @TomoChainANN 💰💰💰</p>                `
            },
            '9': {
              auth: '@KryptoKarlsson',
              date: 'Oct 01, 2019',
              desc: '                  <p>You tried <a href="https://twitter.com/myconstantp2p" target="_blank">@constantp2p</a> yet? Really nice alternative to a centralized bank where you get 5% (!) interest only for depositing funds. Crypto-backed loans and P2P lending are also nice features! Register here and get a 10$ bonus: <a title="https://www.myconstant.com?r=K8VWG148GT" href="https://t.co/sO6Uws139B?amp=1" target="_blank" rel=" noopener noreferrer">myconstant.com/?r=K8VWG148GT</a> #crypto #btc #eth #DeFi</p>                '
            },
            '10': {
              auth: '@Markus30230949',
              date: 'Sep 30, 2019',
              desc: '                  <p>That’s awesome! Love my constant and the 10% market rate and 14% for BUSD is insane! Keep up the great work! Are you guys working on an IPhone App yet?</p>                '
            },
            '11': {
              auth: '@cz_binance',
              date: 'Sep 18, 2019',
              desc: `                  <p>Some much appreciated "competition" to <a href="https://twitter.com/binance" target="_blank">@binance</a> lending. Let's grow together!  🙏🙏🙏</p>                `
            },
            '12': {
              auth: '@KJNorthcote',
              date: 'Oct 11, 2019',
              desc: `                  <p>Interested in peer-to-peer lending and online investing? Here's part one of my review of <a href="https://twitter.com/myconstantp2p" target="_blank">@constantp2p</a> one of the most innovative lending platforms out there. https://kiannorthcote.com/2019/10/11/product-review-investing-made-easy-with-constant-part-one/ #p2p #investing #finance</p>                `
            }
          },
          viewMore: 'View more on',
          fb: {
            '0': {
              auth: 'Mike Desposito',
              date: ' ',
              desc: "                  <p>I love this platform, it is such a hidden gem that more people should know about. I've already been investing my money and making my 8%. Its so easy to deposit and withdraw. And your money makes 4% just sitting there when it's not in loan. That is an INSANE rate compared to other platforms or banks that offer between .01% to 1.7%. You literally cannot beat this and I am sure you guys are going to blow up big one day soon.</p>                "
            },
            '1': {
              auth: 'Robert Carpenter',
              date: 'Jul 30, 2019',
              desc: '                  <p>I have been using constant and love their setup for investing! I was very skeptical at first because it seemed too good to be true but in reality it is that great! Ultimately there is no fee to transfer money in or out, and it allows you to invest any amount of money for any rate that you would wish. Normally if I invest any sum of money for around 10% interest it is then matched to a borrower and I am able to earn the interest. If you go too high on the rate you don t lose anything but you also will not be matched to make money on your investment. It also lets you choose for how long to invest. Let me know if you have questions and I would be happy to explain more. My recommendation would be to set up an account and deposit a small amount to test it for yourself to get the hang of it.</p>                '
            },
            '2': {
              auth: 'Franky Tallavas',
              date: 'Feb 26, 2020',
              desc: "                  <p>It's a unique platform to invest in, and they provide great customer service. I was able to use Zelle to place and lend funds within minutes at 8%. So far so good.</p>                "
            },
            '3': {
              auth: 'Esther Dan',
              date: ' ',
              desc: '                  <p>Great product. I have invested thousands and receive my returns monthly. Thank you My Constant !</p>                '
            },
            '4': {
              auth: 'Alex',
              date: ' ',
              desc: '                  <p>FYI - I’ve used MyConstant without problems since 2019 - I have a substantial chunk of change invested with no problems or $ losses. It’s a similar business platform as Worthy Bonds (offering secured loans) where I invested too - but Worthy financial uses business assets as collateral for that loan, which is riskier as those assets can drop in value in times like these and are harder to liquidate if a business defaults on their loan. I like MyConstant approach to secured lending better and just moved more $ from my Worthy Bonds and Wealthfront accounts to MyConstant. I invested with Fundrise, Diversyfund and others that deliver good returns on investment - I’ve had 0 losses this past decade. I manage investments in my solo 401k too.</p>                '
            },
            '5': {
              auth: 'Ty Taylor',
              date: ' ',
              desc: '                  <p>Ive done over $35,000 already this year and not lost a penny. Last year with Proser P2p I lost over $5,000 in defaults.</p>                '
            }
          },
          tele: {
            '0': {
              auth: 'Dungeon',
              date: 'Mar 02, 2020',
              desc: '                  <p>Wow,thank you very much I was just doing it to help others here and to support Constant and team members since they were very helpful and kind towards me since I joined the group back in July 👍I appreciate it very much.I will always support you guys no matter what.🥳</p>                '
            },
            '1': {
              auth: 'Dungeon',
              date: 'Feb 26, 2020',
              desc: "                  <p>No worries,all of my past experiences with Constant and their team/communication were perfect as a customer so I just wanted to share that with others too,they are very trustworthy people.As in your case a little bit of delay is normal in my opinion because I believe most of their operational team live in UTC+7 time zone and it's past 2 am there right now.</p>                "
            },
            '2': {
              auth: 'Dungeon',
              date: 'Feb 04, 2020',
              desc: '                  <p>Also Constant team is working hard to add new features and they have the best support I have seen to be honest. Communications are great.</p>                '
            },
            '3': {
              auth: 'Dungeon',
              date: 'Dec 31, 2019',
              desc: "                  <p>As 2019 comes to an end I'd like to give a huge shout out to the Constant team,I joined here around middle of this year (July) before they have reached $1MM matching volume and they have achieved so much since then thanks to their hard work,I believe their professionalism and their constant support will make them the biggest crypto service in upcoming year and I also publicly would like to thank them on a personal level👍 @ningtan  @andy_9210 @claire_dang  @jtrain1 @chrisjroper @Peter_Constant @AnnLuong @Natew55</p>                "
            },
            '4': {
              auth: 'Dungeon',
              date: 'Dec 09, 2019',
              desc: "                  <p>You shouldn't be really worried,many people here including myself used Constant's services many times and our experiences with them was always very good as customers.There is nothing you should be worried about,Constant is honest and good business(in my opinion they are the most secure service in this industry)</p>                "
            },
            '5': {
              auth: 'Dungeon',
              date: 'Dec 06, 2019',
              desc: '                  <p>As a happy customer I would say Constant is the best lending service because of many factors but the most important thing is they have very good support. I already recommended their services to many people I know and they used Constant,now they are telling me they are very satisfied too.</p>                '
            },
            '6': {
              auth: 'John',
              date: 'Feb 26, 2020',
              desc: '                  <p>I understand the anxiety involved when it comes to trusting your hard worked money with a newish, virtual company. I feel that sometimes, but Constant has to this point given me no reason that my anxiety is provoked by them...the communication has been on point</p>                '
            },
            '7': {
              auth: 'John',
              date: 'Jan 11, 2020',
              desc: '                  <p>The customer service with Constant is top notch! 💯</p>                '
            },
            '8': {
              auth: 'ktn699',
              date: 'Feb 26, 2020',
              desc: '                  <p>i will vouch that constant - regardless of whatever bugs - has always gotten my coin and cash to where it needs to be. sometimes the delay is just with regards to finace team verification  and liquidity with certain coins, but to be honest, ive seen dramatic improvements over the last 3 months</p>                '
            },
            '9': {
              auth: 'ktn699',
              date: 'Jan 04, 2020',
              desc: '                  <p>constant website has been getting better and better</p>                '
            },
            '10': {
              auth: 'ktn699',
              date: 'Dec 31, 2019',
              desc: '                  <p>i agree that constant team has done a good job! bravo and looking forward to seeing your progress</p>                '
            },
            '11': {
              auth: 'Mike',
              date: 'Feb 04, 2020',
              desc: '                  <p>Constant is 900% better than other P2P lenders. Lending tree and all that stuff..... no guaranteed return on your $ and way less %apy</p>                '
            },
            '12': {
              auth: 'Stephen Tang',
              date: 'Feb 04, 2020',
              desc: '                  <p>Constant actually is the only platform that offers such diverse selection of alts for use as collateral.</p>                '
            },
            '13': {
              auth: 'HBK',
              date: 'Jan 23, 2020',
              desc: '                  <p>Constant is definitely a breath of fresh air. Hope the platform is doing well and sticks around!</p>                '
            },
            '14': {
              auth: 'Mike Le',
              date: 'Jan 09, 2020',
              desc: '                  <p>thanks for a great promotion campaign haha. The first time using incognito chain, working really smooth, really love the feature paying transaction fee by tokens</p>                '
            },
            '15': {
              auth: 'RookieXBT',
              date: 'Dec 24, 2019',
              desc: '                  <p>Yup very cool, Constant is covering all ground. Gotta love it. I have no problem sharing great products in the crypto space with others. Thank you for the great service and help this year! 💥🚀 you guys are the best.</p>                '
            },
            '16': {
              auth: 'RookieXBT',
              date: 'Dec 12, 2019',
              desc: '                  <p>💯 these guys are the most helpful, transparent service out there thats genuinely helping a ton of people out. I and many other people I know from Fantom and TomoChain community have nothing but great things to say about Constant.</p>                '
            },
            '17': {
              auth: 'Jadenn Isma',
              date: 'Dec 19, 2019',
              desc: '                  <p>Everybody saying constant is a good service😁</p>                '
            },
            '18': {
              auth: '2pac',
              date: 'Dec 07, 2019',
              desc: '                  <p>Hi guys, Im @2pac from Fantom Marines,  and I want to share my good experience with Constant,  in less of one day, I got $18.500 USD loan, and withdraw it without problems. Nice Service!!</p>                '
            },
            '19': {
              auth: 'Sanjeev_k',
              date: 'Dec 06, 2019',
              desc: '                  <p>Hahha apart from this service is quite Gud and Andy was very helpful</p>                '
            }
          }
        },
        title: 'SOCIAL MEDIA',
        desc: 'Find out what <strong>1,000s of followers</strong> have to say about their MyConstant experience. Want to join the conversation? Click an icon below to get started.'
      },
      testimonials: {
        title: 'Testimonials',
        desc: 'Get the inside scoop from customers eager to share their stories with you.',
        articles: {
          '1': {
            author: 'Waltonchain (Capital Region)',
            date: 'Nov 20, 2019',
            title: 'My Experience with Constant- P2P Lending'
          },
          '2': {
            author: 'ktn699 | Miscellaneous',
            date: 'Oct 20, 2019',
            title: 'Review of Constant: a borderless, block-chain based P2P lending platform'
          },
          '3': {
            author: 'Kian',
            date: 'Oct 11, 2019',
            title: 'Product Review: Investing Made Easy with MyConstant – Part One'
          },
          '4': {
            author: 'Kian',
            date: 'Dec 13, 2019',
            title: 'One Month review on shopify and Myconstant accounts'
          },
          '5': {
            author: 'End of the Chain',
            date: 'Dec 13, 2019',
            title: 'Constant - Secured P2P Lending Platform Review'
          },
          '6': {
            author: 'Constant',
            date: 'Jun 27, 2019',
            title: 'Why Tung invested all his TUSD savings with Constant'
          }
        }
      }
    },
    title: 'Why People Love Us',
    tabs: { customers: 'Customers', press: 'Press', partners: 'Partners' },
    viewFullArticle: 'Read full article',
    press: {
      consumerFinance: {
        title: 'CONSUMER FINANCE',
        desc: '<p>As the new kid on the block, <strong>we get a lot of press.</strong> Journalists cover everything from our invesment products to the flexibility and support you get along the way. Find out what finance’s most respected publications think about us below.</p>',
        data: {
          '1': {
            desc: 'Constant which allows borrowers and lenders to transact directly. Banks, brokers and other third parties are cut off from any deal, and therefore access to financial data is minimized. Constant lowers borrowing costs by removing middlemen, and thereby streamlining processes.',
            link: 'https://www.inc.com/kenny-kline/startups-have-these-advantages-over-large-enterprises.html'
          },
          '2': {
            desc: 'Constant acts as a trading hub where borrowers and creditors pick the best deal. Moreover, the Redlands, Calif.-based venture allows users to set their own interest rates and terms -- a feature that doesn’t exist anywhere else.',
            link: 'https://www.entrepreneur.com/article/344621'
          },
          '3': {
            desc: 'Lenders can choose their terms, no credit check, and no nonsense – and find borrowers. Programming a system like this using the US banking system, would be expensive, cumbersome, time consuming, and who knows what it might look like after all the regulatory approvals.',
            link: 'https://www.zerohedge.com/news/2019-08-30/lending-stable-coin-threatens-traditional-banking'
          }
        }
      },
      technology: {
        title: 'Technology',
        desc: '<p><strong>Technology is our heart,</strong> and customers are our lifeblood. We combine the two to build products that make a positive difference to people’s lives.</p>',
        data: {
          '1': {
            desc: 'The traditional loan agreement has been replaced by an unstoppable Ethereum smart contract without any possibility of downtime, fraud, or third-party interference. Fully-secured, blockchain-enabled P2P lending is our contribution to a bankless banking system.',
            link: 'https://hackernoon.com/how-we-built-constant-a-secured-p2p-lending-platform-that-puts-customers-in-control-6820e32d8402'
          },
          '2': {
            desc: 'The best thing about Constant, however, is the security the lending platform has in place. Every loan is fully secured and backed in multiple ways. While on loan it’s protected by borrower collateral, and while waiting to be matched it’s protected by a $130M insurance policy.',
            link: 'https://www.techworm.net/2019/09/technology-changing-way-finance.html'
          },
          '3': {
            desc: 'Constant works by enabling borrowers to stake liquid capital via over-collateralized crypto assets (e.g., Ether) based on an LTV ratio. Subsequently, borrowers can “cherrypick” the best deals enumerated on Constant’s interface, selecting the best interest rates that are posted independently by each lending peer.',
            link: 'https://techbullion.com/why-constants-rise-is-indicative-of-the-blossoming-class-of-defi-platforms/'
          }
        }
      },
      blockchain: {
        title: 'Blockchain',
        desc: '<p><strong>We believe in the future of blockchain</strong> and are proud members of the crypto community. As our voice has grown, the crypto press has helped tell our story to the world.</p>',
        data: {
          '1': {
            desc: 'And when it comes to pioneering a new generation of fintech platforms, Constant provides a measuring stick for the industry’s progress.',
            link: 'https://www.coinspeaker.com/constant-p2p-lending-growth/',
          },
          '2': {
            desc: 'Constant has created a platform that cuts banks out of the lending equation and allows anyone in the world to create loans on whatever terms they like. This is a big shift away from the established lending system, and it could be a big benefit for both borrowers and lenders.',
            link: 'https://blockonomi.com/constant-decentralized-p2p-lending/',
          },
          '3': {
            desc: 'Constant, a leading secured P2P lending platform, has been expanding its service and product offerings in recent months as it continues to originate more loans on its Ethereum-based lending service.',
            link: 'https://cryptogazette.com/how-constant-is-pioneering-the-exploration-of-a-new-fintech-generation/',
          },
          '4': {
            desc: "It's a great service, with free deposits and withdrawals, extremely fast responding customer support and a transparent team.",
            link: 'https://www.endofthechain.com/constant-secured-p2p-lending-platform-review/',
          },
          '5': {
            desc: 'Chắc hẳn sẽ có những lúc chúng ta rơi vào hoàn cảnh khi đang HODL một đồng coin nào đó, nhưng lại thấy 1 cơ hội khác trong khi quỹ vốn thì đã hết. Constant sẽ giúp anh em huy động được một lượng cash (tiền mặt) nhất định để vào được kèo mới nhưng không phải cash out (bán) đồng coin đang HODL.',
            link: 'https://coin98.net/constant-la-gi/',
          }
        }
      },
      affiliate: {
        title: 'Affiliate',
        desc: 'Our affiliates lead passionate communities in finance and investing. We’re proud to add our voice to their platforms and help their audiences do more with their money.',
      },
    },
    partners: {
      custody: {
        title: 'Custody & Settlement',
        data: {
          primetrust: {
            desc: '<p>Prime Trust is the independent, accredited custodian of our USD reserve. When you deposit, Prime Trust stores your money across multiple bank accounts <strong>insured to a total of $130,000,000.</strong></p>'
          },
          cb: {
            desc: '<p>Checkbook helps settle withdrawals via ACH and digital cheque, making transfers cheaper for US residents.</p>'
          },
          qc: {
            desc: '<p>QCP Capital helps manage collateral and offers occasional trading services.</p>'
          }
        }
      },
      blockchain: {
        title: 'Blockchain',
        desc: '<p>We’ve signed partnerships with <strong>blockchain’s biggest movers and shakers.</strong> Together, we work hard to help you do more with your assets.</p>',
        vip: {
          name: 'CZ Binance',
          auth: '@cz_binance',
          desc: `<p>Some much appreciated "competition" to <a href="https://twitter.com/binance" target="_blank">@binance</a> lending. Let's grow together!</p>`
        }
      }
    }
  },
  modules: {
    faqs: {
      title: 'Frequently-asked questions (FAQs)',
      desc: `Here you'll find answers to the most common questions our customers ask. If you can't find your answer here, please email us at <a href="mailto:hello@myconstant.com" class="landing-link semiBold" target="_top">hello@myconstant.com</a> and we'll be happy to help you.`,
      viewAll: 'View all',
      viewLess: 'View less',
      explore_our_app: '<span style="color: #F9A00F">Explore</span> our app'
    }
  },
  home: {
    constantLoan: {
      intro: {
        content: `
            <h1>Get a <span class="orange">crypto-backed loan</span> against multi-coin collateral.</h1>
            <p>Borrow against your multi-crypto portfolio in minutes from just {rate}% APR. Withdraw in fiat, stablecoins, or trade your loan for cryptocurrency at the best prices. Your collateral is securely stored and returned when you repay. No exchanges. No trading fees. Trade or cash-in the value of your portfolio without selling up.</p>
        `,
        youtube: { title: 'Why borrow with MyConstant?' },
        desc: {
          '1': 'Borrow from just {rate}% APR',
          '2': 'Combine {numCollateral} cryptocurrencies as collateral',
          '3': 'Get cash or coins without selling up'
        }
      },
      exchange: {
        amountLabel: 'How much do you want to borrow?',
        collateralRequiredLabel: 'Collateral required ({value}% LTV)',
        minTerm: 'Length of term',
        minTermTooltip: 'This is how long you want to borrow for.',
        toolTipCollateral: 'This is the amount of collateral required for the {exchangeUnit} loan. Collateral value is based on the current market price of {exchangeRate}. Check <a href="/withdraw-fees?from=borrow">here</a>',
        apr: 'APR',
        availableCredit: 'Available Credit',
        buttonText: 'Borrow now',
        borrowConfirm: "You're about to borrow {amount} {currency} for {term} days at {rate}% beginning {startDate} and ending {endDate}. Click confirm to start your term and receive your loan. Once your term begins you won't be able to cancel your loan, but you can repay early from your Accounts page.",
        borrowIsolateConfirm: "You're about to borrow {amount} {currency} for {term} days at {rate}% beginning {startDate} and ending {endDate} with {collateralAmount} {collateral} as collateral. Click confirm to start your term and receive your loan. Once your term begins you won’t be able to cancel your loan, but you can repay early from your Accounts page",
        borrowCoinConfirm: "You're about to borrow {amount} {currency} for {term} at {rate}% beginning {startDate} and ending {endDate}. We'll use these funds to buy a maximum of {receiveAmount} to be deposited into your coin balance.\n\nClick confirm to start your term and receive your loan. Once your term begins you won't be able to cancel your loan, but you can repay early from your History page.",
        borrowCoinIsolateConfirm: "You're about to borrow {amount} {currency} for {term} at {rate}% beginning {startDate} and ending {endDate} with {collateralAmount} {collateral} as collateral. We'll use these funds to buy a maximum of {receiveAmount} to be deposited into your coin balance.\n\nClick confirm to start your term and receive your loan. Once your term begins you won't be able to cancel your loan, but you can repay early from your History page.",
        borrowConfirmNote: "Click confirm to start your term and receive your loan. Once your term begins you won't be able to cancel your loan, but you can repay early from your Accounts page.",
        collateralBalance: 'Collateral Balance',
        isolate: 'Isolate',
        isolateDesc: 'Isolate your loan to use a single cryptocurrency as collateral. If you already have a multi-collateral loan the amount of collateral you can isolate may be limited. Check out our FAQs for more details',
        stakingTimeMessage: 'Your collateral will be sent to the MyConstant node. Please note: early repayment won’t be possible for at least {dayNum} days.',
        exchangeInfo: 'Estimate amount USD (const) you need to borrow',
        exchangeInfoDesc: 'Estimate amount USD (const) you need to borrow',
        usedMarketRate: 'Use market rate ',
        usedMarketRateDesc: "When market rate is enabled, you're guaranteed a match at the best rate and term available in the market.",
        maxRate: 'Your maximum interest rate',
        maxRateTooltip: 'This is the annual percentage rate (APR) you are willing to pay for your loan. If there is an investor willing to earn less, you will pay the lower interest rate.',
        caption: 'Crypto-backed loan',
        captionPRV: 'Get the best loan rate in the market',
        month: '{period}-month',
        months: '{period}-month',
        termLabel: 'Pick a loan term',
        collateralLabel: 'Collateral required (66% Loan to Value ratio)',
        startDateLabel: 'Start date',
        endDateLabel: 'End date',
        collateralNote: 'For this coin, loans will take up to 24 hours to be granted, repaid, or topped up.',
        collateralNoteFee: 'Fee: {fee} {currency} (1%)',
        collateralNoteMatchedFee: 'Fee: 1%',
        yourBalance: 'Your current balance is {value} {symbol}',
        collateralNoteTotal: 'You will receive: {total} {currency}',
        collateralNoteDGXExtraFees: 'Digix deducts an additional 0.13% fee on all DGX transfers. This fee will be deducted from your coin balance.',
        usingStaking: 'Get {discount}% off your rate by staking collateral',
        usingStakingMobile: 'Get {discount}% off with staking',
        usingStakingDesc: 'Stake your collateral in our masternode pool to save {discount}% on your loan. Please note staked collateral takes 2 business days to withdraw and you can’t recall excess.',
        stakingMinAmount: 'The staking amount must be greater than {minVal} {currency}',
        collateralNoteMatch: 'Estimated time to match: {hours} hours',
        collateralNoteMatchTime: 'The rate you entered will have a longer time of matching, according to our past data. Estimated time of matching {hours} hours {type}.',
        collateralNoteMatchNoneTime: 'The rate you entered will have a longer time of matching, according to our past data. Estimated time of matching.',
        collateralNoteMatchTimeMax: 'maximum',
        collateralNoteMatchTimeApprox: 'approx',
        collateralNoteMatchInWorkingTime: 'Estimated time to match 1 hour',
        walletAddressLabel: 'Receiving {symbol} wallet for returned funds',
        walletAlias: 'Enter your {symbol} wallet address',
        moreInfo: 'Your funds will reach you as soon as your collateral is confirmed on its blockchain.  ETA: 2 minutes for ETH and 10 minutes for BTC.',
        monthLoan: '{term}-month loan',
        monthsLoan: '{term}-month loan',
        dayLoan: '{term}-day loans',
        receiveAmountLabel: 'Loan in USD or cryptocurrency?',
        receiveAmountLabelDesc: 'We can pay your loan in USD or cryptocurrency. When choosing to receive your loan in cryptocurrency, you’re borrowing USD which we use to buy your chosen cryptocurrency. We search multiple exchanges for the best prices, and you might receive a little more or less cryptocurrency than the amount indicated below depending on the final price paid.',
        overCollateralCap: `We've reached our collateral limit on new loans against {symbol}. Please choose another cryptocurrency as collateral.`,
        overBorrowAmount: 'Sorry, the maximum you can borrow in one order is ${max}. Please choose a smaller amount and try again. <a href="#landing-faqs">Why is there a maximum?</a>',
        overBorrowTerm: 'At the moment, with this term and rate you can only borrow a maximum ${max} amount. Please adjust the amount of money borrowed or try again using a different term and rate. Thank you.',
      },
      what: {
        title: 'Why borrow against your crypto with MyConstant?',
        data: {
          0: {
            title: 'Withdraw in fiat or crypto',
            desc: '<div>Get USD straight to your bank account or trade your loan for cryptocurrency. We search multiple exchanges for the best prices in the market and never charge you trading fees.</div>',
          },
          1: {
            title: 'Flexible terms',
            desc: '<div>Borrow any amount for 1, 3, 6 or 9 months, giving you the flexibility to repay at a time that suits you.</div>',
          },
          2: {
            title: 'Combine or isolate collateral',
            desc: '<div>Borrow against your whole portfolio (multi-collateral) or isolate a single crypto, giving you better leverage, repayment, and top-up options.</div>',
          },
          3: {
            title: 'Secure escrow',
            desc: '<div>Smart contracts and Prime Trust custody protect your collateral while ensuring we can return it to you as quickly as possible.</div>',
          },
          4: {
            title: 'No credit scoring',
            desc: '<div>No credit scoring or background checks. Get funds instantly, no month-long waiting games for approval.</div>',
          },
        }
      },
      howItWorks: {
        title: 'How to get a crypto-backed loan',
        data: {
          0: {
            title: 'Choose a term',
            desc: '<div>Borrow any amount for 30, 90, or 180 days. The shorter your term, the lower the interest.</div>',
          },
          1: {
            title: 'Choose cash or crypto',
            desc: '<div>Receive your loan in USD or cryptocurrency. We always source the best cryptocurrency prices from multiple exchanges and you never pay any trading fees.</div>',
          },
          2: {
            title: 'Deposit your collateral',
            desc: '<div>Send collateral to secure your loan. You can combine different cryptocurrencies to meet the minimum collateral value.</div>',
          },
          3: {
            title: 'Withdraw your loan',
            desc: '<div>Once you’ve sent your collateral, you can withdraw your loan in fiat or stablecoins. Your collateral is securely stored and returned to you when you repay.</div>',
          },
          4: {
            title: 'Repay at anytime',
            desc: '<div>It\'s no early repayment fees applied. Repay anytime with no penalties in fiat, stablecoins, or crypto to get your collateral back. Then withdraw your collateral to your wallet of choice or use it for another loan.</div>',
          },
        },
      },
      compareTable: {
        title: 'Why borrow against your crypto with MyConstant?',
        cryptoBackedLender: {
          title: 'Other crypto-backed lenders',
          benefits: 'Benefits',
          nexo: 'Nexo',
          celsius: 'Celsius',
          blockfi: 'Blockfi',
          withdrawInCrypto: {
            title: 'Withdraw in crypto?',
            constant: '<i class="far fa-check"></i><sup>*</sup>',
            nexo: '<i class="far fa-times"></i>',
            celsius: '<i class="far fa-times"></i>',
            blockfi: '<i class="far fa-times"></i>',
          },
          ltvRatio: {
            title: 'LTV ratio',
            constant: 'Up to 66%',
            nexo: 'Up to 90%',
            celsius: 'Up to 50%',
            blockfi: 'Up to 50%',
          },
          supportedCryptos: {
            title: 'Supported cryptos',
            constant: '{numCollateral}',
            nexo: '<20',
            celsius: '28',
            blockfi: '3',
          },
          recallExcess: {
            title: 'Recall excess mid-term?',
            constant: '<i class="far fa-check"></i>',
            nexo: 'For repayment of loan only',
            celsius: '<i class="far fa-check"></i>',
            blockfi: '<i class="far fa-times"></i>',
          },
          autoTopup: {
            title: 'Auto top-up',
            constant: '<i class="far fa-check"></i>',
            nexo: '<i class="far fa-times"></i>',
            celsius: '<i class="far fa-times"></i>',
            blockfi: '<i class="far fa-times"></i>',
          },
          description: '<sup>*</sup> Trade your USD loan for a supported cryptocurrency at the best prices without exchanges or trading fees.',
        },
        exchangeMarginAccount : {
          title: 'Exchange margin accounts',
          benefits: 'Benefits',
          // const: 'MyConstant',
          exchangeMarginAccount: 'Exchange margin accounts',
          term: 'Set your own terms',
          price: 'Best prices',
          support: '24/7 customer service',
          cryptos: '{numCollateral} supported cryptos',
          withdraw: 'Free withdrawals',
          description: '(?) We search multiple exchanges to find the best price for your chosen cryptocurrency. <br/>Exchange margin accounts typically only use the price on that exchange, so you can miss out on better deals elsewhere. ',
          priceDesc: 'We search multiple exchanges to find the best price for your chosen cryptocurrency.<br/>Exchange margin accounts typically only use the price on that exchange, so you can miss out on better deals elsewhere.',
          // apr: 'Average cost to borrow (APR)',
          // trading: 'Free trading (no fees)',
          // percent: '7%',
          // otherPercent: 'Up to 13.%'
        }

      },
      promotion: {
        title: "Why take part in\nthis promotion?",
        step_1: "Get uncapped, interest-free loans against {symbol}.",
        step_2: "Withdraw your loan in USD or another crypto of your choosing. ",
        step_3: "Use borrowed USD to invest for 30 days at 6% APR, profiting on the loan",
        step_4: "Receive loan in BTC, ETH, or BNB for an automatic 4% APY",
        step_5: "Leverage your existing {symbol} to buy more {symbol}",
        step_6: "Pay just a 3.5% matching fee on the loan amount.",
        step_7: "Comment with other vetted customers – scam free – through our collaboration. ",
        step_8: "When you use your crypto to borrow, you only pay a 3.5% matching fee. ",
        step_9: "Promotion period lasts 1 week from the date of the collaboration event.",
        step_10: "Enjoy all the benefits from the cashback program (for US citizens) / or accumulate bonus points. ",
        step_11: "With 0% APR, you have nothing to lose. Give it a try today!",
        step_12: "With 0% APR, unlimited order or any amount, you have nothing to lose. Give it a try today!",
        join_now: "Join now"
      },
      allTermPromotion: {
        title: "It’s Easy & Affordable—Financing Starting at 4% Only!",
        step_1: "Get uncapped, special 4% APR for all terms.",
        step_2: "Peace of mind knowing your asset is secured, our financing is safe.",
        step_3: "Huge selection of assets to choose from.",
        step_4: "Use borrowed USDT/ USDC to reinvest at 12% APY, profiting on the loan.",
        step_5: "Leverage your existing cryptos to buy more cryptos.",
        step_6: "Withdraw your loan in USDT/ USDC or another cryptos of your choosing.",
        step_7: "Repay anytime, anywhere according to your plan with no penalties.",
        step_8: "Plus, there's no limit to how much you can borrow, so take advantage today!",
        join_now: "Don't wait! Borrow now.",
      },
      cryptosDesc: 'Your collateral is securely stored in either a Prime Trust cold wallet, an Ethereum smart contract, or a password-protected web wallet until you repay your loan.',
      listApplications: {
        notAllowReBorrowing: 'Sorry, you’re unable to repay using refinancing as your collateral value rate is less than 125%. To enable refinancing, please deposit more collateral.',
        headingDetail: 'Loan History Detail',
        title: 'Loan History',
        overview: 'Overview',
        mapStatus2: {
          pending: 'Pending',
          received: 'Matching',
          accepted: 'Matched',
          done: 'Done',
          timeout: 'Timed out',
          cancelled: 'Cancelled',
          payingInterest: 'Interest due',
          withdrawed: 'Withdrawn',
          tnxFailed: 'Please try again',
          rejected: 'Error occurred',
          chainBorrowPending: 'Pending',
          chainBorrowOk: 'Success',
          chainBorrowFailed: 'Please try again',
          chainCancelPending: 'Cancelled',
          chainCancelOk: 'Cancelled',
          chainCancelFailed: 'Please try again',
          chainBorrowCancel: 'Cancelled',
          creating: 'Processing',
          cancelling: 'Cancelling',
          manualPending: 'Pending',
          allStatus: 'All',
          partiallyCancelled: 'Partially cancelled'
        },
        availableCredit: 'Available credit',
        totalDebt: 'Total debt',
        collateralValue: 'Collateral value',
        avgInterest: 'Average interest',
        deposit: 'Deposit',
        recallExcess: 'Recall Excess',
        createdDate: 'Created Date',
        amount: 'Amount',
        rate: 'Rate',
        term: 'Term',
        interest: 'Interest',
        matched: 'matched',
        cancel: 'Cancel',
        cancelBorrow: 'Cancel',
        collateralRequired: 'Collateral',
        collateralAddress: 'Collateral Address',
        noLoans: 'Request your fixed rate loan.',
        delete: 'Delete',
        redeemApply: 'Redeem STAR',
        reBorrowingInfo: 'We will create new loan for you with these information:',
        mapStatus1: {
          done: 'Done',
          liquidatePending: 'Liquidation In Process',
          liquidateFailed: 'Liquidate Failed',
          pending: 'Pending',
          repayFail: 'Repay Fail',
          approved: 'Matched',
          payoffDone: 'Repaid',
          rejected: 'Rejected',
          paid: 'Paid',
          cancelled: 'Cancelled',
          tnxPending: 'Transaction Pending',
          repayPending: 'Repay Pending',
          repayUpPending: 'Repay Up Pending',
          repayUpFail: 'Repay Up Fail',
          repayDownPending: 'Repay Down Pending',
          repayDownFail: 'Repay Down Fail',
          payoffPending: 'Payoff Pending',
          payoffFail: 'Payoff Fail',
          fillPending: 'Fill Pending',
          fillFail: 'Fill Fail',
          repayDownDone: 'Repay Down Done',
          topupPending: 'Top Up Pending',
          manualPending: 'Manual Pending',
          liquidateApproving: 'Liquidate Approving',
          liquidateChainPending: 'Liquidate Chain Pending',
          liquidateDone: 'Liquidate Done',
          payoffPrvPending: 'Payoff Pending',
          payPrvPending: 'Payoff Pending'
        },
        depositCollateral: 'Deposit Collateral',
        collateralType: 'Collateral Type',
        availableAmount: 'Available amount',
        yourLoan: 'Match{plural} {matched}',
        itemMatched: {
          amount: 'Amount',
          interest: 'Interest',
          createdDate: 'Created',
          dueDate: 'Due',
          matchedAmount: 'Matched (USD)',
          matchedAmountDesc: 'The amount of USD you borrowed. This might be less than your original order if you only got a partial match.',
          filledCollateralAmount: 'Bought (Crypto)',
          filledCollateralAmountDesc: 'How much crypto we bought using your USD loan. Depending on the final sale price, this might be a bit less than your original order',
          maxCollateralAmount: 'Max Collateral',
          filledAmount: 'Used (USD)',
          filledAmountDesc: "The amount of USD we used to buy your chosen crypto. If we pay less than the matched amount, we'll return the difference to your account.",
          collateral: 'Collateral',
          collateralValue: 'Collateral value',
          collateralRate: 'Collateral Rate',
          liquidationPrice: 'Liquidation Price',
          collateralValueDesc: 'This represents your collateral value as a percentage of your loan. It accounts for the interest accrued so far, as well as the repayment fee should you choose to repay today. If it falls to 110%, your collateral will be liquidated, and the difference refunded to you.',
          repayAddress: 'Repay Address',
          collateralValueAtLiquid: 'Liquidation price',
          collateralValueAtLiquidDesc: 'Once the price of the token falls to this price, your loan will be liquidated.          Note: this number is always changed unpredictably due to the fluctuation of the market. Please keep an eye on your loans to avoid liquidation.',
          collateralValueAtLiquidC2CDesc: 'Once the price of the collateral falls to this price, your loan will be liquidated.          Note: this number is always changed unpredictably due to the fluctuation of the market. Please keep an eye on your loans to avoid liquidation.',
          price: 'Price',
          priceDesc: 'Price of your chosen crypto',
        },
        partialMatchesDesc: 'Some loan requests may be split into smaller amounts to fill multiple investment orders. The platform will keep trying to match you completely for the duration of your term.',
        isolate: 'Isolate',
        payOffLoan: 'Repay',
        isolateTooltip: 'Isolate lets you secure your loan with one collateral instead of your multi-collateral balance. <a href="https://myconstant.com/blog/multi-crypto-loans/" target="_blank">What’s multi-collateral?</>',
        extraRate: 'Your total rate will be {rate}% effect from {date}',
        errorRecallLowCreditRate: '<p>To recall excess, your collateral rate must be more than {recallRate}%. Your current collateral rate is {creditRate}%</p><p>The collateral rate is in proportion with LTV and collateral prices. Please check the LTV and price for each token <a href="/withdraw-fees">here</a>.</p>',
        heading: 'Loans',
        bep2Memo: 'Memo',
        bep2MemoRequired: 'Memo is required!',
        bep2MemoDesc: 'Memo maybe is your ID if PEP2 crypto!',
        walletAlias: '0x687C906b2....8bd9369242e',
        walletAddressLabel: 'Return to your {symbol} address',
        loan: 'loan',
        staked: 'STAKED',
        availableBalance: 'Available Balance',
        minAmount: 'Min Amount',
        maxAmount: 'Max Amount',
        minIsolateMessage: 'To isolate this loan using {symbol}, you need to enter a minimum of {amount} {symbol}. This is {rate}% of the loan amount.',
        maxIsolateMessage: 'To avoid risking other loans, the maximum you can enter is {amount} {symbol}.',
        maxIsolateRateMessage: 'This reduces your multi-collateral balance to {rate}% of the value of all outstanding loans.',
        recommendMessage: 'To restore your collateral value to the recommended {recommendedRate}%, please top up {amount} {currency}',
        pendingAmount: 'Pending Amount',
        exchangeRates: 'Exchange rates: 1 {symbol}/{value} USD',
        endTerm: 'Term ends',
        principal: 'Principal',
        interests: 'Interest',
        created: 'Created',
        dueDays: 'Due',
        getCollateral: 'Recall excess',
        payCollateral: 'Pay',
        depositMatched: 'Top up',
        topup: 'Top-up',
        recall: 'Recall',
        cancelDepositMatched: 'Cancel Deposit',
        cashback: 'Cash back',
        collateral: 'Collateral',
        liquidWaring: 'Your collateral will be liquidated, please top up more collateral.',
        interestPrice: 'Interest',
        status: 'Status',
        allStatus: 'All',
        mapStatus: {
          pending: 'Pending',
          recieved: 'Received',
          accepted: 'Accepted',
          payingInterest: 'Paying',
          done: 'Done',
          withdrawed: 'Withdrawed',
          tnxFailed: 'Transaction failed',
          cancelled: 'Cancelled',
          rejected: 'Rejected',
          expired: 'Timed out',
          fullPayment: 'full payment'
        },
        getRemainCollateral: '          Are you sure you want to recall <strong>{valueAvailable} {symbol}</strong> to bring your collateral value back to the minimum required amount?        ',
        actionsModal: { cancel: 'Cancel', confirm: 'Confirm' },
        amountLabel: 'Loan amount',
        feePayLabel: 'Late Payment Fee',
        feePayoffLabel: 'Early repayment fee',
        feePayoffDesc: ' If less than 75% of your term has elapsed, the early repayment fee is 50% of the interest on your remaining term. If more than 75% has elapsed, 100% of the interest on the entire term is due.',
        matchingFee: 'Matching fee',
        interestEarly: 'Interest due to date',
        interestOnTime: 'Interest due',
        interestDue: 'Interest due',
        totalRepayment: 'Total repayment',
        totalPayment: 'Total Payment',
        repaymentByCollateral: 'Do you want to repay with collateral?',
        repaymentMethod: 'Payment method: ',
        willPayCollateral: 'Collateral to be repaid',
        willReturnCollateral: 'Collateral to be returned',
        feePayoffExchangeRate: 'Collateral value is based on the current market price of {value} USD/{currency}',
        payCollateralFee: 'Repay with collateral fee',
        payCollateralFeeDesc: 'This fee is to cover the difference in price during liquidation process.',
        repayAddress: 'Repay address',
        copyMessage: 'Address has been copied!',
        errorRemainCollateral: 'You can not to get the remaining collateral back at the moment',
        errorRemainCollateralHtml: '          Please wait for assistance, or contact us at <a href=mailto:hello@myconstant.com">hello@myconstant.com</a>',
        errorCollateralNotEnoughHtml: 'Your collateral value is {percent}% of loan to value. That’s why you can’t do recall excess.',
        errorRecallLowCollateralRate: 'To recall excess, collateral rate must be more than {requiredRate}%. Your current collateral value is {currentRate}%',
        errorIsolateNotEnough: 'Your Collateral Balance does not meet the minimum amount required to set multi-collateral to isolated.',
        depositFrom: {
          title: 'Deposit more collateral',
          amount: 'Amount',
          requiredAmount: 'Amount is required!',
          amountDesc: 'How much do you want to deposit?',
          ok: 'Make deposit',
          cancel: 'Cancel',
          depositFailed: 'Failed while depositing, please try again',
          depositSuccess: 'Your deposit was completed successfully'
        },
        paymentMethod: {
          const: 'USD',
          collateral: 'Collateral',
          reBorrowing: {
            title: 'Refinancing',
            desc: 'Refinancing allows you to repay your loan and any applicable fees by taking out another loan against your collateral. However, please note refinancing results in you paying more interest over time, reduces your collateral value rate (which may result in greater risk of liquidation), and lowers your capacity to borrow unless you deposit more collateral. ',
          },
        },
        footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
        repay: 'Repay',
      },
      transaction: {
        copiedAddress: 'Address is copied to clipboard',
        scanQR: 'Alternatively, scan this QR code directly from your mobile wallet to complete the transfer.',
        title: 'Almost there.',
        message: 'To finalize your loan, please send your collateral to the following wallet address.',
        cryptoAddress: '{currency} address:',
        bitgo: {
          title: 'Secured by BitGo',
          content: 'You’re sending your collateral to BitGo, a qualified digital asset custodian. Your collateral will be securely stored inside a Prime Trust wallet that’s insured to $100M. So no matter what happens to MyConstant, your collateral is secure.'
        },
        summarizeTitle: 'Here are the loan terms:',
        loanAmount: 'Loan amount',
        creditHolding: 'Credit holding',
        collateralBalance: 'Collateral available',
        collateralRequired: 'Collateral required',
        term: 'Term',
        termUnit: '{period} month',
        interestedRate: 'Interest rate',
        interestedRateValue: '{percent} APR',
        matchingFee: 'Matching fee',
        matchingFeeValue: '{matchingFee}% of loan amount',
        repaymentField: '            <div class=row">',
        minuteWindow: 'Please complete your transfer within 60 minutes.',
        minuteWindowDescription: 'For the sake of security, this session will time-out after 60 minutes. If you need a little longer, just re-enter the amount you would like to borrow <a href="/borrow" >here</a>.',
        msgInfoAuto: '          <span>            Once we receive your collateral, your loan status will change from <b>pending</b> to <b>matching</b>. This is usually very fast – around 1 block confirmation – but might take a little longer during busy times. When your status changes, it means we’ve begun finding an investor to accept your terms. We’ll email you as soon as we find you a match.          </span>        ',
        backOrderBook: 'Back',
        backAccount: 'Done',
        exchangeMaxAmount: 'Exchange Max Amount',
        collateralToDeposit: 'Collateral to deposit',
        collateralBalanceDesc: 'Your current collateral balance.',
        collateralRequiredDesc: 'The total amount of collateral needed to secure your loan.',
        collateralToDepositDesc: 'The amount of collateral you need to deposit to back your loan.',
        messageDescription: '        <p>Please open your cryptocurrency wallet, enter the required collateral amount, paste the MyConstant wallet address into the recipient field, and click send.</p>        <p>Having difficulty finding your cryptocurrency wallet?           If you purchase cryptocurrency from an exchange, you will have a wallet with them, usually accessible from your Accounts page.</p>        ',
        msgInfoManual: "          <span>At the moment, {symbol} deposits aren’t automatic, so we need to change your loan status manually. Therefore, please give us <b>up to 12 hours</b> to confirm receipt of your collateral (it's usually much faster).</span>          <br />          <span>If you have any questions, please email us at hello@myconstant.com or message <a href='https://t.me/@andy_9210'>Andy</a>, our head of customer service, on Telegram.</span>          <br />          <span>We'll start finding you a match as soon as we receive your collateral.</span>        ",
        collateralNoteDGXExtraFees: 'Digix deducts an additional 0.13% fee on all DGX transfers. This fee will be deducted from your coin balance.',
        transactionID: 'transaction ID',
        noticeTpl: 'To receive this loan, please deposit the required collateral. Simply go to your cryptocurrency wallet and send <strong>{collateralAmount} {collateralSymbol}</strong> to the following wallet address. Please complete this action within the next <strong>60 minutes</strong>.',
        summarizeContent: '          <div name=ref-container">',
        summarizeContent1: '          <div class=row">',
        termField: 'Term',
        termUnit1: '{period} months',
        collaterals: 'Collateral:'
      },
      errorMessages: {
        constantAmountRequired: 'Amount is required to make a borrow!',
        minimumBorrowAmount: 'The minimum amount that you can borrow is {amount} {symbol}',
        Required: 'Required',
        collateralAmountRequired: 'Collateral amount is required to make a borrow!',
        walletAddressRequired: 'Wallet Address is required to make a borrow!',
        inValidWalletAddress: 'Your wallet address is invalid',
        notSupportBech32: 'BTC bech32 not supported',
        rangeInterestRate: 'Interest rate must be greater than {min}% and less than {max}%',
        rangeMarketRate: 'Interest rate must be greater than or equivalent {value}%',
        stakingMinTimeRequire: 'Length of term must be equal or greater than {days} days',
        notEnoughBalance: 'Please deposit sufficient collateral to secure your loan request',
        notEnoughCollateralLink: ' here.',
      },
      headLine: 'Want to pay off your credit cards, medical bills or rent? Simply enter the amount of constant you want to borrow and make a deposit in ETH, which will be held securely in the MyConstant loan smart contact.',
      interestTable: {
        title: 'Why you should borrow with MyConstant.',
        description: 'MyConstant crypto-backed loans have a generous loan to value ratio, no matter the amount you borrow. Benefit from the kindest interest rates on the market with our short term loans and get more liquidity out of your crypto.'
      },
      reference: '        <div name=ref-container">',
      history: {
        itemTpl: '          <div name=loan-info-container">',
        address: 'Address',
        collateralAddress: 'Collateral Address',
        renew: 'Renew',
        status: {
          pending: 'pending',
          accepted: 'accepted',
          payingInterest: 'paying',
          done: 'done',
          cancelled: 'cancelled',
          withdrawed: 'withdrawn',
          expired: 'Timed out',
          fullPayment: 'full payment'
        },
        confirmPay: {
          payingInterest: {
            title: 'Interest Payment',
            content: '<p>Your Interest payment is <strong>{amountConstant}</strong> Usd/Constant</p>               <p>Your collateral payment is <strong>{amountCollateral} {symbol}</strong></p>            ',
            success: 'Pay Successfully'
          },
          fullPayment: {
            title: 'Full Payment',
            content: '<p>Your Interest payment is <strong>{amountConstant}</strong> Usd/Constant</p>               <p>Your collateral payment is <strong>{amountCollateral} {symbol}</strong></p>            ',
            success: 'Pay successfully'
          }
        },
        interestPaymentTitle: 'Interest Payment',
        interestPaymentContent: '<p>Your Interest payment is <strong>{amountPayInterest}</strong> USD/Constant</p>',
        balanceNotEnough: 'Your Balance is not enough to pay',
        paySuccess: 'Pay Successfully',
        noHistoryData: 'Explore our USD loans.',
        paymentDetail: 'Repayment details',
        itemPayment: '#{id} {description} <strong>{amount}</strong> USD/Constant on {paymentDate}',
        noPaymentDetail: 'No payment detail',
        payConstant: 'Pay by Constant',
        payCollateral: 'Pay by Collateral'
      },
      depositCollateral: {
        collateralTypeLabel: 'Collateral type',
        walletAddressLabel: 'Returning {symbol} wallet address',
        walletAlias: '0x687C906b2....8bd9369242e',
        generateWallet: 'Generate wallet address'
      },
      learnMore: 'Learn more'
    },
    convert: {
      payToForm: {
        addNewBank: 'Add a new bank account',
        note: 'Note (optional)',
        BeneficiaryAddressStreet1: 'Beneficiary Street ',
        BeneficiaryAddressCity: 'Beneficiary City',
        BeneficiaryAddressRegion: 'Beneficiary Region',
        BeneficiaryAddressPostalCode: 'Beneficiary Postal Code',
        transferMethod: 'Transfer Method',
        transferMethods: {
          plaid: { title: 'Linked bank account (ACH)', desc: 'Fee: Free. Time: 4 to 7 days' },
          ach: { title: 'ACH', desc: 'Fee: Free. Time: 4 to 7 days' },
          wire: { title: 'Wire', desc: 'Fee: might be charged by your bank. Time: a business day' },
        },
        fullName: 'Full Name',
        bankCountry: 'Bank Country',
        bankAddress: 'Bank address',
        depositoryBank: 'Depository Bank',
        bankPhone: 'Bank phone',
        bankName: 'Bank name',
        accountNumber: 'Account number',
        bankBranchName: 'Bank branch name',
        bankAccountName: 'Beneficiary name',
        bankAccountNumber: 'Account Number',
        bankAccountType: 'Bank Account Type',
        swiftCode: 'Swift code',
        routingNumber: 'Routing number',
        btnChangeText: 'Change',
        achCheckType: 'Ach Check Type',
        yourBanks: "Recipient's Bank",
        emptyBank: 'Please select a bank',
        openYourBank: 'Click here to open your banks',
        BeneficiaryAddressStreet2: 'Beneficiary Street 2',
        BeneficiaryAddressCountry: 'Beneficiary Country',
        createdBankFailed: 'Failed while creating new bank, please try again',
        reVerifyPlaidMessage: 'Our ACH processing partner, Plaid, has detected a change in your bank details. To continue using this linked bank account for ACH transfers, please re-verify your account. This only takes a moment. Just click Re-verify below and then follow the on-screen instructions.',
        reVerifyButton: 'Re-verify your account',
        transferMethodsCheckbook: {
          ach: { title: 'ACH', desc: 'Fee: Free. Time: next business day' }
        }
      },
      buy: {
        thankYou: 'Almost there.',
        buyFlexCryptoDepositThankMsg: 'Please transfer a minimum of {fiatAmount} {fiatCurrency} to the following wallet address to start earning interest.',
        depositCryptoLendThankMsg: 'Please transfer {minAmount} {fiatCurrency} to the following wallet address to start earning interest.',
        cryptoAddress: '{currency} address',
        scanDepositQR: 'Alternatively, scan this QR code directly from your mobile wallet to complete the deposit.',
        minAmount: 'Minimum investment amount',
        flexRate: 'Interest rate',
        minuteWindowCryptoInvest: 'Send your cryptocurrency now to start earning interest',
        warningLimitCryptoLend: 'You can deposit as much as you like, but you only earn interest on the first {limitAmount} {currency} or equivalent value for each supported asset.',
        warningUnLimitCryptoLend: 'You can deposit as much as you like.',
        buyDepositThankMsg: "Please wire {fiatAmount} {fiatCurrency} to the seller's bank account.",
        bankName: 'Bank name',
        accountName: 'Account name',
        accountNumber: 'Account number',
        amount: 'Amount',
        reference: 'Reference',
        important: 'Important',
        importantMsg: 'Do include your reference number {referenceNumber} in the transfer description.',
        minuteWindow: 'Please complete your transfer within 12 hours.',
        minuteWindowDepositDescription: 'For the sake of security, this session will time-out after 12 hours. If you need a little longer, just re-enter the amount you would like to deposit when you’re ready.',
        type: 'Buy',
        btnText: 'Buy',
        caption: 'Buy from local sellers',
        titleModal: 'Buy from local sellers',
        headLine: "Don't want to make an international wire transfer? Buy locally",
        labelConst: 'How much Constant do you want to buy?',
        labelExchange: 'Your price for 1 Constant',
        labelEqual: 'Equivalent in your local currency',
        newRequestBtn: 'Got Constant? Sell it at your own rates.',
        titleRequestModal: 'Sell Constant',
        buttonRequestModal: 'Sell',
        header: {
          user: 'User',
          qty: 'Qty',
          rate: 'Rate',
          price: 'Price',
          actor: 'Seller',
          bank: 'Bank'
        },
        orderWithdraw: {
          title: 'Almost there.',
          message: 'Please make a wire transfer of {amount} to our custodian Prime Trust, so you have sufficient funds in your account to complete the transfer.'
        },
        introHolderTemplate: '          <h1>Buy from local sellers in {country}</h1>          <p>To be the first to buy and sell Constant in your country, simply reach out to us to get started.</p>          <p>Email us at <a href=mailto:{email}">{email}</a></p>',
        confirmTemplate: `          <div name="thanks">            <p>Thanks! Please wire <strong>{FiatAmount} {FiatCurrency}</strong> to the seller's bank account.</p>          </div>          <div name="bank">            <p>              <span>Bank:</span>              <span>{BankName}</span>            </p>            <p>              <span>Account Name:</span>              <span>{BankAccountName}</span>            </p>            <p>              <span>Account Number: </span>              <span>{BankAccountNumber}</span>            </p>            <p>              <span>Amount: </span>              <span>{FiatAmount}&nbsp;{FiatCurrency}</span>            </p>            <p>              <span>Constant: </span>              <span>{Amount}&nbsp;{Currency}</span>            </p>            <p>              <span>Reference: </span>              <span>{Reference}</span>            </p>          </div>          <div name="notice">            <b name="important">IMPORTANT:</b>            <span>              Do include your reference number <b>{Reference}</b> in the transfer description            </span>          </div>          <div name="window">            <strong name="minutes">30 MINUTE WINDOW: </strong>            <span>              Please complete your transfer <b>within 30 minutes</b>. After that, your              order will be automatically cancelled.            </span>          </div>        `,
        scanQR: 'Alternatively, scan this QR code directly from your mobile wallet to complete the investment.',
        routingNumberWire: 'Routing Number Wire',
        swiftCode: 'Swift Code',
        accountType: 'Account Type',
        accountAddress: 'Account Address',
        yourTopupAmount: 'Top-up Amount',
        collateralBalance: 'Your Balance',
        collateralRequired: 'Collateral Required',
        value: 'Value',
        constant: 'MyConstant',
        buyThankMsg: 'Simply make a transfer of {fiatAmount} to complete your deposit.',
        buyCryptoThankMsg: 'Please transfer {fiatAmount} {fiatCurrency} to the following wallet address to complete your investment.',
        buyCryptoDepositThankMsg: 'Please transfer {fiatAmount} {fiatCurrency} to the following wallet address to complete your deposit.',
        dgxFeeMsg: '(includes the 0.13% DGX transfer fee)',
        minuteWindowDescription: 'For the sake of security, this session will time-out after 12 hours. If you need a little longer, just re-enter the amount you would like to invest when you’re ready.',
        minuteWindowDescriptionDGX: 'Invested DGX is converted to USD. When your term ends, you can choose to withdraw in DGX at the spot price, as USD, or a supported stablecoin.'
      },
      backOrderBook: 'Back',
      title: 'Convert',
      subTitle: 'Be the first in {article} {country}',
      introTitle: 'Change to cash anytime.',
      headLine: 'Get Cash for your MyConstant from the US network, or exchange locally and avoid international transfer fees.',
      subintroTitle1: 'Protect the value of your money.',
      subintroDesc1: ' When your money is Constant, it always keeps its full worth. Constant is 100% backed by USD.',
      subintroTitle2: 'Move your money across the world.',
      subintroDesc2: 'When you have MyConstant, you can send it to anyone, anywhere in the world.',
      subintroTitle3: 'Get USD for Constant, anytime.',
      subintroDesc3: 'Anyone who holds constant can get the equivalent of 1 USD for 1 Constant, that same day.',
      howItWorks: 'How it works',
      max: 'Maximum',
      sell: {
        type: 'Sell',
        btnText: 'Get Cash',
        caption: 'CHANGE CONSTANT TO CASH AT LOCAL RATES',
        titleModal: 'Sell Constant for VND',
        headLine: 'Sell Constant to local buyers and get cash. No international fees.',
        labelConst: 'How much Constant do you want to sell?',
        labelExchange: 'Your price for 1 Constant',
        labelEqual: 'Equivalent in your local currency',
        newRequestBtn: 'Buy Constant at your own rates.',
        titleRequestModal: 'Buy Constant',
        buttonRequestModal: 'Buy',
        header: {
          user: 'User',
          qty: 'Qty',
          rate: 'Rate',
          price: 'Price',
          actor: 'Buyer',
          bank: 'Bank'
        },
        introHolderTemplate: '          <h1>Convert to local currency your country</h1>          <p>To be the first to buy and sell Constant in your country, simply reach out to us to get started.</p>          <p>Email us at <a href=mailto:{email}">{email}</a></p>',
        confirmTemplate: '<div name="thanks">            <p>Thank you!</p>            <p>We’re preparing your funds to be withdrawn to the registered bank account. You should be getting another notification once your withdrawal is in motion. Once you’ve received your funds, please click confirm on the Order History page, so we can finalize the record.</p>            <p>If you do not receive a notification confirming your withdrawal after 30 minutes, please send <a href="mailto:hello@myconstant.com">hello@myconstant.com</a> an email, and we’ll get it sorted for you.</p>          </div>'
      },
      titleRequestModal: '{type} Constant',
      unitText: 'per Constant',
      payTo: 'Buyer will send VND to',
      fee: 'Fee',
      confirmMessage: 'After you have made a transfer, please click confirm.',
      confirmButton: 'Confirm',
      errorMsgs: {
        required: 'Required',
        greaterThan: 'Must be greater than 0',
        notEnough: 'Constant is not enough'
      },
      send: {
        confirmTemplate: {
          title: 'Thank you!',
          description: `Your funds are on the way to your recipient's bank account. Times may vary based on the bank and location. Please view our <a href="/getPrices" target="_blank" class="semiBold">service times</a> for an estimation of when funds will reach you.<br/>While MyConstant processes your deposits and withdrawals free of charge, transaction fees may be applied separately by your financial institution.`,
          contact: 'If you have any questions, feel free to reach out to <a class="colorLight semiBold" href="mailto:hello@myconstant.com">hello@myconstant.com</a> anytime.',
          actionTitle: 'Make another transfer'
        }
      },
      withdraw: {
        confirmTemplate: {
          title: 'Thank you!',
          description: `
            <div class="bold">Your funds are on the way to your recipient's bank account.</div>
            <div>Times may vary based on the bank and location.</div>
            <div>Please view our <a href="/getPrices" target="_blank" class="underline">service times</a> for an estimation of when funds will reach you. While MyConstant processes your deposits and withdrawals free of charge, transaction fees may be applied separately by your financial institution.</div>
          `,
          contact: 'If you have any questions, feel free to reach out to <a class="underline" href="mailto:hello@myconstant.com">hello@myconstant.com</a> anytime.',
          actionTitle: 'Back to homepage'
        }
      }
    },
    transferCrypto: {
      info: {
        content: `
          <p class="topText">Fast, easy crypto transfers.</p>
          <h1 class="last">Withdraw cryptos and stablecoins to your wallet</h1>
          <p>Send cryptocurrencies and stablecoins to any compatible wallet. Simply select the amount, crypto, and enter the wallet address. Click Send and your funds will be on their way to you.</p>
        `
      },
      address: '{currency} wallet address',
      maxBalance: 'Your balance',
      transferBtn: 'Send',
      timeLimit: 'Your money will arrive within ',
      timeLimitMinute: 'minutes',
      timeLimit2: '48 hours',
      validateMaxInput: 'Please make sure your balance is sufficient.',
      confirmMsg: `
        <div class="textLeft">You’re about to convert: {constAmount} CONST (USD) to {amount} {name}</div>
        <div class="textLeft">Fee: \${fee}</div>
        <div class="textLeft">To address: {address}</div>
        <p class="textLeft">Do you want to proceed?</p>
      `,
      confirmMsgDai: `
        <div class="textLeft">You’re about to convert: {constAmount} CONST (USD) to {amount} {name}</div>
        <div class="textLeft">Fee: \${fee}</div>
        <div class="textLeft">Exchange rate: 1 DAI = 1.035 USD</div>
        <div class="textLeft">To address: {address}</div>
        <p class="textLeft">Do you want to proceed?</p>
      `,
      title: 'Transfer',
      caption: 'SEND CONSTANT ANYWHERE',
      transferType: 'Transfer Type',
      transferTypeCaption: 'To an {currency} address',
      transferTypesCaption: {
        eth: 'To an ETH address',
        eos: 'To an EOS address',
        tomo: 'To a TOMO address'
      },
      exchangeRate: 'Exchange rate',
      yourBalance: 'Your balance',
      validateMaxInputBalanceZero: 'Please make sure your balance is sufficient.',
      validateMinInput: 'Must be at least ${min}',
      transferFeeLabel: 'Transfer fee',
      subTitle: 'Free. To anyone, anywhere.',
      introTitle: 'Withdraw to your crypto wallet.',
      phToAddress: 'Recipient’s email or Ethereum wallet address',
      batchConfirmMsg: 'Are you sure you want to transfer to multiple people from the uploaded file?',
      withdrawFailed: 'Something\'s not right. Please try again.',
      withdrawSuccess: 'Withdrawal successful.',
      transferFailed: 'Failed while transfering, please try again',
      transferSuccessful: 'Your transfer was completed successfully',
      transferConfirm: 'We\'ve already sent you a verification link by email. Please click this link to approve your transaction.',
      verifyEmailRequired: '      <p>You need to verify your email to do this action.</p>      <p>Please verify your email <a href="/me/information" class="underline bold">here</a>.</p>      ',
      timeLimitInWorkingTime: 'Your money will arrive within 1 hour',
      timeLimit24h: '24 hours',
      timeLimit3: '36 ~ 72 hours',
      subintroTitle1: 'No banks, no borders.',
      subintroDesc1: 'Constant is a digital currency, so you can send it to anyone, anywhere in the world - within minutes.',
      subintroTitle2: 'Zero fees. No transaction limits.',
      subintroDesc2: 'You can send any amount of Constant to anyone at anytime, for free.',
      subintroTitle3: 'Always reliable.',
      subintroDesc3: 'Unlike other digital currencies, Constant keeps a stable value. This means you can get the equivalent of 1 USD for 1 Constant. In any location, at any time.',
      backToNewOrder: 'Place new order',
      goHistory: 'Go to Open Orders',
      components: { orderInfo: { title: 'Transfer confirmation' } },
      howItWorks: 'How it works',
      amount: 'Amount',
      to: 'To',
      memo: 'Memo',
      input2FA: 'Input 2FA',
      promotion: 'Withdraw in {currency} and get a {percent}% bonus'
    },
    transfer: {
      info: {
        content: `
        <h1 class="last">Send money to anyone, anywhere.</h1>
        <p>All you need is an internet connection to make as many transfers as you like - any amount, anywhere in the world. Send money home, pay your international clients, transfer salaries to your distributed team. With a single click.</p>
        <p>Note: only use this feature when you want to transfer money between Constant accounts.</p>
      `
      },
      caption: 'How much do you want to send?',
      note: 'Note (optional)',
      yourBalance: 'Your balance',
      transferBtn: 'Send',
      address: { email: 'Recipient’s email' },
      timeLimit: 'Your money will arrive within ',
      timeLimitMinute: 'minutes',
      validateMaxInputBalanceZero: 'Please make sure your balance is sufficient.',
      timeLimit24h: '24 hours',
      validateMaxInput: 'Please make sure your balance is sufficient.',
      confirmMsg: 'Are you sure you want to transfer {amount} USD to {address}?',
      title: 'Transfer',
      transferType: 'Transfer Type',
      transferTypeCaption: { email: 'Via Email', batch: 'To multiple people' },
      transferFeeLabel: 'Transfer fee',
      subTitle: 'Free. To anyone, anywhere.',
      introTitle: 'Send money to anyone, anywhere.',
      phToAddress: 'Recipient’s email or Ethereum wallet address',
      batchConfirmMsg: 'Are you sure you want to transfer to multiple people from the uploaded file?',
      transferFailed: 'Failed while transfering, please try again',
      transferSuccessful: 'Your transfer was completed successfully',
      transferConfirm: "We've already sent you a verification link by email. Please click this link to approve your transaction.",
      verifyEmailRequired: '      <p>You need to verify your email to do this action.</p>      <p>Please verify your email <a href="/me/information" class="underline bold">here</a>.</p>      ',
      timeLimit2: '48 hours',
      subintroTitle1: 'No banks, no borders.',
      subintroDesc1: 'Constant is a digital currency, so you can send it to anyone, anywhere in the world - within minutes.',
      subintroTitle2: 'Zero fees. No transaction limits.',
      subintroDesc2: 'You can send any amount of Constant to anyone at anytime, for free.',
      subintroTitle3: 'Always reliable.',
      subintroDesc3: 'Unlike other digital currencies, Constant keeps a stable value. This means you can get the equivalent of 1 USD for 1 Constant. In any location, at any time.',
      backToNewOrder: 'Another transfer',
      goHistory: 'Orders and Transactions',
      components: {
        orderInfo: {
          title: 'Transfer confirmation',
          desc: ' ',
          cryptoAddress: 'To address',
          email: 'To email',
          amount: 'Amount',
          fee: 'Fee',
          promotionAmount: 'Bonus',
          serviceTime: `
            <h6 class="bold colorLight">Service time guarantee &#10004; </h6>
            <div class="colorLight">There are occasions where we have to source extra liquidity but try to process your order as soon as possible (<a href="/fee-and-service-times" target="_blank" rel="nofollow" class="underline">please refer to our service times</a>).</div>
            <p class="colorLight">If your withdraw takes longer than our stated service times to reach your account, we’ll pay you 12% APY for every second it’s late.</p>
          `,
          serviceTimeTransferViaEmail: `
            <div class="colorLight">Your transfer is being processed and should be completed within a few minutes. </div>
            <p class="colorLight">If you still can’t see your funds after 10 minutes, please contact customer support.</p>
          `,
        }
      },
      howItWorks: 'How it works',
      amount: 'Amount',
      to: 'To',
      input2FA: 'Input 2FA'
    },
    loanOriginator: {
      listApplications: {
        mapStatus: {
          overview: 'Overview',
          settings: 'Auto Invest settings',
          matched: 'Matched',
          closed: 'Done'
        },
        headers: {
          id: 'ID',
          investedDate: 'Date invested',
          totalInvestAmount: 'Total investment amount',
          investmentStatus: 'Investment status',
          totalPaymentReceived: 'Total payment received',
          interestRate: 'Interest rate',
          interestAccrued: 'Interest accrued',
          receivables: 'Pending payments',
          latePaymentAmount: 'Late payment amount',
          assignmentAgreement: 'Assignment Agreement',
          createdDate: 'Date created',
          amount: 'Invested amount',
          status: 'Status',
          packageId: 'Auto Invest Plan',
          investAmount: 'Maximum investment amount',
          action: ' ',
          investmentStatusDesc: "            <p>Status of a specific investment.</p>            <p>1. Initial: The Fund is being held at MyConstant's approved custodian's bank account waiting to be wired to the Loan Originator on the next Settlement Date.</p>            <p>2. Fund in transit: The wire transfer is being processed and fund is being sent to the Loan Originator. This process generally takes 3 to 7 business days.</p>            <p>3. Active: The Investment transaction has been settled. The investment is active and has started to earn interest.</p>            <p>4. Closed: The Investment reached the end of loan term and principal fully repaid OR The Investment has been purchased back by the Loan Originator OR  the Investment has been sold in the Secondary Market.</p>            <p>5. Bad Debt: The Investment has passed the loan term for more than 60 days, the Buy Back Guarantee failed to be executed and the principal or part of the principal has not been recovered.</p>          ",
          receivablesDesc: "Money that is currently in the process of being credited to your account. The borrower has made a payment, and we're waiting for the lending company to transfer the money to us. We'll credit the money to your account as soon as we have it.",
          totalPaymentReceivedDesc: "Total interest payment and principal payment (if applicable) that have been received, credited and settled in the Investor's account",
          interestRateDesc: 'The rate at which loans are available for investors to invest in. Interest rate is generally stated in the form of annual NET interest rate paid to the investor. This is the final interest rate earned by the investor which already reflected all potential fees, commission and reserves (if any).',
          interestAccruedDesc: 'interest earned but not yet being paid out, calculated proportionately based on the number of days from last payment date over total number of days from last payment day to next schedule payment date'
        },
        noHistoryData: 'Make your investment of any amount today.',
        investmentStatus: {
          active: 'Active',
          ready: 'Initial',
          activating: 'Fund in transit',
          voiding: 'Selling',
          voided: 'Sold',
          pending: 'Pending',
          closed: 'Closed',
        },
        loanOriginatorHeaders: {
          loanOriginator: 'Loan Originator (LO)',
          loanType: 'Loan Type',
          term: 'Term',
          remainingTerm: 'Remaining Term',
          nextPaymentDate: 'Next Payment Date',
          estimatedNextPayment: 'Estimated Next Payment',
          action: ' ',
          loanTypeDesc: 'Individual icons represents different types of loans offered by the Loan Originator on MyConstant Marketplace such as personal loans, business loans, car loans, mortgages, invoice financing.',
          loanOriginatorDesc: 'The lending company that originates and services the loans listed on the MyConstant marketplace.'
        },
        footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
        overview: {
          headers: {
            investCount: 'My investments',
            investAmount: 'Total',
            currentAmount: 'Current',
            pendingAmount: 'Pending payments',
            late115Amount: '1-15 days late',
            late1630Amount: '16-30 days late',
            late3160Amount: '31-60 days late',
            defaultAmount: 'Default',
            badDebtAmount: 'Bad debt'
          }
        },
        shortTerm: 'Best Interest',
        settingStatus: { stopped: 'Deactivated', active: 'Active' },
        reInvest: 'Auto Reinvest',
        start: 'Activate',
        diversified: 'Diversified',
        stop: 'Deactivate',
        dialog: {
          reInvestSetting: {
            messageConfirm: {
              true: '                <p>When your term ends, your interest and principal will return to your MyConstant account. You will then have to add more funds to invest in new loans.</p>                <p>Are you sure you want to disable auto-reinvest?</p>              ',
              false: '                <p>When your term ends, your interest and principal will be reinvested in new loans automatically. Your can disable this feature at any time.</p>                <p>Enable auto-reinvest?</p>              '
            },
            confirm: 'Yes',
            cancel: 'No',
            autoRenewInvestmentSuccess: {
              true: 'Disabled Auto Reinvest successfully',
              false: 'Enabled Auto Reinvest successfully'
            }
          },
          void: {
            messageConfirm: `
                <p>To end your term early, you can sell your investment and any rights to future loan repayments to another investor. Once sold, you’ll get your principal of \${amount} back.</p>
                <p>Would you like to sell your investment?</p>
            `,
            messageConfirmActive: `
                <p>To end your term early, you can sell your investment and any rights to future loan repayments to another investor. Once sold, you’ll get your principal of \${amount} back plus 2% APR on elapsed term time.</p>
                <p>Would you like to sell your investment?</p>
            `,
            confirm: 'Yes',
            cancel: 'Go back'
          },
          cancelVoid: {
            messageConfirm: `<p>Are you sure you want to cancel selling your investment?</p>`,
            confirm: 'Yes',
            cancel: 'Go back'
          },
          cancelPendingBuy: {
            messageConfirm: `<p>Are you sure you want to cancel this secondary market order? Once canceled, you might not be able to buy it again.</p>`,
            confirm: 'Yes',
            cancel: 'Go back'
          }
        },
        investedLoanStatus: {
          default: 'Default',
          current: 'Current',
          late_1_15: '1-15 Days Late',
          late_16_30: '16-30 Days Late',
          late_31_60: '31-60 Days Late',
          bad_debt: 'Bad Debt',
        },
        reInvest_desc: 'This option enables investors to automatically invest borrower repayments (such as an interest or principal repayment) in new loans. This maximizes your returns by minimizing the amount of time your money spends idle.',
        stopConfirm: {
          shortTerm: '            <p>If you deactivate your Best Interest Investment Plan, your available funds – if any – will not be used to invest in new loans.</p>             <p>Deactivate your Best Interest Investment Plan?</p>          '
        },
        noInvestedLoans: 'No invested loan found',
        investmentStatusDesc: {
          ready: "The Fund is being held at MyConstant's approved custodian's bank account waiting to be wired to the Loan Originator on the next Settlement Date which is by <strong>{time}</strong>."
        },
        paymentScheduleHeaders: {
          date: 'Date',
          principal: 'Principal',
          interest: 'Interest',
          total: 'Total',
          paymentReceived: 'Payment Received',
          paymentDate: 'Payment Date',
          status: 'Status'
        },
        paymentScheduleStatus: { paid: 'Paid', scheduled: 'Schedule' },
        secondaryMarketLoanOriginator: {
          mapStatus: {
            pending: 'Pending',
            voiding: 'Selling',
            voided: 'Sold',
            active: 'Bought',
            closed: 'Done',
          },
        },
        void: 'End your term early',
        cancelVoid: 'Cancel',
        cancelPendingBuy: 'Cancel',
        alert: {
          voidInvestmentSuccess: 'Thanks. Your investment has rejoined the matching queue and we’ll let you know when we find a buyer. In the meantime, you can cancel your sell order from your Accounts page.',
          voidInvestmentFailed: 'Sorry, we couldn’t create your sell order. Please try again later. If you keep seeing this message, please email us at <a href="mailto:hello@myconstant.com">hello@myconstant.com</a>.',
          voidInvestmentFailedMinAmount: "Sorry, we couldn't create your sell order as your investment is less than ${minAmount}.",
          cancelVoidInvestmentSuccess: 'You successfully cancelled investment.',
          cancelVoidInvestmentFailed: 'Failed to cancel investment',
          cancelPendingBuyInvestmentSuccess: 'You successfully cancelled buying investment.',
          cancelPendingBuyInvestmentFailed: 'Failed to cancel buying investment',
          sellInvestmentAlreadyPaidPrincipal: `Sorry, you can’t sell this investment as the borrower has already repaid your principal and earned profit. To learn more, please <a href="/loan-originators/manual-invest/{id}" target="_blank" class="underline">view your payment schedule</a>.`
        },
      }
    },
    direct: {
      components: {
        sellInfoInput: {
          confirmText: "You're sending {amount} {currency}",
          confirmSubText: "Please check the recipient's bank info again.",
          close: 'Close',
          agreeSell: 'Agree to send'
        },
        fee: {
          fee: 'Fee: ',
          minAmount: 'Minimum withdrawal: ',
          wantZeroFeeLabel: 'Want zero fees?',
          wantZeroFeeMsg: 'All purchases of 1000 Constant and over are completely free.',
        },
      },
      goHistory: 'Go to History',
      backToNewOrder: 'Make another transfer '
    }
  },
  support: {
    newTitle: `
    <div class="h6">Questions? Let's get on a call.</div>
    <div class="h6">Set up a time <a href="/help-and-support">here</a>.</div>`
  },
  home1: {
    lendingSection: {
      form: {
        textLogin: 'Log in',
        or: 'or',
        textRegister: 'Sign up',
        btnBorrow: 'to borrow now',
        btnDeposit: 'to deposit now',
        btnChat: 'to chat now',
        text1: 'Set your own lending terms',
        placeholder: { amount: 'How much do you want to lend?' },
        maxTerm: 'Length of term',
        text2Promotion: 'Choose a term and as soon as our custodian receives your funds you’ll start earning interest. You earn {promotionRate}% APR on the first ${promotionAmount} and 6% thereafter. If you’d like to end your term early, you can sell your investment in our <a href="https://myconstant.com/blog/constant-secondary-market" target="_blank" class="underline">secondary market</a>.',
        text2: 'Choose a term you like and as soon as Prime Trust, our custodian, receives your funds you’ll start earning interest. The longer you invest for the more interest you earn. If you’d like to end your term early, you can sell your investment in our <a href="https://myconstant.com/blog/constant-secondary-market" target="_blank" class="underline">secondary market</a>.',
        btn2: 'to invest now',
        tooltip: {
          maxTerm: '<p>Choose instant access to withdraw your funds at any time for free. Otherwise, pick a fixed term to get our best rates.</p>',
        },
        deposit: 'to deposit now',
        btn1: 'Invest now',
        gotoVerifyKyc: 'Verify now',
        btnKyc1: '<a>Verify your ID</a> to invest',
        validateReferralPromotionAmountMax: 'The maximum you can lend is ${amount}',
        comment: "to comment",
        to_start_thread: "to start a thread"
      },
      intro: {
        content: `
          <h1><span class="orange">Lend money online</span> to earn up to 4x better interest than a Certificate of deposit (CD).</h1>
          <p>Earn up to {rate}% APR by investing in P2P loans to people and businesses around the world. Withdraw anytime for free or invest in a fixed-term loan for the best rates. All lending is backed by cryptocurrency collateral. No fees.</p>
        `,
        contentPromotion: `
            <p class="topText">Invest the way you want</p>
            <h1 style="color:#F5A623;">Earn {promotionRate}% APR on your <br/>first 30-day investment.</h1>
            <p>Triple your returns on your first fixed-term investment. Lend at least \${promotionAmount} for 30 days and you’ll earn {promotionRate}% APR<sup>*</sup> (up to 9x higher than a CD). All lending is backed by collateral which is sold if the borrower defaults. No fees. When your investment matures, withdraw or reinvest for free.</p>
            <p class="reference"><sup>*</sup> To qualify, you must invest within 30 days of passing KYC. The {promotionRate}% APR introductory rate applies to the first \${promotionAmount} of your investment only (you’ll earn our usual 6% APR rate on any remaining amount).</p>
        `,
        youtube: { title: 'Why invest with MyConstant?' },
        desc: {
          '1': 'Earn 4x more than a CD',
          '2': 'Start investing from just $10',
          '3': 'Instant access or fixed terms',
        }
      },
      alert: {
        investUsingBalanceConfirm: 'Click confirm to start your term and begin earning interest. Once you confirm, you won’t be able to cancel your investment but can sell it on our secondary market.',
        investNotUsingBalanceConfirm: 'Click confirm and you’ll start earning interest as soon as we receive your funds. Once your term starts, you won’t be able to cancel your investment but can sell it on our secondary market.',
        invalid_payment_method: 'To invest USD, you must be a KYC-verified US citizen. Please choose another currency to deposit.',
        success: 'Success',
        sendEmailVerifyForTrialSuccess: 'Thanks! We sent you a verification email to unlock your free trial.',
        sendEmailVerifyForTrialFailed: 'Send email failedSorry, we could not send your verification email. Please make sure your email address is valid and try again.',
        trialFailed: 'Sorry, we can only offer one free trial per user.',
        normalSuccess: 'Submit normal success',
        normalFailed: 'Submit normal failed. Please refresh your browser and try again.',
        trialSuccess: "Thanks! We're connecting you with a borrower glad to pay your rates. We'll send you an email when you're matched.",
        zelleSuccess: 'Thanks! Please proceed with the transfer.',
        zelleFailed: 'Something went wrong. Please try again.',
        needKyc: '',
        buy2ndDesc: `Click confirm to agree to the <a href="{loanTermUrl}" target="_blank" class="underline">terms of the loan</a>, start your term, and begin earning interest. Once confirmed, you can’t cancel this investment but you can sell it early on the secondary market.`,
        warningNonUSPrimeTrust:`
          <p><strong>USD withdrawals are temporarily suspended for non-US bank accounts.</strong></p>
          <p>Before you deposit, please note <strong>you can only withdraw to US bank accounts</strong>. Due to an unusually high number of wire reversals, we’ve temporarily suspended USD withdrawals to banks outside of the US. We’re working with Prime Trust, our custodian, to reinstate fiat withdrawals to non-US banks as soon as possible.</p>
          <p>Please only display these to non-US customers or US customers with non-US banks on site. Thanks</p>
        `
      },
      confirm: {
        thankYou1: 'Almost there.',
        thankMsgDeposit: '<p>Please deposit {amount} to complete your investment.</p>',
        amount: 'Amount',
        bankName: 'Bank name',
        depositoryBankName: 'Bank name',
        routingNumberWire: 'Routing number for Wire',
        routingNumber: 'Routing number for Wire',
        swiftCode: 'Swift code',
        bankAddress: 'Bank address',
        accountName: 'Account name',
        creditTo: 'Account name',
        accountNumber: 'Account number',
        accountType: 'Account Type',
        accountAddress: 'Account address',
        beneficiaryAddress: 'Account address',
        bankPhone: 'Bank phone',
        refNumber: 'Reference number',
        reference: 'Reference number',
        notes: 'Notes',
        invalid_amount: 'Invalid amount, please check your amount and try again',
        updateBalanceNote: "Please note it might take <strong class='yellow'>up to 1 business day</strong> to update your balance after receiving your deposit. We're working hard to reduce this time, so thanks for your patience.",
        referNote: 'Your reference number is {refNumber}',
        minuteWindow: 'Please complete your transfer within 3 days.',
        minuteWindowDescription: 'For the sake of security, this session will time-out after 3 days. If you need a little longer, just re-enter the amount you would like to invest when you’re ready.',
        paymentMethods: {
          email: {
            titleDesc: '<p><span class="method-title">Fast. Free. Easy.</span><br/>Up to ${max} per transaction</p>',
            note: 'Before making a transfer, please enter the full name associated with the Zelle account here',
            paymentInfo: '              <div class="row">                <div class="col">                    <label>Amount</label>                    <p>{amount} USD</p>                </div>                <div class="col">                    <label>Send to</label>                    <p>{email}</p>                </div>              </div>              <div class="row">                <div class="col">                    <label>Full name</label>                    <p>{name}</p>                </div>                <div class="col">                    <label>Type</label>                    <p>{type}</p>                </div>              </div>            ',
            placeholder: 'Please enter the full name here',
            submitButton: 'Submit',
            notAvailableAmount: '<p>The maximum amount you can send through Zelle is {amount}. Please choose either a wire transfer or ACH transfer (available for linked US bank accounts only).</p><p><a target="_blank" href="https://myconstant.com/blog/how-to-link-your-bank-account-for-ach-transfers-on-constant/">How to link a US bank account for ACH transfers.</a></p>',
            referNoteDesc: 'Please include your reference number when you make your Zelle transfer so we can match your deposit to your account.',
          },
          primetrust: {
            titleDesc: '<p><span class="method-title">Fast. Secure. Unlimited.</span><br/>Unlimited amount</p>',
            referNoteDesc: 'To receive your deposit you MUST include this reference number when your wire your funds from your bank.',
            minuteWindow: '15 MINUTE WINDOW',
            minuteWindowDescription: 'Please complete your transfer within 15 minutes. After that, your order will be automatically cancelled.',
            needHelp: `
              <div class="need-help">
                <div>Need help?</div>
                <div><a target="_blank" class="underline" href="https://myconstant.com/blog/how-to-wire-your-money-to-constant-via-prime-trust/">Check out our guide to Prime Trust transfers.</a></div>
              </div>
            `,
            subtitleGeneral: `
              <p><span class="orange">Please include Reference Number <span class="semiBold">{refNumber}</span> in Reference/ Description box</span> when you set up wire template with your bank. If we receive a wire from your bank that does not include the Reference Number, the wire will get returned. Also, please make sure that the wire you select from your bank is a <strong>same-day wire transfer</strong>. When you have made your transfer, please email your wire receipt to <a href="mailto:finance@myconstant.com" style="color:#0053E2;">finance@myconstant.com</a>.</p>
            `,
            subtitleInvest: `
              <p>To complete your investment of \${amount} at {interest}% for {term} days, please wire your funds to our custodial partner, Prime Trust, using the details below.</p>
              <p>IMPORTANT: Please include your reference number {refNumber} so we can match your deposit to your account. To speed this up, please email your wire receipt to <a href="mailto:finance@myconstant.com">finance@myconstant.com</a>.</p>
              <div class="need-help"><strong>Need help?</strong> <a target="_blank" href="https://myconstant.com/blog/how-to-wire-your-money-to-constant-via-prime-trust/">View our guide to Prime Trust wire transfers.</a></div>
            `,
            referenceDesc: 'Please include this Reference Number in Reference/ Description box when you set up wire template with your bank. If we receive a wire from your bank that does not include the Reference Number, the wire will get returned.'
          },
          plaid: {
            title: 'Transfer via ACH to earn {rate}% instantly',
            titleDesc: '<p><span class="method-title">Instant interest. Linked US bank only.</span><br/>Less than ${max} per transaction</p>',
            subtitle: {
              enoughInstantCredit: '<p>Invest your deposit of <strong>${instantAmount}</strong> instantly while your ACH clears. Get <strong>{flexRate}% APY</strong> through Instant-access investing or invest in a fixed term loan for up to <strong>{maxRate}%</strong>. (Please note: While you can invest your deposit instantly, you can’t withdraw it until your ACH clears.)</p>',
              overInstantCredit: '<p>Invest up to ${instantAmount} of your deposit instantly while your ACH clears. Get {flexRate}% APY through Instant-access investing or invest in a fixed term loan for up to {maxRate}%. The remainder of your deposit will credit when your funds reach us. (Please note: While you can invest up to ${instantAmount} of your deposit instantly, you can’t withdraw it until your ACH clears.)</p>',
              investNote: '<p>Get an instant 4% APY on any Plaid ACH deposit up to ${instantAmount}. Your investment of ${amount} at {interest}% for {term} days will start after your ACH clears.</p>',
              pleaseNote: `
                <p><strong>Please note:</strong><p>
                <ul>
                  <li>ACH transfers are available for linked US bank accounts only and can take 4 to 7 business days to clear. However, if there’s an issue or discrepancy with information, the time might take longer as we’ll have to investigate.</li>
                  <li>MyConstant customers with no membership status can only make 1 ACH transfer at a time. Gold customers and above can make one extra transfer at a time.</li>
                  <li>Every 30 points worth of credit gets you 1 more ACH transfer (5 maximum).</li>
                  <li>You can make a maximum of 10 ACH transfers per month.</li>
                </ul>
              `,
              haveLinkedBank: '<p>Please choose a linked bank account below and then click <strong class="orange">Submit</strong>.</p>',
              attention: `
                <div class="bold">Attention!</div>
                <p >To avoid an ACH reversal, please make sure you have <strong>sufficient funds within at least the next 48 hours</strong> in your bank account. The penalties for all ACH reversals result in a temporary suspension of your account as well as fees incurred.</p>
                <ul class="bold">
                    <li>1st ACH reversal — $20 fee</li>       
                    <li>2nd ACH reversal — $30 fee</li>       
                    <li>3rd ACH reversal — $50 fee</li>       
                </ul>
              `,
              creditScore: 'Your ACH credits: ',
              creditScoreDesc: `
              <div><strong>How much can you deposit via ACH?</strong></div>
              <p>To minimize ACH reversals (which result in bank fees), you earn a higher deposit limit with each successful deposit through our credit system.</p>
              <p>You start with zero deposit credits, which gives you <strong>a deposit limit of 30% of your available bank balance.</strong></p>
              <p>When your deposit succeeds, you earn 5 credits, which increases your deposit limit. The more points you earn, the higher your deposit limit:</p>
              <table>
                <thead>
                  <tr>
                    <th><span class="underline">ACH credits</span></th>
                    <th><span class="underline">The percentage you can deposit</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>From 0 to 5</td>
                    <td style="padding-left: 5px;">30% of your available balance</td>
                  </tr>
                  <tr>
                    <td>From 5 to 10</td>
                    <td style="padding-left: 5px;">40% of your available balance</td>
                  </tr>
                  <tr>
                    <td>From 10 to 15</td>
                    <td style="padding-left: 5px;">50% of your available balance</td>
                  </tr>
                    <td>Above 15</td>
                    <td style="padding-left: 5px;">70% of your available balance</td>
                  </tr>   
                </tbody>
              </table>
              <br/>
              <div><strong>What happens if your ACH is reversed?</strong></div>
              <p>For every <a href="https://myconstant.com/blog/how-to-avoid-the-cost-hassle-and-delays-caused-by-ach-reversals/" target="_blank" class="underline">ACH reversal</a>, 10 points are deducted from your score. <strong>Your account will also be suspended</strong> until our customer support team resolves the issue. If your score drops below zero, please contact us on <a href="mailto:hello@myconstant.com" target="_blank" class="underline">hello@myconstant.com</a>.</p>
              `,
            },
            subTitleSplitDeposit: {
              splitNote: `
                <div>The maximum amount you can send via ACH in a single transaction is {amountPerTransaction}.</div>
                <p>Instead, you can split your deposit over {numTransaction} orders:</p>
              `,
              investNote: `
                    <p>Each order will be processed under the same linked bank account you choose from below.</p>
                `,
              investNoteInstantCredit: `
                <p>While your ACH clears, you’ll earn {flexRate}% APY instantly on \${instantAmount} through our Instant Credit program. You can also reinvest Instant Credit in a fixed-term loan for up to {investRate}% APR.</p>
              `,
              pleaseNote: `
                <p><strong>Please note:</strong><p>
                <ul>
                  <li>ACH transfers are available for linked US bank accounts only and can take 4 to 7 business days to clear. However, if there’s an issue or discrepancy with information, the time might take longer as we’ll have to investigate.</li>
                  <li>MyConstant customers with no membership status can only make 1 ACH transfer at a time. Gold customers and above can make one extra transfer at a time.</li>
                  <li>Every 30 points worth of credit gets you 1 more ACH transfer (5 maximum).</li>
                  <li>You can make a maximum of 10 ACH transfers per month.</li>
                </ul>
              `,
              haveLinkedBank: '<div>To confirm your deposit, please choose a linked bank account below and then click <strong class="orange">Submit</strong>.</div>',
            },
            subTitleSplitInvestment: {
              splitNote: `
                <div>The maximum amount you can send via ACH in a single transaction is {amountPerTransaction}.</div>
                <p>Instead, you can split your investment over {numTransaction} orders:</p>
              `,
              investNote: `
                    <p>The funds for each investment will be taken from the same linked bank account you choose from below.</p>
                `,
              pleaseNote: `
                <p><strong>Please note:</strong><p>
                <ul>
                  <li>ACH transfers are available for linked US bank accounts only and can take 4 to 7 business days to clear. However, if there’s an issue or discrepancy with information, the time might take longer as we’ll have to investigate.</li>
                  <li>MyConstant customers with no membership status can only make 1 ACH transfer at a time. Gold customers and above can make one extra transfer at a time.</li>
                  <li>Every 30 points worth of credit gets you 1 more ACH transfer (5 maximum).</li>
                  <li>You can make a maximum of 10 ACH transfers per month.</li>
                </ul>
              `,
              haveLinkedBank: '<div>To split your investments, please choose a linked bank account below and then click <strong class="orange">Submit</strong>.</div>',
            },
            noBankDesc: '<p>Link a US bank account to do ACH transfers through our partner, Plaid.</p><p>You don’t have any linked bank accounts at the moment. To link a US bank account to do ACH transfers, please click <strong>+ Add new bank</strong>.</p>',
            updateBalanceNoteInWorkingTime: 'Please note it might take <strong class="orange">up to five business days</strong> for the ACH transfer to complete and show in your account.',
            confirmMessage: `
              <p class="text-left">Please ensure you have sufficient funds in your bank account to avoid an ACH reversal. All ACH reversals result in temporary suspension of your account and <strong>fees up to $50 for each reversal.</strong></p>
              <p class="text-left">When you're ready, please click <strong>Confirm</strong> and start your transfer.</p>
              <p class="text-left orange">You’ll see a withdrawal on your bank from <strong>PrimeTrust</strong> - our custodian — for this deposit. Please do not dispute the transaction.</p>
            `,
            confirmMessage2: `
              <p class="text-left">Once you click <span class="orange">Confirm</span>, <br/>you will not be able to cancel the transfer.</p>
            `,
            referNoteDesc: `
              <p class="title">By clicking Submit, you confirm to</p>
            `,
            checkListNote: `
              <ul>
                <li>You have linked a US checking account thats supports outgoing ACH transfers.</li>
                <li>The amount you’re sending is less than \${max}, or if higher, you’ve agreed to split your transactions (up to a maximum of \${maxTotal})</li>
                <li>You expect to keep the same linked bank account(s) for the next 60 days.</li>
                <li>You've read <a target="_blank" href="https://myconstant.com/blog/how-to-link-your-bank-account-for-ach-transfers-on-constant/">our linked banking and ACH transfers guide.</a></li>
              </ul>
            `,
            // sendNote: `
            //     <p>Please send your funds <strong>within 3 business days</strong> otherwise this transaction will time-out for the sake of security. If you need more time, please re-enter your investment amount when you're ready.</p>
            //     <p>Please <strong>don't send</strong> an ACH via any method other than linking your US bank account or we won't be able to accept or trace it (and it will likely return to your bank).</p>
            // `,
            whySplitDeposit: 'Why split your deposit?',
            whySplitDepositDesc: `<div>This means you don’t need to create several deposit orders. However, please note your bank may charge you for each ACH transaction. Once you agree to split your deposit into separate orders, you can’t cancel it.</div>`,
            whySplitInvestment: 'Why split your investment?',
            whySplitInvestmentDesc: `<div>This means you don’t need to create several orders manually. However, please note your bank may charge you for each ACH transaction. Once you agree to split your investments, you won’t be able to cancel.</div>`,
            whyLimitAch: `
              <p><strong>Why we limit ACH transfers</strong></p>
              <p>ACH reversals can result in fees and the suspension of your account. To prevent this, we set the following upper limits on your account:</p>
              <ul>
                <li>A maximum of \${amountPer} per transaction.</li>
                <li>A maximum of {number} active (not yet cleared) ACH transfers at any one time.</li>
              </ul>
              <p>If you want to send more than these limits allow, please choose wire as your payment method.</p>
            `,
            plaidSplitResultDeposit: {
              investNoteSuccess1: `<p>Your deposit of \${amount} was processed successfully.</p>`,
              investNoteSuccess2: `<p>You’re now earning 4% APY on \${instantAmount} of Instant Credit. You can also reinvest your Instant Credit in a fixed-term loan for up to {investRate}% APR.</p>`,
              investNoteSuccess3: `<p>ACH deposits take 4 to 7 business days to clear (not including weekends) and your funds will be available for withdrawal 7 days <strong>after</strong> they clear.</p>`,
              splitNote: '<p>Sorry, we couldn’t process all the transactions for your deposit. Here’s a summary:</p>',
              investNote: `
                <p>ACH deposits take 4 to 7 business days to clear (not including weekends) and your funds will be available for withdrawal 7 days <strong>after</strong> the transaction clears.</p>
                <p>To transfer the remaining \${unsuccessfulAmount} for your deposit, <a href="https://www.myconstant.com/flex" class="underline">create another deposit order</a>.</p>
              `,
              transactionSuccess: ' (Successful)',
              transactionError: ' (Failed due to {error})',
              plaidReachLimit: 'You\'re allowed up to {number} active ACH transfers of up to ${amountPer} each. The amount entered would require {requireNumber} ACH transfers at once. Therefore, please choose another payment method or transfer your funds in batches.',
              plaidReachNumerOrderLimit: 'Sorry, you already have {number} active ACH transfers. Please wait until these have cleared before starting a new transfer or choose another payment method.',
            },
            plaidSplitResultInvestment: {
              investNoteSuccess: '<p>The transfer(s) for your investment(s) were processed successfully.</p>',
              splitNoteFail: '<p>Sorry, we couldn’t process all the transfers for your investments. Here’s a summary:</p>',
              investNote: `<p>Please note ACH transfers take 4 to 7 business days to clear and your investments will start as soon as we receive your funds.</p>`,
              investNoteUsingInstantCredit: `<p>Please note ACH transfers take 4 to 7 business days to clear and your other investments will start as soon as we receive your funds.</p>`,
              reCreateCanceledOrder: '<p>To recreate your canceled order, <a href="https://myconstant.com/invest" class="underline">please visit our invest page</a>.</p>',
              investmentUsingInstantCredit: '<p>The investment below has already begun using your Instant Credit:</p>',
              trackInvestments: '<p>To track investments, please visit your <a href="https://myconstant.com/accounts" class="underline">Accounts page</a>.</p>',
              transactionSuccess: '(Successful)',
              transactionError: ' (Canceled due to {error})',
            },
            unknownError: '<p>Sorry, something went wrong and we couldn\'t process your ACH transaction. Please try again later. If the problem continues, please contact us on hello@myconstant.com.</p>',
            insufficient_bank_account: '<p>Sorry, your deposit amount can\'t be higher than 70% of your available bank balance. Please enter a smaller amount.</p><p><strong>Important</strong>: please ensure you don\'t exceed this limit while your deposit is being processed otherwise your ACH will be reversed.</p>',
            insufficient_bank_account_2: `
              <p><strong>Insufficient funds:</strong></p>
              <p>We’re sorry, but your deposit amount can’t be higher than {limitBalancePer}% of your available bank balance. Please enter a smaller amount. Your current available balance is \${availableBalance} which means your limited balance can only be \${limitBalance}.</p>
              <p>Please ensure that you don’t exceed this limit while your deposit is being processed, otherwise you risk having an ACH reversal which will cost your money and suspend your account.</p>
            `,
            not_enough_credit_score: `
              <p><strong>Attention!</strong></p>
              <p><strong>You can not make an ACH deposit at this time</strong> because your credit score is below zero. To resolve this issue, please contact customer support at <a href="mailto:hello@myconstant.com" target="_blank" class="underline">hello@myconstant.com</a></p>  
            `
          },
          gift_card: {
            titleDesc: 'Instantly. Free. <span class="orange">3% cashback</span> to your Instant-access investing.'
          }
        },
        updateBalanceNoteInWorkingTime: 'Please note it might take <strong class="orange">up to 1 hour</strong> to update your balance after receiving your deposit. We\'re working hard to reduce this time, so thanks for your patience.',
        bankChargeFeeInfo: `
          <h3 style="color: #757575;font-size: 16px; border-bottom: 1px solid #EEEEEE">Notice about intermediary bank fees</h3>
          <p class="text-left">The global banking system is imperfect, and some banks lack the direct relationships that support international wire transfers. These banks may depend on one or more intermediary banks (banks in addition to yours and ours) to help process the wire.</p>  
          <p class="text-left">Intermediary banks may automatically deduct fees for this service before the wire reaches your bank (withdrawal) or our bank (deposit). Unfortunately, these fees are outside of our control, and we can’t reliably inform you of the intermediary banks involved nor their fees in advance.</p> 
          <p class="text-left">While we don’t charge a USD withdrawal fee, you might still receive less than the withdrawal amount (after deducting your bank’s wire receipt fee) due to intermediary bank fees. Similarly, you might receive less in your MyConstant account after a deposit due to the same intermediary bank fees.</p>
        `,
        bankChargeFeeTitle: 'Intermediary bank fees',
        makeLocalOrderFailed: "Sorry, we couldn't create your order this time. Please try again. If you continue experiencing problems, please contact us at hello@myconstant.com. Thank you.",
        rewardTicketDesc: `
          For every \${amountPerTicket} you successfully deposit into your account gets you 1 Lending Lottery ticket. That means if you deposit $300 you get 3 tickets. Each day we announce a new number (drawn at random) Monday to Sunday at 9pm PST.
        `,
        over_limit: `Sorry, we're unable to process any further deposit at the moment.`,
      }
    },
    whyInvest: {
      title: 'Why lend money online with MyConstant?',
      data: {
        0: {
          title: 'Immediate returns',
          desc: `<p>Start earning interest the moment you deposit.</p>`,
        },
        1: {
          title: 'Flexible terms',
          desc: `
            <p>Withdraw anytime or choose from three fixed terms.</p>
          `,
        },
        2: {
          title: 'Collateral-backed',
          desc: `
            <p>All lending is backed by cryptocurrencies that are easy to sell if borrowers default.</p>
            <a href="https://myconstant.com/blog/how-we-protect-your-money-and-collateral/" target="_blank">How we protect you</a>
          `,
        },
        3: {
          title: 'No fees',
          desc: '<p>No fees to invest or withdraw USD.</p>',
        },
        4: {
          title: '24/7 customer support',
          desc: '<p>Call, email, or message us on social media at any time of the day or night.</p>',
        }
      }
    },
    howItWorks: {
      title: 'How to lend money on MyConstant',
      data: {
        0: {
          title: 'Deposit your funds',
          desc: 'Send your funds via ACH (US) or wire (non-US).',
        },
        1: {
          title: 'Set your own terms',
          desc: 'Choose instant access or a fixed term of 30, 90, or 180 days.',
        },
        2: {
          title: 'Start earning interest',
          desc: 'You earn interest as soon as your funds reach us.',
        },
        3: {
          title: 'Withdraw or reinvest',
          desc: 'Withdraw anytime (instant access) or at the end of your fixed term for free.',
        }
      }
    }
  },
  ourPartners: { title: 'Our Partners', desc: 'We are working closely with' },
  loanOriginators: {
    header: {
      tabs: {
        landing: 'How it works',
        loanOriginator: 'Loan Originators',
        constantRating: 'MyConstant Rating'
      }
    },
    landing: {
      contentHtml: `
          <p class="topText">A world of lending at your fingertips</p>
          <h1 class="last">Earn up to 11% APR investing<br/> in loans from the top loan<br/> originators in the world.</h1>
          <p>Diversify your investments and multiply your returns across global lending markets. Choose an Investment Plan (Auto Invest) for easy investing or follow your own strategy (Manual Invest). All loans include the loan originator’s buy-back guarantee in case of borrower default.</p>`,
      intro: {
        desc: {
          '1': 'Vetted loan originators',
          '2': 'Greater choice of loans',
          '3': 'Zero fees'
        }
      },
      why: {
        title: 'Why invest in Loan Originator loans?',
        data: {
          0: {
            title: 'Higher returns',
            desc: 'Access 1,000s of borrowers from loan originators around the world to earn up to 11% APR.'
          },
          1: {
            title: 'Periodic repayments',
            desc: 'Reinvest or withdraw earnings as you go when you choose loans with regular scheduled repayments.'
          },
          2: {
            title: 'Diversification',
            desc: 'Balance risk and reward across multiple originators, borrowers, countries, and loan types.'
          },
          3: {
            title: 'Protection',
            desc: 'Minimize risk with MyConstant Ratings and the loan originator’s buy-back guarantee behind every loan.'
          },
        }
      },
      what: {
        title: 'What is a Loan Originator loan?',
        constantRatingDesc:'<p>MyConstant assigns ratings for loan originators that offer loans on the MyConstant marketplace. MyConstant Ratings range from "A+" to "C-" reflecting the creditworthiness/counterparty risks of the loan originators. An "A+" rating represents the highest creditworthiness while a "C-" rating is the lowest allowable creditworthiness rating for a loan to be listed on the MyConstant marketplace.</p>',
        claimRightDesc: 'A Claim or Claim Right refers to the right to receive payments from a Loan. A Claim can be against 100% of the Loan or a portion of the Loan. The sale and purchase of a Claim is executed by an Assignment Agreement, which specifies the amount of the Claim and the conditions upon which it is acquired. An Assignment Agreement stipulates all terms of the deal, including the principal and maturity date of the Claim, as well as the applicable interest rate and payment schedule.',
        assignmentAgreementDesc: '\n' +
        '        <p>A Claim refers to the right to receive payments from a Loan. A Claim can be against 100% of the Loan or a portion of the Loan. The sale and purchase of a Claim is executed by an Assignment Agreement, which specifies the amount of the Claim and the conditions upon which it is acquired. An Assignment Agreement stipulates all terms of the deal, including the principal and maturity date of the Claim, as well as the applicable interest rate and payment schedule.</p>\n' +
        '        <p>An agreement for claim assignment on the Primary Market on the MyConstant Platform will be formed between the Loan Originator as the Assignor and the MyConstant User as the Assignee. In a Secondary Market transaction on the MyConstant Platform, the Assignment Agreement is concluded among the MyConstant Users as the Assignor and the Assignee.</p>\n' +
        '        <p>On MyConstant Marketplace, the Assignment Agreement is concluded remotely, i.e. without signatures of the parties, but has full legal force.The Assignment Agreement is deemed to be concluded and enters into legal force from the moment when the Assignee (the MyConstant User) has confirmed the acquisition of the Claim right in its MyConstant User profile.</p>        \n' +
        '        ',
        buyBackGuaranteeDesc: '\n' +
        "        <p>A buy-back guarantee is a guarantee issued by the loan originator to the investor for a particular loan, where the loan orginator promises to rebuy the loan from the investor if that particular loan is delayed by more than 60 days. The buy-back guarantee is given at an individual loan level and is marked with the symbol of a shield. If a loan with a buy-back guarantee is delayed by more than 60 days, the loan originator will buy back the loan from the investor at the nominal value of outstanding principal plus accrued interest. A buy-back guarantee is only as good as the loan originator's financial capability to honor such guarantee at the time of a buy-back event being triggered. A buy-back guarantee is NOT a guarantee from the MyConstant marketplace and its associates.</p>\n" +
        '        ',
      },
      how: {
        title: 'How it works',
        data: {
          0: {
            title: 'Deposit funds',
            desc: 'Before you choose a loan or Investment Plan, deposit the funds for your investment. The minimum is $50. ',
          },
          1: {
            title: 'Choose a loan or Investment Plan',
            desc: 'Select an Auto Invest Investment Plan or filter and select loans individually with Manual Invest.',
          },
          2: {
            title: 'Wait for a match',
            desc: 'Matching is usually instant, but might take a little longer depending on the loan or Investment Plan you choose.',
          },
          3: {
            title: 'Start earning interest',
            desc: '         <p>Once your funds reach the loan originator, you’ll start earning interest. This might take up to 10 business days from the date you match.</p>         <p><a style="color:#3E8DEB" href="/getPrices" target="_blank"><b>Learn more about service times</b></a></p>           ',
          },
          4: {
            title: 'Withdraw or reinvest',
            desc: 'Withdraw or reinvest your earnings when the term ends, or sooner if your borrower repays in regular instalments.'
          },
        }
      },
      ourPress: { title: 'Our Partners' }
    },
    loanOriginator: {
      headers: {
        loanOriginator: 'Loan Originator (LO)',
        constantRate: 'MyConstant Rating',
        loanType: 'Loan Type',
        country: 'Country',
        currency: 'Currency',
        buyBackGuarantee: 'Buyback Guarantee',
        loansOriginated: 'Loans Originated',
        loanPortfolio: 'Total Loans Originated',
        loanOutstanding: 'Outstanding Loans Originated',
        averageInterest: 'Average Interest Rate (%)',
        loanOriginator_desc: 'The lending company that originates and services the loans listed on the MyConstant marketplace.',
        constantRate_desc: 'MyConstant assigns ratings for loan originators that offer loans on the MyConstant marketplace. MyConstant Ratings range from "A+" to "C-" reflecting the creditworthiness/counterparty risks of the loan originators. An "A+" rating represents the highest creditworthiness while a "C-" rating is the lowest allowable creditworthiness rating for a loan to be listed on the MyConstant marketplace.',
        loanType_desc: 'The icons below represent different types of loans offered by the loan originator on the MyConstant marketplace, such as personal loans, business loans, car loans, mortgages, and invoice financing.',
        averageInterest_desc: 'The average interest rate calculated for all loans offered by the loan originator listed on the MyConstant marketplace. The average interest rate percentage is a weighted average of all interest rates of loans based on their loan amount placed on the MyConstant marketplace.',
        loanOutstanding_desc: 'The total outstanding principal of loans issued to borrowers by the loan originator since its inception until now. Information is as of the last reporting date of the loan originator.',
        loanPortfolio_desc: 'The total amount of loans of all loan types issued to borrowers by the loan originator since its inception until now. Information is as of the last reporting date of the loan originator.',
        loansOriginated_desc: 'The total amount of loans of all loan types issued to borrowers by the loan originator on the MyConstant marketplace.',
        buyBackGuarantee_desc: "A buy-back guarantee is a guarantee issued by the loan originator to the investor for a particular loan, where the loan orginator promises to rebuy the loan from the investor if that particular loan is delayed by more than 60 days. The buy-back guarantee is given at an individual loan level and is marked with the symbol of a shield. If a loan with a buy-back guarantee is delayed by more than 60 days, the loan originator will buy back the loan from the investor at the nominal value of outstanding principal plus accrued interest. A buy-back guarantee is only as good as the loan originator's financial capability to honor such guarantee at the time of a buy-back event being triggered. A buy-back guarantee is NOT a guarantee from the MyConstant marketplace and its associates.",
        country_desc: 'The flags indicate the country where the loan originator company was incorporated.',
        founded: 'Founded',
        employees: 'Employees',
        joinedConstant: 'Joined MyConstant',
        market: 'Market',
        loanOutstandingConstant: 'Outstanding loans originated on MyConstant',
        aboutTheLO: 'About the Loan Originator',
        lastUpdated: 'Last Updated',
        market_desc: 'The flags indicate the countries where the loan originator has issued the loans listed on the MyConstant marketplace.',
        lastUpdated_desc: 'The date the loan originator publishes its financial statements on the MyConstant marketplace.',
        loanOutstandingConstant_desc: 'The total outstanding principal of loans issued to borrowers by the Loan Originator since its inception until now that are listed on the MyConstant marketplace.',
        skinInTheGame: 'Skin in the Game',
        skinInTheGame_desc: `
          <p>The phrase "Skin in the Game" refers to the equity stake (ownership interest) that an owner of an investment retains after the investment is sold to external investors. This is to ensure the interests of the originator of the asset are aligned with the interests of the external investors, as both sides have a stake (an ownership interest) in the investment.</p>
          <p>On the MyConstant marketplace, all loan originators are required to maintain a certain percentage interest in each loan which is their stake in the loan. For example, if a loan originator with 10% skin in the game issues a EUR1000 loan to a borrower, only EUR 900 of this loan will be available for external investors to invest in and the loan originator will keep a stake of EUR100, equal to 10% of the Loan.</p>
        `,
      },
      footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
      news: { title: 'Recent News' }
    },
    constantRating: {
      howCalculate: {
        title: "How we calculate a loan originator's MyConstant Rating",
        description: 'Before you invest in Loan Originator, <strong>please take a minute to review our rating system</strong>. We rate loan originators from A to D, with A being the best, and only ever accept loan originators rated C and above. As a guide, our rating system can help you <strong>understand investment risk</strong>, <strong>diversify investments</strong>, and <strong>earn consistent returns</strong> over time.'
      },
      what: {
        title: 'What is the MyConstant Rating?',
        description: 'Similar to a credit score, our MyConstant Rating is an internal metric we’ve created to help you assess a loan originator’s ability to buy back your loans. We calculate each MyConstant Rating from a thorough assessment of the loan originator’s financial history, internal business operations, and regulatory environment.'
      },
      why: {
        title: 'Why do we need a rating system?',
        description: 'Our loan originators come from all over the world, from different cultures, regulatory and lending environments. It can be difficult to assess all that information on your own. Our MyConstant Rating system is a tool to help you make better informed decisions about your investments without digging through pages of financial statements and jargon.'
      },
      howRate: {
        title: 'How we rate Loan Originators',
        conditions: {
          profile: {
            '0': 'History of operations, market share, and market position.',
            '1': 'Country of operation.',
            title: "The loan originator's profile"
          },
          operation: {
            '0': 'Know Your Customer (KYC) policy and AML/ATF policy.',
            '1': 'Credit/Underwriting Policy.',
            '2': 'Loan Monitoring Process.',
            '3': 'Debt recovery process.',
            '4': 'Risk management controls such as loan portfolio diversification policy.',
            title: "The loan originator's lending operations and risk management"
          },
          performance: {
            '0': 'Loan portfolio structure: secured vs. unsecured loans, loan types, and maturities.',
            '1': 'Lending exposure to different countries.',
            '2': 'Historic default rates for each loan type.',
            title: "The loan originator's current loan portfolio performance"
          },
          financial: {
            '0': 'Profitability.',
            '1': 'Capital strength.',
            '2': 'Liquidity.',
            title: "The Loan Originator's financial health"
          }
        },
        rangeNote: 'After this, we will publish the final rating of each loan originator ranging from A to D',
        ranges: {
          low: {
            title: 'A+ A A-<br/>Low risk',
            description: 'A financially strong company in a leading or niche market position. It has solid asset quality, a robust and well-structured credit policy, debt collection procedures, and risk management policies. The company is led by a management team with a proven track record and operates in a stable and established regulatory environment.'
          },
          moderate: {
            title: 'B+ B B-<br/>Medium risk',
            description: 'A company with a business standing described by one or more of the following indicators: stable but somewhat weaker financials, an average-to-good market position, adequate credit policy, adequate debt collection procedures, and a shorter asset quality track record. It is led by a management team with relevant experience, and adequate financial strengths and operates in a less regulated and/or uncertain environment.'
          },
          high: {
            title: 'C+ C C-<br/>High risk',
            description: 'A company with considerable weaknesses in financial performance and standing. They can be described by one or more of the following indicators: limited or weakening competitive position, below average/rapidly decreasing asset quality, a limited track record, inexperienced management, weaker financial strengths, and operating under substantial regulatory risk.'
          },
          extremelyHigh: {
            title: 'D<br/>Extremely high risk',
            description: 'A company that has substantial weakness in financial performance and standing. Asset quality is sub-standard, management shows a significant lack of experience in managing and controlling the risk of the lending business, operating under substantial regulatory risk, and/or operates in a country that has a significant risk of political instability that would impact the lending business in the near future.'
          }
        }
      },
      howOfter: {
        title: 'How often do you evaluate your loan originators?',
        description: 'Every quarter we thoroughly assess our loan originators to decide their status in the next quarter.',
        upgrade: {
          '0': 'Their financial strength improve materially. (For example, a capital injection.)',
          '1': 'The company significantly improves its financial standing and/or performance.',
          '2': 'There is a noticeable improvement in their loan book quality and loan book diversification.',
          '3': 'The company improves its market position, product offering, and/or income diversification.',
          '4': 'Regional regulations improve or overall business conditions improve.',
          title: 'We upgrade a loan originator if:'
        },
        downgrade: {
          '0': 'Their overall loan book quality deteriorates.',
          '1': 'Their financial strength deteriorates.',
          '2': 'The company defaults on a financial obligation.',
          '3': 'The company undergoes forced restructuring.',
          '4': 'Regulation changes for the worse or a major national risk impacting the future of their lending business arises.',
          title: 'We downgrade a loan originator if:'
        }
      }
    },
    securityHeader: { tabs: { security: 'Security & Risk', risk: 'Online Security' } },
    manualInvest: {
      headers: {
        id: 'Loan ID',
        loanOriginator: 'Loan Originator (LO)',
        issuedDate: 'Issued Date',
        loanType: 'Loan Type',
        market: 'Market',
        constantRate: 'MyConstant Rating',
        remainingTerm: 'Remaining Term',
        interestRate: 'Interest rate %',
        loanAmount: 'Loan Amount / Available for investment',
        loanStatus: 'Loan Status',
        currency: 'Currency',
        availableForInvestment: 'Loan Amount available for investment',
        originalTerm: 'Original Term',
        buyBackGuarantee: 'Buy back Guarantee',
        paymentType: {
          title: 'Payment Type',
          interest_only: {
            title: 'Interest only',
            desc: 'Interest only – fixed, regular payments of interest only. The principal is then repaid at the end of the term.'
          },
          desc: '          Payment type:           <p>1. Installments (Full amortization) – fixed, regular payments of the interest and principal. Full amortization reduces the outstanding loan balance to zero at the end of the term.</p>          <p>2. Interest only – fixed, regular payments of interest only. The principal is then repaid at the end of the term.</p>          ',
          installments: {
            title: 'Installments (Full amortization)',
            desc: 'Installments (Full amortization) – fixed, regular payments of the interest and principal. Full amortization reduces the outstanding loan balance to zero at the end of the term.'
          }
        },
        issueDate: 'Issue Date',
        maturityDate: 'Maturity Date',
        loanPaymentStatus: 'Loan Status',
        buyBackGuarantee_desc: "A buy-back guarantee is a guarantee issued by the loan originator to the investor for a particular loan, where the loan orginator promises to rebuy the loan from the investor if that particular loan is delayed by more than 60 days. The buy-back guarantee is given at an individual loan level and is marked with the symbol of a shield. If a loan with a buy-back guarantee is delayed by more than 60 days, the loan originator will buy back the loan from the investor at the nominal value of outstanding principal plus accrued interest. A buy-back guarantee is only as good as the loan originator's financial capability to honor such guarantee at the time of a buy-back event being triggered. A buy-back guarantee is NOT a guarantee from the MyConstant marketplace and its associates.",
        originalTerm_desc: 'This is the period  (in months and days).',
        remainingTerm_desc: 'This is the period measured in months and days left until the full repayment of the loan is set. ',
        interestRate_desc: 'The interest rate offered for investing in available loans. The interest rate is generally stated in the form of the annual NET interest rate paid to the investor. This is the final interest rate earned by the investor which reflects all potential fees, commission, and reserves (if any).',
        availableForInvestment_desc: 'This is the total amount available to investors for investment consisting of remaining principal of the loan and any accrued interests.',
        market_desc: 'The flags indicate the countries where the loan originator has issued the loans listed on the MyConstant marketplace.',
        loanPaymentStatus_desc: 'Loan status indicates whether the borrower is repaying on schedule. Loan status include Current, 1-15 days late, 16-30 days late, 31-60 days late, Default (more than 60 days late), Bad Debt (when the Loan is in default but the Loan Originator fails to honor its Buy Back Guarantee.',
        maturityDate_desc: "The date date on which a borrower's final loan payment is due and the loan is scheduled to be fully repaid.",
        issueDate_desc: 'The date when the loan was originally issued by the loan originator to the borrower.',
        investmentAmount: 'Your investment amount',
        loanType_desc: 'The icons below represent different types of loans offered by the loan originator on the MyConstant marketplace, such as personal loans, business loans, car loans, mortgages, and invoice financing.',
        issuedDate_desc: 'The date when the loan was originally issued by the loan originator to the borrower.',
        loanOriginator_desc: 'The lending company that originates and services the loans listed on the MyConstant marketplace.',
        id_desc: 'The loan ID is assigned by the loan originator.',
        constantRate_desc: 'MyConstant assigns ratings for loan originators that offer loans on the MyConstant marketplace. MyConstant Ratings range from "A+" to "C-" reflecting the creditworthiness/counterparty risks of the loan originators. An "A+" rating represents the highest creditworthiness while a "C-" rating is the lowest allowable creditworthiness rating for a loan to be listed on the MyConstant marketplace.',
        loanAmount_desc: 'This is the total loan amount and the current outstanding balance. If the borrower has already made some principal repayments, the amount will differ.',
        currency_desc: 'The denominated currency of the Claim Right',
        term: 'Term',
        term_desc: 'This is the period  (in months and days)',
        scheduleExtension: 'Schedule extension',
        scheduleExtension_desc: 'During the first 30 days after the due date of a loan payment, the borrower can opt for extending the loan payment schedule. The maximum number of extensions depends on the loan agreement. If the lending company agrees, the loan will be automatically updated, with the new schedule, and the due dates for all outstanding payments will be extended by up to 31 days. Investors will continue to earn the same interest throughout the remaining loan term.'
      },
      footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
      details: {
        backToLoan: 'Back to loan list',
        loanId: 'LOAN ID',
        loanDetails: ' Loan Details',
        loanOriginatorDetails: 'Loan Originator Details',
        loanOriginator: 'Loan Originator (LO)',
        legalEntity: 'Legal Entity',
        paymentSchedule: 'Payment Schedule',
        availableAmount: 'Available amount:',
        availableBalance: 'Available balance:',
        yourInvestment: 'Your investment: ',
        paymentSchedule_desc: 'The detailed schedule of loan payments for each Loan that is contractually set at the start of the orginal Loan Agreement or Loan Assignment Agreement. In the Assignment Agreement, this refers to the Claim Payment Schedule which is the schedule payments calculated based on the Loan Amount transferred to the Investor from the Loan Originator.',
        loanOriginator_desc: 'The lending company that originates and services the loans listed on the MyConstant marketplace.'
      },
      summary: {
        summaryTitle: 'Investment Summary',
        desc: '        <ul>        <li>Once you click Confirm your investment will be sent to our loan origination partner. Please allow <strong>3-10  business days</strong> for funds to be transferred.</li>        <li>Once funds have been transferred successfully to our loan origination partner, your loan status will change to <strong>Active</strong> and you will start earning interest.</li>        </ul>        ',
        totalTitle: 'Total amount:',
        confirm: 'Confirm',
        investSuccess: "Thank you! We've begun transferring your funds to the borrower. Once this is complete, we'll send you an email to confirm.",
        investFailed: 'Fail Invested',
        validateBalance: 'Please make sure your balance is sufficient.',
        cancel: 'Cancel',
        assignmentAgreement: 'Assignment agreement',
        yourInvestAmount: 'Your investment amount:',
        totalAmount: 'Total amount:',
        buy2ndDesc: `You’re about to invest {amount} USD for {days} days at {interest}%. Click confirm to agree to the <a href="{loanTermUrl}" target="_blank" class="underline bold">terms of the loan</a>, start your term, and begin earning interest. Once confirmed, you can’t cancel this investment but you can sell it early on the secondary market.`,
        buyLOSecondaryInvestmentSuccess: 'Done! You\'ve bought a new investment. To track it, please visit the Loan Originator Secondary Market tab on your Accounts page.',
      },
      sortBy: 'Sort by: ',
      sortHeaders: {
        mostPopuplar: 'Most popular',
        issuedDate: 'Issued Date',
        loanType: 'Loan Type',
        constantRate: 'MyConstant Rating',
        remainingTerm: 'Remaining Term',
        interestRate: 'Interest Rate',
        investmentAmount: 'Available For Investment',
        term: 'Term'
      },
      filter: {
        loanOriginator: 'Loan Originator (LO)',
        selectAll: 'Select',
        clearAll: 'Clear All',
        issuedDate: 'Issued Date',
        constantRate: 'MyConstant Rating',
        loanType: 'Loan Type',
        interestRate: 'Interest Rate',
        investmentAmount: 'Available Investment Amount (USD)',
        title: 'Your investment criteria',
        term: 'Term',
        done: 'Done',
      },
      investButton: 'Invest',
      ads: {
        desc: '        Advertisement goes here. Just another line to fill up empty space of this ad.        ',
        actionText: 'Invest'
      },
      howItWorks: {
        '0': '<p>Set your lending criteria using the options on the left.</p>',
        '1': '<p>Choose a loan from the results below.</p>',
        '2': '<p>Enter the amount you want to invest.</p>',
        '3': '<p>Click <strong>invest</strong> and then confirm your order on the next screen.</p>'
      }
    },
    autoInvest: {
      form: {
        investConfirmation: {
          diversified: `            <p>You've selected the {name} Plan and a maximum investment amount of {amount} USD. Our {name} Plan is designed for that perfect balance between risk and reward. Do you want to proceed?</p>            <p style="color: red">Please note all future deposits will be invested in your {name} Plan up to the maximum investment amount. You can deactivate this Investment Plan or change the details at any time.</p>`,
          shortTerm: `            <p>You've selected the {name} Plan and a maximum investment amount of {amount} USD. Our {name} Plan offers the highest rates and shortest terms, but is higher risk than our Diversified Plan. Do you want to proceed?</p>            <p style="color: red">Please note all future deposits will be invested in your {name} Plan up to the maximum investment amount. You can deactivate this Investment Plan or change the details at any time.</p>`
        }
      }
    },
    loanRecords: {
      heading: 'Loan Records',
      loanDisbursement: 'Loan Disbursement',
      loanPayment: 'Loan Payment',
      settlement: 'Settlement',
      noData: 'No loans data',
      viewDetails: 'View details',
      dialog: {
        createNewTitle: 'Create new loan payment',
        interestAmountLabel: 'Total loan interest payment',
        principalAmountLabel: 'Total loan principal payment',
        lateLoans: 'Late loans',
        cancel: 'Cancel',
        create: 'Create',
        addOrDropFile: '+ Add or drop file here',
        uploadSuccess: 'Upload successfully',
      },
      headers: {
        date: 'Settlement Cycle - Settlement Date',
        totalMatchedValue: 'Total loan value matched',
        numberMatched: 'Total number of loan matched',
        status: 'Status',
        action: '',
        paymentId: 'Payment ID',
        totalLoanInterest: 'Total loan interest payment',
        totalLoanPrincipal: 'Total loan principal payment',
        lateLoans: 'Late loans',
        referenceCode: 'Reference code',
        totalClaimPaymentDue: 'Total claim payment due',
        netAmount: 'Net settlement amount',
        depositDate: 'Deposit date',
        depositAmount: 'Deposit amount',
        transactionId: 'Transaction ID',
        transactionFee: 'Transaction Fee',
        note: 'Note',
      },
      status: {
        pending: 'To Be Settled',
        sent: 'Pending Settlement',
        received: 'Settled',
      },
      settlementStatus: {
        pending: 'Pending',
        to_be_settled: 'To Be Settled',
        pending_settlement: 'Pending Settlement',
        settled: 'Settled',
      }
    }
  },
  deposit: {
    what: {
      title: 'Why invest with us?',
      data: {
        0: {
          title: 'Immediate growth',
          desc: '<p>{interest}% APY on all deposits. See your earnings reflected immediately.</p>',
        },
        1: {
          title: 'Zero downtime',
          desc: '<p>Earn {interest}% APY between fixed-term investments. Compounded and paid every second.</p>',
        },
        2: {
          title: 'Unlimited earnings',
          desc: '<p>No maximum deposit, no maximum term. Invest from just $10 and withdraw anytime. </p>',
        },
        3: {
          title: 'Withdraw anytime',
          desc: '<p>To any local bank account or wallet address, as often as you like.</p>'
        },
      }
    },
    howItWorks: {
      title: 'How it works',
      data: {
        0: {
          title: 'Deposit your funds',
          desc: 'Create your deposit order and then transfer your funds in USD or a supported stablecoin. If you send a different fiat currency, we’ll convert it for you.',
        },
        1: {
          title: 'Earn {interest}% APY',
          desc: 'Your funds supply liquidity to decentralized exchanges and lending pools. In return, you earn {interest}% APY, compounded and paid into your account every second.',
        },
        2: {
          title: 'Watch your money grow',
          desc: 'Your balance compounds and increases every second. So no downtime, no wasted earnings.',
        }
      },
    },
    contentHtml: `
      <p class="topText">Do more with your money</p>
      <h1 class="last">Enjoy <span style="color: #F5A623; font-size: inherit">50x better</span> interest<br/>than a savings account<sup>*</sup>.</h1>
      <p>Earn {interest}% APY through decentralized lending and liquidity pools. You deposit, we lend on your behalf, and you keep the interest. Unlimited free withdrawals and no fees. Grow your money without locking it in.</p>
      <p class="reference"><sup>*</sup>Comparison based on data from ValuePenguin.</p>`,
    learnMore: 'Learn more here',
    intro: {
      desc: {
        '1': 'Multiple markets',
        '2': 'Compounded & paid every second',
        '3': 'Unlimited free withdrawals'
      }
    },
    compareTable: {
      title: 'MyConstant vs Savings Accounts',
      benefits: 'Benefits',
      constant: 'MyConstant',
      others: 'Savings accounts',
      interestRate: {
        title: 'Interest rate',
        constant: '4% APY',
        others: 'Up to 1.7% APY*'
      },
      compounded: {
        title: 'Compounded',
        constant: 'Every second',
        others: 'Every 1-12 months'
      },
      fees: { title: 'Fees', constant: 'FREE', others: 'Up to $15' },
      availability: { title: 'Availability', constant: 'Global', others: 'US only' },
      minimumBalance: {
        title: 'Minimum balance',
        constant: 'NO',
        others: 'Up to $2,500'
      },
      protection: {
        title: 'Protection',
        constant: 'Collateral',
        others: 'FDIC deposit insurance'
      },
      description: '* Data taken from first 100 results on <a href="https://www.bankrate.com/banking/savings/rates/" target="_blank" class="underline">https://www.bankrate.com/banking/savings/rates/</a> and correct as of April 29, 2020. '
    },
    faqs: {
      0: {
        question: 'What is Instant-access investing?',
        answer: '      <p>Instant-access investing is a deposit account that earns you 4% APY through automatic lending. When you deposit money into Instant-access investing, it goes into a liquidity pool managed by Compound Finance. Borrowers who’ve put up collateral can then secure loans from the pool in return for interest – and we pay that interest to you. Since your deposits go to a pool rather than individual borrowers, you can withdraw or deposit anytime, as often as you like, and without any fees.</p>      <p><a href="https://myconstant.com/blog/flex-apy-account-compound-finance" class="underline" target="_blank">How Instant-access investing works.</a></p>      ',
      },
      1: {
        question: 'Why 4% APY?',
        answer: 'Compound Finance uses a blockchain protocol that intelligently manages the lending pool. It also sets interest rates according to supply and demand. At the moment, the rate is fixed at 4% APY. In the future, it could be higher or lower depending on activity in Compound’s lending market.',
      },
      2: {
        question: 'What is Compound Finance?',
        answer: '      <p>Compound Finance is a technology that intelligently manages a lending pool of over $150 million in assets (as of September 2019). Instant-access investing interfaces with this technology through an API that allows you to withdraw your deposits and earned interest at any time.</p>      <p>Compound Finance lends in a similar way to banks: your deposits go in and are lent to borrowers in exchange for interest and collateral. But since Compound Finance is a technology, there is no need for branches or staff, so you enjoy a much better interest rate!</p>      <p><a href="https://compound.finance/" class="underline" target="_blank">Learn more about Compound Finance.</a></p>      ',
      },
      3: {
        question: 'Do you have a referral program for Instant-access investing?',
        answer: 'Yes, we do. For every friend you refer to Instant-access investing, you’ll get 10% of their interest earnings for the first year (up to a maximum of $1,000,000). For more details, <a href="/referral">visit your Referrals page.</a>',
      },
      4: {
        question: 'Can I withdraw anytime?',
        answer: 'Yes. Since you’re not lending your deposits to an individual, but to a lending pool, you can deposit or withdraw as much and as often as you like.',
      },
      5: {
        question: 'How do my deposits earn interest?',
        answer: 'Instant-access investing uses an API with Compound Finance to lend your deposits to a liquidity pool. Others borrow from the pool in return for putting up collateral, and you earn the interest. In other words, Instant-access investing is very similar to how investments work on MyConstant, only with Instant-access investing you can withdraw anytime.',
      },
      6: {
        question: 'Are my deposits insured?',
        answer: `      <p>Not always, no. While held with our trust partner, Prime Trust, your deposits are covered by a $130,000,000 insurance policy. However, we expect deposits will spend most of their time earning interest through Compound Finance, so will instead be protected by collateral put up by Compound's users.</p>      <p><a href="https://myconstant.com/blog/flex-or-prime-trust-constant" class="underline" target="_blank">Earn interest on your deposits or insure them?</a></p>      `,
      },
      7: {
        question: 'Can I deposit collateral or other cryptocurrency into Instant-access investing?',
        answer: 'At the moment, Instant-access investing only accepts USD and USD-backed stablecoins.',
      },
      8: {
        question: 'Can I earn interest while waiting for an investment order to match?',
        answer: "No, your funds are set aside while waiting for a match so won't earn interest on Instant-access investing. If you decide to cancel the order, your funds will return to earning interest in Instant-access investing.",
      },
      9: {
        question: 'Will the interest rate change?',
        answer: "The interest rate depends on supply and demand in Compound's lending market. Sometimes it's higher, sometimes it's lower, but for the time being we've fixed this at 4% APY.",
      },
      10: {
        question: 'Are there any fees?',
        answer: 'No – all deposits and withdrawals on Instant-access investing are free.',
      },
      11: {
        question: 'How does MyConstant make money?',
        answer: 'Instant-access investing deposits earn interest through an API with Compound Finance, another lending platform. Compound sets interest rates according to supply and demand, and MyConstant will make a slim profit on any difference between the Instant-access investing and Compound rates.'
      },
    },
  },
  depositFiat: {
    contentHtml: `
        <h1><span class="orange">Deposit money online</span> to earn interest, compounded and paid every second.</h1>
        <p>Deposit your USD online into your MyConstant account through our licensed custodian, Prime Trust. Earn an automatic {interest}% APY interest, compounded and paid every second, or store it in an insured escrow. Link up to two US bank accounts for ACH transfers through Plaid. No fees.</p>`,
    learnMore: 'Learn more here',
    howItWorks: {
      title: 'How to deposit your money on MyConstant',
      data: {
        0: {
          desc: 'Create your deposit order',
        },
        1: {
          desc: 'Send your funds via wire, or ACH through a linked US bank account.',
        },
        2: {
          desc: 'Your balance will update according to our service times.',
        },
        3: {
          desc: 'Earn {interest}% APY or escrow your balance with Prime Trust.',
        },
        4: {
          desc: 'Invest in a fixed-term loan or withdraw at anytime for free.',
        },
      },
    },
  },
  depositCrypto: {
    contentHtml: `
      <h1>Your online <span class="orange">multi-cryptocurrency wallet</span><br/> for lending and borrowing.</h1>
      <p>Deposit cryptocurrencies and stablecoins to your MyConstant wallet. Earn {flexInterestStable}% APY on your USDT and USDC deposits. Get an instant multi-collateral credit line from just 6% APR. Top-up collateral automatically when markets fall.</p>
      <p><a href="/getprices" class="underline" target="_blank">How long will it take to process your deposit?</a></p>
      <p><a href="https://myconstant.com/blog/how-we-protect-your-money-and-collateral/" class="underline" target="_blank">How we protect your assets</a></p>
    `,
    intro: {
      desc: {
        '1': 'Deposit {numCollateral} supported cryptos',
        '2': 'Earn {flexInterestStable}% APY on your USDT and USDC',
        '3': 'Borrow instantly against your portfolio',
      }
    },
    howItWorks: {
      title: 'How it works',
      data: {
        0: {
          desc: 'Create your deposit order',
        },
        1: {
          desc: 'Copy our wallet address or scan the QR code using your wallet app.',
        },
        2: {
          desc: 'Your balance will update according to our service times.',
        },
        3: {
          desc: 'Borrow against your portfolio or top-up an existing loan.',
        },
        4: {
          desc: 'Withdraw anytime to your wallet of choice.',
        },
      },
    },
  },
  cryptoLend: {
    contentHtml: `
      <h1><span class="orange">Earn interest on your cryptos</span> with our P2P crypto-lending platform</h1>
      <p>Lend your cryptocurrencies to earn up to <strong class="orange">{interestStable}% APY<sup>*</sup></strong>. Unlock the best rate when you receive interest in USDT, USDC and PRV. Otherwise, earn {interest}% APY in {cryptos} compounded and paid every second. Withdraw interest and principal anytime. All lending backed by MyConstant Guarantee<sup>1</sup>.</p>
      <p class="note"><sup>*</sup> Applies to USDT or USDC deposits of up to {maxAmountInterest}. Deposits of over {maxAmountInterest} will earn {interestSpecial}% APY (in USDT or USDC) or {prvInterest}% APR (in PRV).</p>
      <p class="note"><sup>*</sup> The more MCT you hold in your balance, the higher your interest rate will be!.</p>
      <p class="note"><sup>1</sup> The MyConstant Guarantee defines a limit of investment within which MyConstant can protect invested funds from loss or theft while in the custody of third parties.</p>
    `,
    learnMore: 'Learn more here',
    intro: {
      desc: {
        1: 'Earn up to {interestStable}% APY',
        2: 'Invest over 10 cryptocurrencies',
        3: 'No fees, penalties, or lock-ups',
      }
    },
    whyInvest: {
      title: 'Why lend crypto on MyConstant?',
      data: {
        0: {
          title: 'Immediate growth',
          desc: `<p>Watch your crypto grow, second by second, from the moment you invest.</p>`
        },
        1: {
          title: 'Earn on idle assets',
          desc: `<p>Keep earning between trades with a generous {interest}% APY on your idle cryptos.</p>`
        },
        2: {
          title: 'No investing fees',
          desc: `<p>We don't charge you a penny to invest or deposit (though you might pay network fees to send your crypto).</p>`
        },
        3: {
          title: 'Withdraw anytime',
          desc: `<p>Withdraw your cryptocurrency instantly whenever you need it. No limits.</p>`
        },
      }
    },
    howItWorks: {
      title: 'How crypto lending works',
      data: {
        0: {
          title: 'Sign up and verify your MyConstant account',
          desc: `<p>Sign up for your free MyConstant account and submit your KYC to verify it</p>`
        },
        1: {
          title: 'Deposit your crypto',
          desc: `<p>Send your crypto to our lending pool and we’ll redistribute it among our trusted exchange partners, including the Incognito pDEX.</p>`
        },
        2: {
          title: 'Earn {interest}% APY',
          desc: `<p>Your crypto provides liquidity to exchange and swapping partners and you earn a cut of their trading fees – a generous {interest}% APY backed by the MyConstant Guarantee.</p>`
        },
        3: {
          title: 'Watch your crypto grow',
          desc: `<p>Interest is compounded and paid every second, giving you immediate returns. When you’re ready, withdraw again to a wallet of your choice.</p>`
        },
      }
    },
  },
  ourStory: {
    header: "Building you a brighter financial future",
    title: 'Our story',
    desc: "We’re doers, thinkers, and builders. We get up early and go to bed late, work hard and play harder. Night and day, we strive to build the best financial platform in the world to help you achieve your financial goals, fast. ",
    culture: {
      title: "It’s a culture thing",
      label1: 'BUILT FOR YOU',
      desc1: 'We listen to and then act on your feedback to build the best financial tools in the world. Making you happy makes us happy.',
      label2: 'WE TAKE OWNERSHIP',
      desc2: 'We’re not perfect but we’re honest. You won’t hear lame excuses from us – just the determination to solve problems quickly. ',
      label3: 'ENOUGH IS NEVER ENOUGH',
      desc3: 'We strive for excellence, going above and beyond in everything we do. You set the bar and we do everything to exceed it. ',
      label4: 'BETTER EVERY TIME',
      desc4: 'Evolution beats revolution. Each new iteration of our products or services will be better than our last – call us out if otherwise.',
      label5: 'We work hard & play harder',
      desc5: 'We work hard & play harder'
    },
    members: {
      title: 'Who is behind MyConstant?',
      ourLeadership: "Our Leadership",
      desc1: 'We’re an international team of engineers, creatives, and economists. Headquartered in Riverside, California, and with satellite offices in Southeast Asia, we hire the very best people to fulfill our mission to help you do more with your money.',
      desc2: "Scroll down to read about our core team below.",
      andy_title: "Head of Customer Operations",
      andy_name: "Andy Tran",
      andy_desc: "Andy graduated with an MA in Applied Linguistics at Macquarie University, Sydney. You’ll find him in our Telegram group, answering your Facebook Messenger queries, and sharing your feedback with the team to make MyConstant better. Andy is ready to help you no matter the time of day.",
      claire_title: 'Head of Growth',
      claire_name: "Claire Dang",
      claire_desc: 'International Business graduate and former sales at Uber, Claire heads up our business development team. Claire networks with commercial partners and affiliates to help bring better investing to the wider world, and is always happy to jump on a call to discuss new partnerships or foster existing ones.',
      myphan_title: "Head of Finance",
      myphan_name: "My Phan",
      myphan_desc: 'My Phan brings vast finance and accounting expertise to MyConstant. Most recently, she was a senior accountant for a regional CPA firm where she led multiple teams to provide full accounting functions for clients in various industries. My Phan has a BA in Business Economics with an emphasis in accounting from the University of California, Los Angeles. She is a licensed CPA in the state of California.',
      zon_title: "Co-Founder & CEO",
      zon_name: "Zon Chu",
      zon_desc: `
        <p>Before MyConstant, Zon spent 15 years as full-stack developer, project manager, and technical director for e-commerce and social network companies in Vietnam and the USA. His e-commerce app for Nguyen Kim, one of the top regional retailers in the Asia-Pacific region, hit 4 million users in just two years.</p>
        <p>Zon was also technical director at Viettel Group, where he designed software to manage its 50,000 employees and provide telecommunications services to 63 million subscribers across Asia, Africa, and the Americas.</p>
      `,
      trevor_title: "Communications Manager",
      trevor_name: "Trevor Kraus",
      trevor_desc: "A New Jersey native, Trevor has a BA in marketing and was previously copywriter for an investment firm as well as a tourism company in Ho Chi Minh City. When he’s not responding to customer queries on TrustPilot, Trevor can be found making helpful tutorials on YouTube, writing blog articles and hosting the Alternative Investing podcast.",
      george_title: "Content and Community Manager",
      george_name: "George Schooling",
      george_desc: "George is from the UK and graduated with a BA in political science. After many years working for an auction house, George moved to Ho Chi Minh City in 2017, where he joined a mergers & acquisitions firm. He’s responsible for much of the content on the MyConstant website, publishing blog articles and writing newsletters.",
      nathaniel_title: "Customer Support Manager",
      nathaniel_name: "Nathaniel Whitmyer",
      nathaniel_desc: "Nathaniel Whitmyer is our customer support lead in the US, and has been at MyConstant since it launched in 2019. Nathaniel brings over 12 years of financial and customer support experience, having worked at Wells Fargo and Americor. You'll find Nathaniel ready to help you on all of our communication channels. Fun fact: Nathaniel is an aspiring author.",
      duyhuynh_title: 'Founder of MyConstant',
      duyhuynh_name: 'Duy Huynh',
      duyhuynh_desc: `
        <p>Duy is a serial entrepreneur with a strong track record of building profitable startups. He started his first business, Autonomous.ai, from his garage and it's now one of the market leaders in smart office furniture in the world</p>
        <p>Duy has been particularly invested in crypto-related projects, culminating in founding the MyConstant "Metaverse" Bank and many other growing projects.</p>
        <p>At age 19, Duy was a PhD computer science researcher funded by NASA, the Semantic Web and Fujitsu. As a competitive programmer, he was an ACM ICPC World Finalist and USA Computing Olympiad winner</p>
      `,
      hieunguyen_title: 'Back-end Engineer',
      hieunguyen_name: 'Hieu Nguyen',
      hieunguyen_desc: 'Hieu has over 13 years of experience in building the backbone of multiple technology projects within the Asia-Pacific region. His dedication to the development of Viettel Group helped it become one of the leading social media platforms in Vietnam in 2010. He is now in charge of all back-end development of MyConstant and designing the tokenomics of the product.',
      duynguyen_title: 'Back-end Architect',
      duynguyen_name: 'Duy Nguyen',
      duynguyen_desc: 'After five years in Viettel Telecom, one of the leading telecoms companies in Vietnam, Duy left to become a core team member at Vodi where he created a telecoms token. He’s now in charge of the infrastructure of MyConstant and token development.',
    },
    step1: {
      time: 'Jan 2019',
      title1: 'Hi there.',
      title2: 'Thanks for visiting our  secured P2P lending  platform.',
      desc1: "<p>If it’s your first time here, I’d love to tell you a little about who we are and what we do.</p><p>Like many stories, ours has a few twists and turns. So let’s rewind to January 2019 when it all began…</p><p>Back then, we were a humble stablecoin project. A team of economists, engineers, and creatives looking to make money more useful.</p>",
    },
    step2: {
      time: 'CES 2019',
      title1: 'Back then, we were a humble stablecoin project',
      desc1: '<p>We had developed an efficient, borderless, and stable digital currency, backed by the US Dollar, which we launched at CES 2019 in Las Vegas.</p><p>Our stablecoin (CONST) offered stability for people in troubled economies</p>',
      desc3: 'It cut the cost of international transfers.',
      desc4: 'It slashed processing times from days to minutes.',
      desc5: 'gave everyone access to a free USD account.',
      desc6: 'But we wanted to do more…',
      title2: 'We wanted to help people grow their money.',
      content2: '<p>Like the rest of the world, we’d experienced first-hand the problems with traditional banking. The miserable interest rates, the huge fees, the slow, inefficient systems…</p><p>So, we began thinking about how we could pay a great interest rate on our stablecoin.</p><p>Digital currencies are versatile. They move quickly, freely, and are protected by the blockchain technology underpinning them – surely we could put those efficiency savings to work?</p><p>After a lot of discussion, we set our sights on an industry that – until now – has had a mixed reputation: peer-to-peer (P2P) lending. </p><p>The problem with P2P lending, as we saw it, was too much centralization and too little use of collateral. P2P lenders were doing what banks did – setting rates, evaluating risk, and so on. </p><p>Ultimately, this takes control away from you, the customer. </p><p>So, we built a P2P lending platform that would do two things:</p>',
      mission1: '<p><strong>Give you control</strong> over amounts, rates, and terms.</p><p>We’re the only P2P lending platform in the world to offer this (as far as we know).</p>',
      mission2: '<p><strong>Secure all lending</strong> with collateral. This protects your funds and means we never do credit checks.</p>'
    },
    step3: {
      time1: 'May 2019',
      title1: 'Our P2P lending platform launched',
      desc: "<p>Building on our knowledge of decentralized economics, we launched the first version of our crypto-backed lending product in May 2019.</p><p>We instantly connected crypto holders with investors over a peer-to-peer network, helping them do business together without costly intermediaries and inefficiency.</p><p>Our P2P lending product was the first step towards building a financial ecosystem that helps create wealth and financial opportunities for people across the globe.</p>",
    },
    step4: {
      time: 'Jun 2019',
      title1: 'We’d matched <span class="attention">$1,000,000</span> in loans',
      content1: 'Helping people across the world get the cash they need to do the things they want.'
    },
    step5: {
      content1: '<p>And as you’ve probably guessed by reading this far, we’re not done yet...</p><p>Our goal is to give you the best financial tools in the world and the expertise to use them.</p><p>That means continuous development of our technology, making it faster, cheaper, and more secure, and giving you all the helpful content you need to take advantage of it.</p>',
      title2: 'Our job is to help you do more with your money.',
      content2: '<p>To give you great interest rates on investments and easy access to credit when you need it.</p>      '
    },
    // step6: {
    //   content1: `<p>No, we’re not perfect, but we’re honest about our shortcomings and dedicated to improving.</p><p class="clearBottom">Something broken? Get in touch</p><p class="clearBottom">Want a new feature? Let us know</p><p>Got a question? We’re always here for you.</p><p>Our <strong>customer service team works 24/7</strong>, providing round-the-clock support no matter wherer you live.</p><p>We promise you'll always have a say in the development of the MyConstant platform...</p>        <p>Because we <strong>built it for you</strong></p>`
    // },
    step6: {
      title1: "Reached 100,000 customers",
      content1: "We are immensely proud of our community and grateful for their continued support as we've grown and developed our platform.",
    },
    step7: {
      title1: "Matched $100 million in loans",
      content1: "We've helped investors lend millions of dollars to cryptocurrency holders, without a single investor loss (not so much as a cent).",
    },
    step8: {
      title1: "Paid $1,000,000 in interest",
      content1: "We've helped investors achieve their financial goals with a bank and inflation-beating interest rate, paying over $1 million in interest.",
      title2: "Reviewed over 1,000 times on Trustpilot",
      content2: "We love your feedback and are delighted when you share it with others. Whether good or bad, your reviews help us do better, which improves the experience for everyone."
    },
    step9: {
      time: 'Today',
      title1: 'Our P2P lending platform is internationally recognized as one of the most flexible, transparent, and rewarding.',
      content1: 'Our team has grown to over 50, spread across California, Vietnam, Malta, and Hong Kong.',
      content2: 'We’re the fastest-growing P2P lender in SE Asia, and have featured in HackerNoon, ZeroHedge, Inc., and many other popular tech and finance publications.'
    },
  },
  getPrices: {
    title: 'Fees and Service times',
    desc: '    <div>All times shown below are maximum estimates. In all cases, the team strives for ASAP.</div>    <div>While MyConstant processes your deposits and withdrawals free of charge, transaction fees may be applied separately by your financial institution.</div>    ',
    loanOriginator: { head1: 'Loan Originators', head2: ' ' },
    loanOrigiantorNote: `    <p class="title">* You'll start earning interest once your funds arrive in the Loan Originator's bank account, not when funds are deducted from your account.</p>    `,
    depositFiat: { head1: 'Deposit', head2: 'Funds shown in MyConstant account after deposit is received*', head3: 'Fee' },
    depositCrypto: {
      head1: 'Deposit',
      head2: 'Funds shown in MyConstant account after deposit is received',
      head3: 'Fee',
    },
    depositCollateral: {
      head1: 'Collateral',
      head2: 'Business hours (9am-6pm GMT +7, Mon-Fri)',
      head3: 'Outside business hours',
      head4: 'Fee'
    },
    collateralIssue: '    <p class="title">* About collateral issue, please reach out to Andy via <a href="https://www.myconstant.com/live-chat" class="underline" target="_blank"><strong>Live chat</strong></a> to get direct support.</p>',
    zelleDepositDelayIssue: `<p> - Missing relevant information to match with your deposit order: Name, User ID, Reference...</p>    <p> - Varying level of processing time by the bank.</p>    <p> - Transfering from an account with the account holder's name different than your registered name on MyConstant platform.</p>    <p> - Lack of or incorrect deposit order created prior to depositing.</p>    `,
    withdrawFiat: {
      head1: 'Fiat withdrawal',
      head2: 'Funds received in your bank account',
      head3: 'Fee'
    },
    withdrawFiatNote: `
        <p class="title">* A receiving fee may be charged by your financial institutions.</p>
        <p>The global banking system is imperfect, and some banks lack the direct relationships that support international wire transfers. These banks may depend on one or more intermediary banks (banks in addition to yours and ours) to help process the wire.</p>
        <p>Intermediary banks may automatically deduct fees for this service before the wire reaches your bank (withdrawal) or our bank (deposit). Unfortunately, these fees are outside of our control, and we can’t reliably inform you of the intermediary banks involved nor their fees in advance.</p>
        <p>While we don’t charge a USD withdrawal fee, you might still receive less than the withdrawal amount (after deducting your bank’s wire receipt fee) due to intermediary bank fees. Similarly, you might receive less in your MyConstant account after a deposit due to the same intermediary bank fees.</p>
    `,
    withdrawCrypto: {
      head1: 'Stablecoin withdrawal',
      head2: 'Funds received in your wallet*',
      head3: 'Fee'
    },
    withdrawCryptoNote: `
        <p class="title">* If you don’t have any outstanding borrow orders, you can withdraw up to $2,000 worth of stablecoin.</p>
        <p class="title">* If you do have borrow orders, then you can withdraw the total amount per volume.</p>
        <p class="title">* Please note, if all the withdrawals exceed the daily platform limit ($60,000), you’ll have to wait for approval.</p>
        <div class="bold guarantee">Service time guarantee &#10004; </div>
        <p>There are occasions where we have to source extra liquidity but try to process your order as soon as possible (<a href="/fee-and-service-times" target="_blank" rel="nofollow">please refer to our service times</a>). If your withdraw takes longer than our stated service times to reach your account, we’ll pay you 12% APY for every second it’s late.</p>
    `,
    withdrawCollateral: {
      head1: 'Collateral withdrawal',
      head2: 'Funds received in your wallet',
      head3: 'Fee'
    },
    withdrawCollateralData: {
      title1: '<$10,000',
      title2: '>=$10.000',
    },
    withdrawCollateralNote: `
      <p class="title">* About collateral issue, please reach out to Andy via <a href="https://www.myconstant.com/live-chat" class="underline" target="_blank"><strong>Live chat</strong></a> to get direct support.</p>
      <p class="title">* Network congestion on chains such as Ethereum or Binance may result in funds taking longer to arrive.</p>
      <p class="title">* <a href="/withdraw-fees" class="underline"><strong>Please check the collateral withdrawal fee here.</strong></a></p>
    `,
    unstaking: { head1: 'Unstaking', head2: 'Tokens received in your balance' },
    unstakingNote: '    <p class="title">* It depends on Incognito chain. Read more <a href="https://incognito.org/t/the-algorithm-of-probability-for-node-selection/836" class="underline bold" target="_blank"><strong>here</strong></a>.</p>    ',
    matchingFee: { head1: 'Matching fee', head2: ' ' },
    earlyRepayment: { head1: 'Early Repayment', head2: 'Fees' },
    lateRepayment: { head1: 'Late Repayment', head2: 'Fees' },
    holidaysNote: '    <p class="title">* Our service time may also be affected by public and banking holidays in the US and your local country.</p>    ',
  },
  pageNotFound: {
    pageNotFound: 'Page not found',
    descText: "Sorry we couldn't find what you were looking for.",
    backHome: 'Back to Home'
  },
  withdraw: {
    what: {
      title: 'Access your money whenever you need it',
      data: {
        0: {
          title: 'Works with your bank',
          desc: '<p>Easily transfer MyConstant funds to your local bank account. Just let us know where to send it.</p>',
        },
        1: {
          title: 'Easy, quick withdrawals',
          desc: '<p>Funds should reach you within 2 days, but times may vary depending on your bank’s operating hours.</p>',
        },
        2: {
          title: 'Withdraw in your local currency',
          desc: '<p>Withdraw as often as you like for free. Never pay a penny to access your money.</p>',
        },
        3: {
          title: 'Unlimited free withdrawals',
          desc: '<p>Withdraw as often as you like for free. Please note that MyConstant cannot be liable for charges from your own bank.</p>'
        },
        4: {
          title: 'Spend instantly',
          desc: '<p>Withdraw your funds as a gift card for up to 3% cashback (into your MyConstant balance) and instant digital delivery.</p>',
        },
      },
    },
    info: {
      content: `
        <p class="topText">How would you like your money?</p>
        <h1 class="last">Withdraw to your bank account</h1>
        <p>Withdraw funds to your bank account via Wire, or ACH. All withdrawals – are free and unlimited.</p>
      `,
      contentUS: `
        <p class="topText">How would you like your money?</p>
        <h1 class="last">Withdraw to your bank account</h1>
        <p>Withdraw funds to your bank account via ACH. All withdrawals — are free and unlimited.</p>
      `
    }
  },
  exchangeInputSellForm: {
    getAmountFailed: 'Failed while getting amount',
    howMuchDoYouWantToWithdraw: 'How much do you want to send?',
    paymentMethod: 'Payment method',
    amountResult: {
      explainNoFee: '* The maximum amount you can withdraw is {amountExcludeFee} {currency}.'
    },
    sellBtn: 'Send',
    recipientBankInformation: 'Recipient’s bank information',
    availableBalance: 'Available balance',
    email: { name: 'Recipient', placeholder: 'Enter receiving email address' },
    countryNotSupport: 'Your country is not supported at the moment. Our support team will get in touch with you via email.',
    validateMaxInput: 'Please make sure your balance is sufficient.',
    validateMaxInputBalanceZero: 'Please make sure your balance is sufficient.',
    suggestOtherPayment: 'Minimum withdrawal by bank transfer is ${minDirect}.',
    noAvailableMethod: 'There is no available method for you, please contact our support.',
  },
  termSelector: {
    getTermFailed: 'Get terms failed.'
  },
  app: {
    common: {
      required: 'Required',
      unit: 'USD',
      close: 'Close',
      warning6h: 'Please complete your transfer within 6 hours.',
      timeoutDepositWarning: 'For the sake of security, this order will time-out after 6 hours. If you need a little longer, just re-enter the amount you’d like to invest when you’re ready.',
      copiedAddress: 'Address is copied to clipboard',
      minValues: 'Must be greater than {min}',
      maxValues: 'Must be less than {max}',
      uploading: 'Uploading...',
      fileupload: 'Upload',
      retry: 'Something wrong happened. Please retry again.',
      remove: 'Remove',
      error: 'OH! something went wrong!',
    },
    local: { type: { buy: 'Deposit', sell: 'Withdraw' } },
    goToHomePage: 'Go to Home page',
    goToLoginPage: 'Go to Sign In page'
  },
  affiliates: {
    applicationForm: {
      title: `
      <p class="header">MyConstant Affiliate Application Form</p>
      `,
      desc: `
      <p>Thanks for your interest in our affiliate program. To progress your application, please fill out the details below:</p>
        `,
      fullName: 'Your full name (as it appears on your ID)',
      emailAddress: 'Email address',
      phoneNumber: 'Phone number',
      permanentAddress: 'Permanent address (in full)',
      taxId: 'Tax ID',
      website: 'Website',
      socialMediaLinks: 'Social media links',
      howToAdvertise: 'How will you advertise us? (CPC, blog, SEO, and so on)',
      others: 'Anything else that might help us approve your application',
      applyNow: 'Apply now',
      requestSuccess: 'Successfully submitted to become an affiliate.',
      requestFailed: 'Failed to submit',
      emailExisted: 'Affiliate with this email already exists.',
      notValidEmail: 'Invalid email address',
    },
    register: {
      aboveTheFold: {
        title: '<p class="header">Hey, thanks for visiting. Here’s a <span class="orange">${kycTrialAmount} free trial</span> to get you started</p>',
        desc: '<p>Try crypto-backed lending for free. Your first investment is on us when you sign up and pass KYC. Earn {flexRate}% APY for two weeks on a free ${kycTrialAmount} bonus. After two weeks, the bonus expires but you keep the interest.</p>',
      },
      howItWorks: {
        title: 'You won’t find a better place to grow your money',
        desc: 'You deserve a great rate on your money but the banks won’t give you one. If you want to beat inflation and achieve real growth, put your money to work with crypto-backed lending instead.',
        data: {
          0: {
            title: 'Earn up to 50x more',
            desc: 'The top US banks pay just fractions of a percent. With us, you could earn up to 50x more interest than a savings account and up to double that of a CD.'
          },
          1: {
            title: 'Real-time growth',
            desc: 'Your interest is compounded and paid every second, so you can literally watch your money grow. See how quickly your returns stack up in just a few weeks.',
          },
          2: {
            title: 'Withdraw anytime',
            desc: 'Once the free trial ends and you invest your own money, you can withdraw again anytime. No limits, fees, or restrictions. You won’t find a more flexible investment account!',
          },
          3: {
            title: 'Backed by crypto',
            desc: 'When you invest, you fund borrowers who’ve put up cryptocurrency collateral. If they don’t repay or their collateral falls too much in value, we sell it to repay you.',
          },
        }
      }
    },
    howAffiliateProgramWorks: {
      title: 'Easy to Start & Earn',
      desc: 'Like most finance affiliate programs, you share a unique referral link which deposits a cookie on your referee’s computer.',
      data: {
        0: {
          title: 'Share your unique affiliate link',
          desc: 'When customers click your affiliate link, we deposit a cookie in their browser that lasts 30 days. If they lend crypto, deposit USD, or borrow with that cookie on their computer, you get paid.'
        },
        1: {
          title: 'Your referee signs up and deposits',
          desc: 'To qualify for the bonus payment and interest, your referee must sign up, verify their ID, lend their crypto, deposit at least $10, or take out a crypto-backed loan.'
        },
        2: {
          title: 'Receive your rewards',
          desc: `
            <p>You get $20 paid instantly and automatically into your account. You also earn 20% of your referees’ instant-access and crypto lending interest.</p>
          `
        },
      },
      dataNonUS: {
        0: {
          title: 'Share your unique affiliate link',
          desc: 'When customers click your affiliate link, we deposit a cookie in their browser that lasts 30 days. If they lend crypto, deposit USD, or borrow with that cookie on their computer, you get paid.'
        },
        1: {
          title: 'Your referee signs up and deposits',
          desc: 'To qualify for the bonus payment and interest, your referee must sign up, verify their ID, and then lend their crypto, deposit at least $10, or make a crypto-backed loan.'
        },
        2: {
          title: 'Receive your rewards',
          desc: `
            <p>When the conditions are met, your USDT and USD rewards are automatically paid into your account. You also earn 20% of your referees’ instant-access and crypto lending interest with us.</p>
          `
        },
      },
    },
    milestone: {
      title: 'MILESTONES',
      intro: 'Smash these depositor goals to earn <span class="orange">our best rewards.</span> Milestone rewards are <span class="orange">unlimited.</span> We reset the timeline on every win.',
    }
  },
  developers: {
    menu: {
      constantAPI: 'MyConstant API',
      accountResource: 'Account resource',
      loanResource: 'Loan resource',
      investmentResource: 'Investment resource'
    },
    content: {
      title: 'Let’s do great things together',
      desc: 'Build. Customize. Automate. Do it all with our REST API.',
      getStart: 'Get started now',
      block1: {
        title: 'Why use our API?',
        desc: 'Whether you’re an institutional partner or want to whitelabel our tech, our API opens new ways to interact with and exploit the MyConstant platform.          <li>Launch your own whitelabel DeFi product.</li>          <li>Add lending to your product suite.</li>          <li>Verify and authenticate customers.</li>          <li>Automate lending strategies.</li>          <li>Support a vast number of digital assets.</li>          <li>Add extra utility to your platform or project.</li>        Our API lets you program new software, services, and products without spending a fortune on engineering your own tech. Instead, seamlessly interface with our platform to offer everything we do and more.        '
      },
      block2: {
        title: 'What can you do with it?',
        desc: '          <h5 class="margin-0">Here’s a brief overview of our API services.</h5>          <strong>Invest</strong>, <strong>Borrow</strong>, and <strong>Deposit</strong>             <li>Browse our open order book. Give your customers access to the best deals in our P2P lending market.</li>             <li>Submit customized lending orders. Put customers in the driving seat with flexible rates and terms.</li>             <li>Earn interest on fiat and stablecoin deposits. Reward customers with a bank-beating interest rate on deposits, compounded and paid every second.</li>          <h5>View balances and withdraw</h5>             <li>Browse deposit history. Help your customers keep track of their spend.</li>             <li>View coin and fiat balances. Give customers insight into their digital assets and liquidity.</li>             <li>Withdraw cryptocurrencies and fiat. Support withdrawal of multiple assets, including USD, crypto, and USD-backed stablecoins.</li>          <h5>Onboard new customers</h5>             <li>Perform KYC checks. Verify the identity of customers to comply with AML (Anti Money Laundering) regulations.</li>             <li>Verify accounts. Reduce spam and improve the quality of your customer database.</li>             <li>Authenticate anyone. Help protect customer security and preserve the integrity of your platform through 2FA protocols.</li>        '
      }
    }
  },
  yourSaving: {
    hello: "<span class='yellow'>Hello {name}!</span><br/>Let's see how your<br/> money is doing today."
  },
  common: {
    table: {
      id: '#',
      file_name: 'File',
      created_at: 'Date created',
      status: 'Status'
    }
  },
  notification: {
    title: 'Notifications',
    markAllRead: 'Mark all as read',
    nextLevel: { '0': ' ', '1': 'Gold', '2': 'Platinum', '3': 'Diamond' },
    system: {
      inform: { title: 'Information', desc: '{content}' },
      common: {
        viewingTitle: '{viewingTitle}',
        viewingContent: '{viewingContent}'
      }
    },
    viewAll: 'View All',
    clearAll: 'Clear All',
    clearAllSuccess: 'All notifications have been cleared.',
    noNewData: 'No new notifications',
    noData: 'No notifications'
  },
  yourReferral: {
    inviteEmail: {
      emailPlaceholder: 'Enter email addresses (seperate by comma)',
      affiliateUser: `
        <p>You’re already registered under our affiliate program so can’t refer here. Please visit your <a href="/affiliates" class="underline bold">affiliate dashboard</a> to get your unique affiliate link, sharing tools, and to track your earnings.</p>
      `,
      inviteBtn: 'Invite',
      btn: 'Invite now',
      sendInvitationsSuccess: 'Send Invitations Successfully!',
      sendInvitationsFail: 'Send Invitations Failed!',
      maximum_invitation_email: 'Maximun invitation emails',
    },
    shareReferral: {
      codeTitle: 'Share your invitation code:',
      copied: 'Referral link copied to clipboard.',
      success: 'Your referral code has been updated successfully',
      failed: 'Update referral code failed',
      duplicated: 'Sorry, someone has already created that referral code. Please choose another.',
      fb: 'Share on Facebook',
      tw: 'Share on Twitter',
      shareFbSuccess: 'Share referral link success.',
    },
    referralUsers: {
      title: 'Invited friends',
      affiliateTitle: 'Pending referral',
      headers: {
        email: 'Email',
        status: 'Status',
        statusDesc: 'The status (written in gold) shows your referee’s latest action. The bars below show the remaining actions to earn your reward. A blue bar is a complete action while a grey bar is an incomplete action. Once your referee completes all actions, you receive your reward and the status will turn grey.',
        date: 'Date',
        action: ' ',
        userId: 'User ID'
      },
      status: {
        pending: 'Sent',
        registered: 'Signed up',
        expired: 'Expired',
        kyc: 'Passed KYC',
        verified_email: 'Verified email',
        deposit: 'Made a deposit',
        borrow: 'Made borrow(s)',
        cryptolend: 'Lent crypto',
      },
      stepRemainGetBonus: '{step} step{plural} away to get ${referralReward} bonus',
      stepRemainGetBonusGeneral: '{step} step{plural} away to get bonus',
      headHunterBadge: {
        title: 'Headhunter badges',
        content: `
          <div>You referred</div>
          <div><span>{numReferees}</span> /{maxReferees}</div>
          <div>friends</div>
        `,
        contentMobile: '<div>You referred <span>{numReferees}/{maxReferees}</span> friends</div>',
        referring_friend_10_referees: '<strong style="color:#F7B500;">{numReferees} referees left</strong> to earn ${rewardAmount} & a bronze Headhunter badge',
        referring_friend_50_referees: '<strong style="color:#F7B500;">{numReferees} referees left</strong> to earn ${rewardAmount} & a silver Headhunter badge',
        referring_friend_100_referees: '<strong style="color:#F7B500;">{numReferees} referees left</strong> to earn ${rewardAmount} & a gold Headhunter badge',
      },
      luminaryBadge: {
        title: 'Luminary badges',
        content: `
          <div>Your referees invested</div>
          <div><span>{investedAmount}</span></div>
          <div>/ {maxInvestAmount} USD</div>
        `,
        contentMobile: '<div>Your referees invested <span>{investedAmount} / {maxInvestAmount} USD</span></div>',
        referring_invest_100k: '<strong style="color:#F7B500;">{investAmount} USD left</strong> to earn ${rewardAmount} & a bronze Luminary badge',
        referring_invest_200k: '<strong style="color:#F7B500;">{investAmount} USD left</strong> to earn ${rewardAmount} & a silver Luminary badge',
        referring_invest_500k: '<strong style="color:#F7B500;">{investAmount} USD left</strong> to earn ${rewardAmount} & a gold Luminary badge',
      },
      affiliateNoRecord: '<p>Start referring now and in addition to your usual rewards you’ll also qualify for our Headhunter badges (Bronze is worth $20!)</p>',
      affiliateNoRecordMobile: '<p>Refer now to earn Headhunter badge and $20 reward.</p>',
      remind: 'Remind',
      remindAll: 'Remind all',
      remindAllDesc: 'When you click Remind, a reminder email will be sent to your friend. Then, the status will change to Reminder sent. The Remind all button lets you remind all your friends in a single click.',
      remindAllMobile: 'Send a reminder email',
      sent: 'Sent',
      remindEmailSuccess: 'Hooray! You just sent a reminder email successfully to:',
      noEmailRemind: 'You’ve reminded all your friends today. There’s no one left to send. Check back again tomorrow.',
    },
    referralHistory: {
      title: 'Fiat referral history',
      affiliateTitle: 'Fiat affiliate history',
      headers: {
        email: 'Email',
        friendEarnings: 'Friend\'s earnings',
        type: 'Type',
        yourEarnings: 'Your earnings',
        yourEarningsDesc: 'As soon as you’ve earned at least 1 cent, you can track your earnings below.',
        date: 'Date',
        instantBonus: 'Instant Bonus',
        interestBonus: 'Interest Bonus',
        status: 'Status',
        releaseTime: 'Release Time',
        action: ' ',
        yourEarningsMCTDesc: 'Once your referral is successful, you can track your earned MCT below.',
      },
      affiliateNoRecord: '<p>Start referring now and in addition to your usual rewards you’ll also qualify for our Luminary badges (Bronze is worth $10!)</p>',
      affiliateNoRecordMobile: '<p>Refer now to earn Luminary badge and $10 reward.</p>',
      type: {
        flex: 'Instant Access Interest',
        invest: 'Invest',
        borrow: 'Borrow',
        user_invest: 'Invest',
        user_borrow: 'Borrow',
        user: 'User',
        mct_borrow: 'Borrow',
        cryptolend: 'Lends crypto',
        interest_bonus: 'Interest bonus',
      },
    },
    banner: {
      title: `<h1>Invite friends and you both earn <span class="orange">10 USDT</span></h1>`,
      // titleAuth: `<h1>Get <span class="orange">\${referralReward}</span> incredible cash rewards for every friend you refer.</h1>`,
      desc: `
        <p>Get <span class="orange">10 USDT</span> for you and a friend when your friend does one of the following: </p>
        <ol>
            <li>Lends crypto valued at $100 or more and holds it for 30 days.</li>
            <li>Borrows at least $500* in one or more crypto-backed loans.</li>
        </ol>
        <p>Or earn <span class="orange">\${referralReward}</span> for yourself when your friend deposits at least $10. You also earn <span class="orange">{referralFlexReward}%</span> of your friend's instant-access investment earnings in their first year.</p>
        <p class="font-italic"><strong>Don’t wait. Start earning now!</strong></p>
      `,
      // descAuth: `
      //   <p>Earn an instant \${referralReward} bonus when a friend signs up and deposits at least $10 or borrows a minimum of $500*. You’ll also earn {referralFlexReward}% of their instant-access investment earnings for their first year.</p>
      //   <p>Refer as many people as you like, as often as you like, and grow your money together.</p>
      // `,
      titleNonUS: `<h1>Invite friends and you both earn <span class="orange">10 USDT</span></h1>`,
      // titleNonUSAuth: `
      //   <h1>Refer friends and family to supercharge your earnings!</h1>
      // `,
      descNonUS: `
        <p>Get <span class="orange">10 USDT</span> for you and a friend when your friend does one of the following: </p>
        <ol>
            <li>Lends crypto valued at $100 or more and holds it for 30 days.</li>
            <li>Borrows at least $500* in one or more crypto-backed loans.</li>
        </ol>
        <p>You also earn <span class="orange">{referralFlexReward}%</span> of your friend's instant-access investment earnings in their first year.</p>
        <p class="font-italic"><strong>Don’t wait. Start earning now!</strong></p>
      `,
      // descNonUSAuth: `
      //   <p>When you do, <span class="orange">you receive \${referralReward}</span> for each person who signs up and deposits USD or makes a crypto-backed loan order of at least $500.</p>
      //   <p>You also get <span class="orange">{referralFlexReward}% of their instant-access investment interest</span>, compounded and paid every second. Refer as many friends as you like, however you like, and grow your money together.</p>
      // `,
      greeting: `<h1>Hello {name},</h1>`,
      invite: 'Invite friends',
    },
    whatForYou: {
      title: 'Share the love and we’ll share the cash',
      formula: {
        '0': 'For every friend who signs up and deposits USD',
        '1': ' ',
        '2': 'of your friends’ Instant-access investment interest',
        '3': ' ',
        '4': 'for your friend if they’re a US resident'
      },
      // desc: '        <div class="section-desc-title">INVITE FRIENDS, EARN TOGETHER</div>        <div><strong>Earn ${referralReward} for every friend who signs up and deposits USD</strong>, paid instantly, and with no limits.</div>        <div><strong>Then earn {referralFlexReward}% of their Instant-access investment interest</strong>, capped at a generous $1,000,000.</div>        <div><strong>Your friend gets a 30-day ${kYCTrialAmount} trial bonus</strong> and keeps the interest (US referees only).</div>      ',
      // descNonUS: '        <div class="section-desc-title">INVITE FRIENDS, EARN TOGETHER</div>        <div><strong>Earn {referralFlexReward}% of their Instant-access investment interest</strong>, capped at a generous $1,000,000.</div>        <div><strong>Your friend gets a 30-day ${kYCTrialAmount} trial bonus</strong> and keeps the interest (US referees only).</div>      ',
      // data: {
      //   '0': {
      //     title: 'EARN TOGETHER',
      //     desc: 'With you and your friends’ earnings linked, you can grow your money faster, while your friends can refer people to multiple their earnings, too.'
      //   },
      //   '1': {
      //     title: 'HELP PEOPLE INVEST BETTER',
      //     desc: 'Help your friends and family overcome the challenges of today’s investment market. MyConstant is an ideal way to diversify and enjoy secured returns without fees.'
      //   },
      //   '2': {
      //     title: 'IMPROVE THE PLATFORM',
      //     desc: 'Every new customer we welcome helps fuel new features and products, giving you a range of investment options, rates, and tools to reach all your financial goals. '
      //   }
      // },
      trialBonus: 'a ${kycTrialAmount} trial bonus',
    },
    youBothWin: {
      title: 'Invite friends and you both win',
      data: {
        0: {
          title: 'GET THE BEST SERVICE',
          desc: 'Loved by 1,000s of customers, with a <strong>4.7/5 rating on Trustpilot</strong> across 1,100 reviews.',
        },
        1: {
          title: 'EARN OUR BEST RATES',
          desc: 'Beat the banks and inflation with interest rates of up to {investRate}% APR. Earn <strong>4x more than a CD</strong>. Plus, your friend gets <strong>0% APR on their first 30-day loan</strong>.',
        },
        2: {
          title: 'MULTIPLY YOUR EARNINGS',
          desc: 'Refer as many people as you like and get paid <strong>{referralFlexReward}%</strong> on all their instant-access interest. Earn <strong>${referralReward}</strong>* when your friend deposits or borrows for the first time and earn <strong>10 USDT</strong>* when your friend lends crypto or borrows USD.',
        },
        3: {
          title: 'PROFIT IN ANY MARKET',
          desc: 'Earn consistent returns, backed by collateral that protects your funds.',
        },
        4: {
          title: 'GET HELP WHEN YOU NEED IT',
          desc: 'With offices around the world, we’re here to help day or night, wherever you may be.',
        },
      },
      dataNonUS: {
        0: {
          title: 'GET THE BEST SERVICE',
          desc: 'Loved by 1,000s of customers, with a <strong>4.7/5 rating on Trustpilot</strong> across 1,100 reviews.',
        },
        1: {
          title: 'EARN OUR BEST RATES',
          desc: 'Beat the banks and inflation with interest rates of up to {investRate}% APR. Earn <strong>4x more than a CD.</strong> Plus, your friend gets <strong>0% APR on their first 30-day loan.</strong>',
        },
        2: {
          title: 'MULTIPLY YOUR EARNINGS',
          desc: 'Earn <strong>{referralReward} USDT</strong> for you and your friends when they lend crypto valued at $100 or more and hold it for 30 days, or when they borrow more than $500* in one or more crypto-backed loans. You also earn <strong>{referralFlexReward}%</strong> of their instant-access investment interest.',
        },
        3: {
          title: 'PROFIT IN ANY MARKET',
          desc: 'Earn consistent returns despite the economic climate, backed by collateral that protects your funds.',
        },
        4: {
          title: 'GET HELP WHEN YOU NEED IT',
          desc: 'With offices around the world, we’re here to help day or night, wherever you may be.',
        },
      }
    },
    // whatForThem: {
    //   title: 'What’s in it for them?',
    //   content: {
    //     left: '          <p class="content-title">${kYCTrialAmount} trial bonus</p>          <p class="content-desc">When your referree signs up and passes KYC, we’ll deposit a ${kYCTrialAmount} bonus in their account. This lasts for 30 days*, at the end of which, we reclaim the bonus but your referee keeps all the interest.</p>          <p class="content-desc font-italic">*30-day trial bonus (US residents only).</p>        ',
    //     right: '          <div>            <div class="content-title">4% APY</div>            <p class="content-desc">on all <strong>Instant-access</strong> deposits</p>          </div>          <div>            <div class="content-title">{rate}% APR</div>            <p class="content-desc">on <strong>crypto-backed</strong> investments</p>          </div>          <div>            <div class="content-title">11% APR</div>            <p class="content-desc">on <strong>Loan Originator</strong> investments</p>          </div>        '
    //   }
    // },
    howItWorks: {
      title: 'How it works',
      // data: {
      //   '0': {
      //     title: ' ',
      //     desc: 'Share your unique referral link by email or social media.'
      //   },
      //   '1': {
      //     title: ' ',
      //     desc: 'Your referee signs up using your link, verifies their ID (KYC) and deposits USD, borrows or invests in Crypto Lend.'
      //   },
      //   '2': {
      //     title: ' ',
      //     desc: 'You get ${referralReward} paid instantly to your MyConstant account when your referee deposits more than $10 or borrows a minimum of $500 after signing up.'
      //   },
      //   '3': {
      //     title: ' ',
      //     desc: 'You both get 10 USDT when your friend lends USDT/USDC valued at $100 or more and holds it for 30 days OR borrows more than $500* in one or more crypto-backed loans.',
      //   },
      //   '4': {
      //     title: ' ',
      //     desc: 'Your friend earns interest on a {kycTrialPeriod}-day <strong>${kycTrialAmount} trial</strong> bonus. They can also place a one-time only 30-day loan at <strong>0% APR</strong> after signing up.',
      //   },
      //   '5': {
      //     title: ' ',
      //     desc: `You get {referralFlexReward}% of their Instant-access investment earnings, compounded and paid every second.`
      //   }
      // },
      // dataNonUS: {
      //   '0': {
      //     title: ' ',
      //     desc: 'Share your unique referral link by email or social media.'
      //   },
      //   '1': {
      //     title: ' ',
      //     desc: 'Your referee signs up using your link, verifies their ID (KYC) and deposits USD, borrows, or invests in Crypto Lend.'
      //   },
      //   '2': {
      //     title: ' ',
      //     desc: ' '
      //   },
      //   '3': {
      //     title: ' ',
      //     desc: ' ',
      //   },
      //   '4': {
      //     title: ' ',
      //     desc: `You both earn <strong>{referralReward} USDT</strong> when your friend lends USDT/USDC valued at $100 or more and holds it for 30 days or borrows more than *$500 in one or more crypto-backed loans. You also earn <strong>{referralFlexReward}%</strong> on your friends’ instant-access interest in their first year.`,
      //   }
      // },
      desc: `
        <p>Invite your friends to enjoy generous cash bonuses and benefits all year round.</p>
        <p class="font-italic">*See terms and conditions for more details.</p>
        `,
      descNonUS: '<p>Let\'s speak up with your friends so that you can all experience the amazing bonus and benefits from MyConstant together.</p>'
    },
    terms: {
      normal:
        `
        <h2 class="semiBold">Terms and conditions for earning prizes</h2>
        <ol>
            <li><p>Referral rewards for USD investment products apply to US citizens only. This restriction doesn't apply to borrowing products.</p></li>
            <li><p>Your referees must be US citizens to qualify for their \${kycTrialAmount} trial bonus.</p></li>
            <li><p>Your referral rewards are paid in USD/USDT, directly into your MyConstant account.</p></li>
            <li><p>The <strong>\${referralReward}</strong> bonus is paid when a friend deposits any amount of USD or borrows more than $500 for the first time. The $500 can be made in a single loan or multiple smaller loans.</p></li>
            <li>You earn <strong>{referralReward} USDT</strong> when your friend lends USDT/USDC valued at more than $100 and holds it for 30 days or takes out one or more crypto-backed loans of more than $500 for the first time.</p></li>
            <li>The <strong>{referralReward} USDT</strong> rewards will be paid to the coin balance of both referrers and referees after 30 days referees hold their stable coins on coin balance.</p></li>
            <li>The <strong>{referralReward} USDT</strong> rewards will be paid once only for each referee.</p></li>
            <li><p>Your USD earnings are paid directly into your MyConstant account.</p></li>
            <li><p>To enjoy the <strong>{referralReward} USDT</strong> promotion, your referees must lend either USDT and USDC.</p></li>     
            <li><p>Once your referee passes KYC, you’ll be awarded <strong>1,000 points</strong> which you can exchange for spins on the Prize Wheel.</p></li>            
            <li><p>You earn <strong>{referralFlexReward}%</strong> of your friends’ instant-access interest during their first year, capped at $1,000,000. We pay this interest bonus every second that your referees earn and do not deduct from your friends’ earned instant-access investment interest.</p></li>
            <li><p>The <strong>0% APR</strong> applies to your friend’s first 30-day loan and is valid from the date the borrow order is placed on MyConstant.</p></li>
            <li><p>You can not self-invite by creating multiple accounts. If we detect such activity, all referrals and earnings (if any) will be forfeited.</p></li>
            <li><p>MyConstant reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.</p></li>
        </ol>
        <p class="bold effective">Last updated on May 5th, 2022</p>
       `,
      normalNonUS:
        `
        <h2 class="semiBold">Terms and conditions for earning prizes</h2>
        <ol>
            <li><p>Referral rewards for USD investment products apply to US citizens only. This restriction doesn't apply to borrowing products.</p></li>
            <li><p>Your referees must be US citizens to qualify for their \${kycTrialAmount} trial bonus.</p></li>
            <li><p>Your referral rewards are paid in USD/USDT, directly into your MyConstant account.</p></li>
            <li><p>The <strong>\${referralReward}</strong> bonus is paid when a friend deposits any amount of USD or borrows more than $500 for the first time. The $500 can be made in a single loan or multiple smaller loans.</p></li>
            <li>You earn <strong>{referralReward} USDT</strong> when your friend lends USDT/USDC valued at more than $100 and holds it for 30 days or takes out one or more crypto-backed loans of more than $500 for the first time.</p></li>
            <li>The <strong>{referralReward} USDT</strong> rewards will be paid to the coin balance of both referrers and referees after 30 days referees hold their stable coins on coin balance.</p></li>
            <li>The <strong>{referralReward} USDT</strong> rewards will be paid once only for each referee.</p></li>
            <li><p>Your USD earnings are paid directly into your MyConstant account.</p></li>
            <li><p>To enjoy the <strong>{referralReward} USDT</strong> promotion, your referees must lend either USDT and USDC.</p></li>     
            <li><p>Once your referee passes KYC, you’ll be awarded <strong>1,000 points</strong> which you can exchange for spins on the Prize Wheel.</p></li>            
            <li><p>You earn <strong>{referralFlexReward}%</strong> of your friends’ instant-access interest during their first year, capped at $1,000,000. We pay this interest bonus every second that your referees earn and do not deduct from your friends’ earned instant-access investment interest.</p></li>
            <li><p>The <strong>0% APR</strong> applies to your friend’s first 30-day loan and is valid from the date the borrow order is placed on MyConstant.</p></li>
            <li><p>You can not self-invite by creating multiple accounts. If we detect such activity, all referrals and earnings (if any) will be forfeited.</p></li>
            <li><p>MyConstant reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.</p></li>
        </ol>
        <p class="bold effective">Last updated on May 5th, 2022</p>
       `
    },
    materials: {
      title: 'We make referring easy',
      desc: 'Check out <strong>our library of free resources</strong> to refer more friends and earn bigger rewards. Our blogs and downloadable materials make referring friends a piece of cake.',
    },
  },
  static_page: {
    AMLPolicy: 'Anti-Money Laundering Agreement',
    privacyPolicy: 'Privacy Policy'
  },
  components: {
    confirmBtn: { defaultConfirmText: 'Yes', defaultDeclineText: 'No' },
    dialog: {
      require2Fa: {
        message: '          <p><span class="bold">Action required:</span> For your security, we now require all accounts to enable 2-Factor Authorization (2FA). Please enable this now from your settings menu.</p>          <p>If you have any problems, please email <a href="mailto://hello@myconstant.com">hello@myconstant.com</a>.</p>        ',
        enable: 'Enable 2FA now',
        cancel: 'Close'
      },
      checkTrialKyc: {
        messageConfirm: 'For your security, please verify your identity before {action} from your Account.',
        confirm: 'Verify my ID',
        cancel: 'Maybe later',
        action: {
          withdraw: 'making a withdrawal',
          transfer: 'making a transfer',
        }
      },
    }
  },
  collateralBalance: {
    balance: 'Coin Balance',
    tabs: { collaterals: 'Collateral' },
    estimatedCollateralValue: 'Estimated Collateral Value',
    estimatedAvailableValue: 'Estimated Available Value',
    hideSmallBalance: 'Hide small balances',
    coin: 'Coin',
    name: 'Name',
    collateralBalance: 'Collateral balance',
    availableBalance: 'Available balance',
    airdropBalance: 'Airdrop balance',
    deposit: 'Deposit',
    topup: 'Top up',
    withdraw: 'Withdraw',
    hideDesc: 'Only show balances greater than zero. ',
    topupCollateralTitle: 'Top up',
    topupNote: 'Your crypto will be sent to your multi-collateral balance. To top up an isolated (single-collateral) loan, go to your active loans page and click the top-up button next to the loan you wish to top-up.',
    depositCollateralTitle: 'Deposit',
    topupSuccess: 'Top up Collateral successfully',
    depositSuccess: 'Deposit Collateral successfully',
    flexBalance: 'Instant-access investing Balance',
    cryptoWallet: 'Crypto Wallet',
    recommendMessage: 'To restore your collateral value to the recommended {recommendedRate}%, please top up {amount} {currency}',
    errorMaxValue: 'Amount must less than {max}',
    errorMinValue: 'Amount must greater than {min}',
    generateAddress: 'Generate address',
    noRecord: 'No record found',
    topupNoBalanceMessage: 'Please deposit to top-up your multi-collateral balance.',
    depositCapTitle: 'Our remaining collateral cap for {symbol} is {amount} {symbol}.',
    depositCapDesc: 'Please note if you deposit over this amount, you\'ll only be able to borrow against a maximum of {amount} {symbol}. This cap is subject to change.',
    depositCapReachedTitle: 'We\'ve reached the collateral cap for {symbol}.',
    depositCapReachedDesc: 'You can still deposit, but you won\'t be able to borrow against {symbol} for the time being. This cap is subject to change.',
    explainWhyCap: `<p>We cap our exposure to different cryptocurrency collateral to prevent triggering a mass liquidation event should that cryptocurrency's price fall. It helps protect investors while minimizing the potential for mass liquidations of borrower collateral.</p>`,
  },
  proLending: {
    balances: {
      withdrawFrom: {
        title: 'Withdraw your crypto collateral',
        amount: 'Amount',
        address: 'Your {name} wallet address',
        ok: 'Ok',
        cancel: 'Cancel',
        amountDesc: 'How much do you want to withdraw?',
        addressDesc: 'Receiving crypto wallet address.',
        withdrawFailedSameAddress: 'Unable to withdraw to the same wallet address.',
        withdraw: 'Withdraw',
        bep2Memo: 'Memo',
        bep2MemoDesc: "If you are withdrawing to an exchange, please include a MEMO so your funds don't get lost.",
        bep2MemoRequired: 'Please include a memo.',
        withdrawFailed: "Something's not right. Please try again.",
        withdrawSuccess: 'Withdrawal successful.',
        transferConfirm: "We've already sent you a verification link by email. Please click this link to approve your transaction."
      },
      title: 'Balances',
      withdraw: 'Withdraw cash',
      collateralBalances: 'Withdraw crypto',
      titleDesc: 'All investment and collateral deposits will show here.'
    },
    matchStats: {
      lastInterest: 'Latest match',
      change: '24H Change',
      high: '24H High',
      low: '24H Low',
      volume: '24H Volume',
      totalInterest: 'Total interest earned',
    },
    orderBook: 'Order Book',
    tradeHistory: 'Trade History',
    orderHistory: 'Order History',
    watchBoard: {
      reserves: 'Reserve orders',
      reserveOrderNote: 'Earn {rate}%APR.Fully secured.No fees.',
      secondaryInvestments: 'Secondary Investments',
      amount: 'Amount (USD)',
      interest: 'Interest (APR)',
      term: 'Term',
      matched: 'Time',
      secondaryInvestmentsDesc: 'These are matched orders investors want to sell. You can’t change the term or rate on a secondary investment, but you earn all the interest due on the loan regardless of how much time is left in the term.',
      loanOriginatorInvestments: 'Loan Originator Investments',
      loanOriginatorInvestmentsDesc: 'Investments backed by the loan originator’s buy-back guarantee. Unlike crypto-backed investments, there might not always be collateral securing the loan. Instead, the loan originator guarantees to buy back the loan should the borrower default for 60 days or more, returning your principal and earned profit.',
      loSecondaryInvestments: 'LO Secondary Investments',
      loSecondaryInvestmentsDesc: 'These are Loan Originator (LO) investments that investors want to sell. You can’t change the term or rate on an LO secondary market investment. However, you will earn interest on the full term regardless of how much time is left. You earn a cut of interest on elapsed term time (full rate minus 2%) and the full interest amount on remaining term time.',
    },
    orderPool: {
      title: 'Order Pool',
      diamondRound: 'Diamond round',
      openRound: 'Open round',
      desc: 'All secondary market orders go into an order pool. We then randomly fulfil orders across 10-minute rounds. Diamond members get priority on the first round, during which you won’t be able to join the order pool unless you’re a Diamond member. Once the Diamond round ends, Open rounds begin. Open rounds are open to anyone, regardless of membership.',
      startingSoon: 'Starting soon',
      customer: 'Customer',
      membership: 'Membership',
      ordered: 'Ordered',
      round: 'Round',
      done: 'Done',
      count: 'Count',
      started:'Started',
      remaining: 'Remaining',
      poolSize: 'Pool size',
      roundEnded: 'Round ended',
      roundCanceled: 'Round cancelled',
      current: 'Current',
      nextRound: 'Next round',
      roundCancelMessage: 'Thanks for your interest in this secondary market order. Unfortunately, the seller has canceled the sale so all rounds have ended. If the seller changes their mind, you’ll be able to bid for this order again when it appears in the list.',
    },
    depositFundsTitle: 'Deposit Funds',
    depositCollateralTitle: 'Deposit',
    investBooking: {
      deposit: {
        depositNote: 'Invest USD or USD stablecoins.',
        depositButton: 'Deposit funds'
      },
      form: {
        title: 'Invest',
        amount: 'Amount (USD)',
        term: 'Term (Days)',
        investButton: 'Invest now',
        invalidAmount: "Sorry, you don't have enough funds in your account. Please deposit {amount} USD to complete your order.",
        termDesc: 'If there is a borrower looking for a shorter loan at your rates, you will be partially matched. We will continue to find you a match for the rest of the term.',
        interest: 'Interest (APR)',
        termMonth: '{month}-Month',
        cancel: 'Cancel',
        availableBalance: 'Available balance',
        availableInvest: 'Available for investment',
        loginButton: 'Log in or Sign up to invest',
        getFeeFailed: 'Sorry, something went wrong. Please try again.',
        fee: 'Invest fee: <span class=amount">{fee}</span> {currency}"',
        amountDesc: 'Enter the amount you want to lend. If there is an open order of the same amount, you will match fully. If there is an open order larger than that amount, you will also match fully. If your amount is larger, you will be partially matched. The remainder will go towards a new open order.',
        interestDesc: 'This the minimum annual interest rate you are willing to accept. If there is a borrower willing to pay more, you will be matched at the higher rate.',
        goToAccountApp: 'Please go to the Account tab to sign up or log in.'
      },
      amountRequired: 'Please enter an amount.',
      messLoginFirst: {
        invest: 'Please log in to make an investment.',
        borrow: 'Please log in to request a loan.'
      },
      messDepositFirst: 'Please deposit sufficient funds to make this investment.',
      minAmountRequired: 'The minimum amount to invest is {value}.',
      interestRequired: 'Interest is required to make an investment!',
      matchedSuccess: 'Thanks! Your investment order was successful. Your term has begun and you\'ve started earning interest. To review your investments, please visit your Accounts page.',
      matchedFailed: 'Your investment had been matched unsuccessfully. Please refresh your browser and try again.',
      matchedSecondaryInvestmentSuccess: 'Done! You\'ve bought a new investment. To track it, please visit the Secondary Market tab on your Accounts page.',
    },
    deposits: {
      depositLabel: 'Select loan collateral',
      walletAddressLabel: 'Send collateral to',
      scanQR: 'Or scan this QR code directly from your mobile wallet to complete the transfer'
    },
    activityChart: 'Activity Chart MyConstant',
    borrowBooking: {
      title: 'Borrow',
      exchange: {
        amountLabel: 'Amount',
        availableCredit: 'Available Credit',
        minTerm: 'Term (Days)',
        buttonText: 'Borrow',
        receiveType: 'Receive type',
        receiveAmount: 'Receive amount',
        minTermTooltip: 'This is how long you want to borrow for.',
        collateralNoteMatchedFee: 'Fee: 1%',
        amountRequired: 'Amount is required to make a borrow!',
        amountMin: 'The minimum amount that you can borrow is {amount}',
        amountLabelDesc: 'How much do you want to borrow?',
        exchangeInfo: 'Estimate amount',
        exchangeInfoDesc: 'Estimate amount USD (const) you need to borrow',
        collateralLabel: 'Collateral required',
        collateralDesc: 'To secure your loan, please deposit 150% of the loan value in collateral.\n',
        collateralRequiredDesc: 'To secure your loan, please deposit {value}% of the loan value in collateral.\n',
        maxRate: 'Interest (APR)',
        maxRateTooltip: 'This is the annual percentage rate (APR) you are willing to pay for your loan. If there is an investor willing to earn less, you will pay the lower interest rate.',
        loginButton: 'Log in or Sign up to borrow',
        collateralRequired: 'Collateral amount is required to make a borrow!',
        rateRequired: 'Interest rate is required to make a borrow!',
        rateMax: 'Interest rate must be less than {rate}%',
        rateMax1: 'Interest rate must from 1% to {rate}%',
        collateralNoteFee: 'Fee: {fee} {currency} (1%)',
        yourBalance: 'Your current balance is {value} {symbol}',
        collateralNoteTotal: 'Total loan received: {total} {currency}',
        usingStaking: 'Get {discount}% off your rate by staking collateral\n',
        usingStakingMobile: 'Get {discount}% off with staking',
        usingStakingDesc: 'Stake your collateral in our masternode pool to save {discount}% on your loan. Please note staked collateral takes 2 business days to withdraw and you can’t recall excess.',
        stakingMinAmount: 'The staking amount must be greater than {minVal} {currency}',
        goToAccountApp: 'Please go to the Account tab to sign up or log in.',
        interest: 'Interest (APR)',
        term: 'Term (Days)',
        cancel: 'Cancel',
        notEnoughBalance: 'Please deposit sufficient collateral to credit your requested amount',
        receiveAmountLabel: 'Choose USD or crypto you want to receive?',
        receiveAmountLabelDesc: 'We can pay your loan in USD or cryptocurrency. When choosing to receive your loan in cryptocurrency, you’re borrowing USD which we use to buy your chosen cryptocurrency. We search multiple exchanges for the best prices, and you might receive a little more or less cryptocurrency than the amount indicated below depending on the final price paid.',
      },
      createdSuccess: 'Thanks! Your borrow order was successful. Your term has started and your funds are in your account and ready to use. To review your loans, please visit your Accounts page.',
      errorMsgs: {
        notEnoughBalance: 'Your Available Credit is {amount} USD. Please deposit sufficient collateral to credit your requested amount',
        generateCollateralAddressFail: 'Something went wrong. Please try again.',
        maxOfCollateralHolding: "Please try a different coin. To manage risk, the platform can hold only 10% of any coin's 24h trading volume.",
        error: 'OH! something went wrong! Please refresh your browser and try again.'
      }
    },
    openOrders: {
      title: 'Open orders',
      amount: 'Amount (USD)',
      interest: 'Rate(APR)',
      term: 'Days',
      date: 'Created',
      matched: 'Matched',
      titleDesc: 'These are orders waiting for a match.',
      messageSuccess: 'Your order book have been canceled successfully',
    },
    matchedOrders: {
      title: 'Order History',
      titleDesc: 'View your matched orders here.',
      messageSuccess: 'Your order has been cancelled successfully.'
    }
  },
  error: {
    serverCode: {
      otpNotMatched: 'Sorry, your Google authentication code didn\'t match. Please check and try again. If the problem continues, check the date set on your phone. Google authentication won\'t work if the date set on your phone is incorrect.',
      smsNotMatched: 'SMS code not matched, please try again',
      invalidStatus: 'This order has been processed. Please refresh the page to see update!',
      requestFailed: 'Something went wrong. Please refresh your browser and try again.',
      need_kyc: 'For your security, please verify your identity (KYC). Until you\'re verified, you won\'t be able to use any of our products or services.',
      old_user_need_kyc: 'For your security, please verify your identity (KYC) before {date}. After this date, you will be unable to use the platform unless your identity has been identified.',
      invalidBankAccountName: 'For your security, the beneficiary name must match the name you registered when signing up with MyConstant. Please enter a different name or email hello@myconstant.com if you need help',
      registerWithExistEmail: 'This email already exists in our system. Please try another.',
      generalCode500: 'Something went wrong, please try again! (500)',
      loginFailure: 'Please make sure your email and password are correct.',
      createdBankExisted: 'Nice! This bank account has already been registered.',
      systemError: 'System error',
      transferUserNotFound: 'Please enter a valid email.',
      loginFailureCaptchaV2: 'Something went wrong please try again',
      stakingRequireMinTerm: 'Since you staked your collateral to reduce the interest on your loan, you can’t un-stake until your loan matures.',
      generalCode400: 'Something went wrong, please try again! (400)',
      generalError: 'Oops! Something went wrong, please try again',
      collateralSuspendRecall: 'Sorry, you can\'t recall excess on {symbol} collateral due to pricing volatility. We regularly review market activity and {symbol} prices are highly volatile at the moment. We\'ve therefore limited recall excess on {symbol} to protect you from an increased risk of liquidation. For more information, please contact hello@myconstant.com.',
      not_enough_balance: 'Your balance is insufficient. Please deposit sufficient funds.',
      violateBonusPolicy: 'Your bonus can\'t be withdrawn for 30 days. This gives you time to try our platform and make some money through our Instant-access investing account.',
      reserveNotEnough: "Sorry, we can't create your order at the moment. Please contact us at hello@myconstant.com and we'll help fix this issue for you.",
      already_bought_void_term_deposit: 'You’ve already joined the matching queue for this order. Our system will randomly select a buyer to fulfil this order in priority of membership level. If you’re selected, your investment will start automatically and we’ll notify you by email.',
      firstBuy2ndOrderMessage: 'You’ve joined the matching queue for this order. Our system will randomly select a buyer to fulfil this order in priority of membership level. If you’re selected, your investment will start automatically and we’ll notify you by email.',
      undefined: 'Oops! Something went wrong, please try again',
      notEnoughConstant: 'Please ensure you have sufficient funds in your account to complete this transfer.',
      unable_to_cancel: 'You can’t cancel this order. It might be cancelled by the seller or picked by another investor already. Please refresh the browser.',
      userTaxIDNumberExisted: 'This SSN is already used to verify another account.',
      userTaxIDNumberInvalid: 'TaxIDNumber is invalid',
      userPhoneNumberInvalid: 'PhoneNumber is invalid',
      inValidWalletAddress: 'Receive address invalid',
      missingUserDocuments: 'Please upload required documents.',
      bankInfoError: 'We couldn’t save your details. Please ensure there are no spaces in the account or routing numbers and then try again.',
      cannot_split_void: 'Since this is a promotion, if you want to sell your investment on our secondary market, you must sell the entire investment amount. You can not sell a portion of the investment.',
      plaid_unable_to_access_linked_bank: 'Unable to access your linked bank account. Please log in to your bank account again.',
      cryptoDepositSunspend: 'This crypto currency deposit is suspended',
      notVerifiedEmailDiscord: "To log in with Discord, you must first verify the email you used when signing up to Discord.",
      registerInvalidPassword: "Please enter a valid password.",
    },
    expiredSession: 'Your session has expired. Please log in again.',
    withdrawLessThanOrEqual: 'The maximum withdrawal amount is {max}. Please try other available methods.',
    withdrawGreaterThan: 'The minimum withdrawal amount is {min}',
    suspendWithdrawalTo: '<p>For your security, withdrawals and transfers have been temporarily suspended on your account until {date}. This follows a recent change to your security settings. To reinstate withdrawals and transfers early, please <a href="/me/securities" target="_blank" class="underline bold">enable email confirmations</a>.</p>',
    greaterThan: 'Please enter a number greater than {min}',
    lessThan: 'Please enter a number smaller than {max}',
    greaterThanEqual: 'Please enter a number greater than {min}',
    lessThanEqual: 'Please enter a number smaller than {max}',
    mustBeANumber: 'Please enter a valid number',
    mustBeAPositiveInteger: 'Please enter a valid number',
    mustBeAIntergerNumber: 'Please enter a valid number',
    reachDayLimit: `Sorry, you've reached your daily Zelle withdrawal limit of {max}. Please try again tomorrow or choose another withdrawal method.`,
    reachTransactionLimit: 'Sorry, the maximum you can withdraw via Zelle is {max}. Please reduce your withdrawal amount or choose another withdrawal method.',
    zelleOverLimitLessThanMin: 'The maximum you can withdraw via Zelle is {max}. As this is less than our minimum withdrawal amount of {min}, please choose another payment method.',
  },
  menuMobile: {
    btnSignIn: 'Log In',
    btnSignUp: 'Sign Up',
    btnReferral: 'Share & Earn',
    btnReferralDesc: 'Refer friends and family to earn cash and crypto rewards.',
    affiliate: 'Affiliate',
    affiliateDesc: 'Write, talk or stream about us and get paid when people sign up',
    btnTransfer: 'Transfer',
    btnCollateralBalance: '<div class="textLeft"><small>Coin</small><div>Balance</div></div>',
    btnWithdrawFiat: '<div class="textLeft"><small>Withdraw</small><div>USD</div></div>',
    btnWithdrawCrypto: '<div class="textLeft"><small>Withdraw</small><div>Crypto</div></div>',
    btnDepositFiat: '<div class="textLeft"><small>Deposit</small><div>USD</div></div>',
    btnDepositCrypto: '<div class="textLeft"><small>Deposit</small><div>Crypto</div></div>',
    btnGiftCard: '<div class="textLeft"><div>Gift Cards</div></div>',
    btnDailyLogin: '<div class="textLeft"><small>Claim your</small><div>Daily Login</div></div>',
    btnWithdrawGiftcards: '<div class="textLeft"><small>Withdraw as</small><div>Gift card</div></div>',
    btnLendingLottery: '<div class="textCenter">Lending Lottery</div>',
    btnStakingMCT: '<div class="textLeft"><small>Staking</small><div>MCT</div></div>',
    btnBuyMCT: '<div class="textLeft"><small>Buy</small><div>MCT</div></div>',
    lendingLottery: 'Lending Lottery',
    community: 'Community',
    liveChat: "Live chat",
    nftCollection: "NFT Collection",
    greeting: {
      hi: 'Hi you',
      title: 'Good',
      evening: 'Evening',
      morning: 'Morning',
      afternoon: 'Afternoon'
    },
    about: 'About',
    team: 'Our Story',
    privacy: 'Privacy Policy',
    supportCenter: 'Support Center',
    prices: 'Pricing & Service times',
    account: 'Account',
    getStarted: 'Get started',
    getStartedDesc: 'Verify your ID and link your bank account.',
    getStartedNow: 'Get Started now',
    aboutYou: 'Profile',
    aboutYouDesc: 'View or change your personal details.',
    badges: 'Your badges',
    badgesDesc: 'Nab badges and cash rewards when you promote MC.',
    interest: 'Interest',
    accountActivity: 'Account Activity',
    blog: 'Blog',
    wallet: 'Wallet',
    membership: 'Membership',
    membershipDesc: 'View your points, benefits, and more.',
    security: 'Security',
    securityDesc: 'View or change your security settings.',
    signout: 'Sign out',
    history: 'History',
    btnInvestments: 'Investments',
    btnBuyBackInvestments: '<div class="textLeft"><small>Investments</small><div>Loan Originator</div></div>',
    btnSecondaryMarket: 'Secondary market',
    btnLOSecondaryMarket: '<div class="textLeft"><small>Secondary market</small><div>Loan Originator</div></div>',
    btnLoans: 'Loans',
    btnShortSelling: 'Short selling (Advanced)',
    btnshortSimple: 'Short Selling (Simple)',
    btnLoansC2C: 'Crypto Credit',
    btnLoanSettings: '<div class="textLeft"><small>Loan</small><div>Settings</div></div>',
    btnSpending: 'Gift Cards',
    prizeWheel: 'MCT Prize Wheel',
    prizeWheelDesc: 'Earn points for Prize Wheel spins to earn up to $10M.',
    cryptoBackedDesc: 'View instant access and fixed-term investments.',
    loanOriginatorDesc: 'View Loan Originator investments.',
    loanDesc: 'View active and settled loans.',
    secondaryMarketDesc: 'View bought and sold investments.',
    accountActivityDesc: 'View interest earnings, deposits, withdrawals, and more.',
    dashBoard: 'Dashboard',
    dashBoardDesc: 'View your balance, deposit, withdraw, and more.',
    lendingLotteryDesc: 'View fixed-term investments.',
    mctToken: 'MCT Token',
    mctAirdrop: 'MCT Airdrop',
    mctMembership: 'MCT Membership',
  },
  landingPage: {
    faqsSectionLink: '<p class="clearBottom">Questions? Check out our <a href="#landing-faqs" class="underline">FAQs</a>.</p>',
    promotionGeneral: {
      // title: `<p class="header">Invest in your future <br/><span class="hightlight">with a free \${kycTrialAmount} trial</span></p>`,
      title: `<p class="header"><span class="hightlight">Earn Triple Returns</span><br/>on Your First Investment</p>`,
      // desc: `
      //   <p>Experience the magic of compounding interest with collateral-backed P2P lending. Sign up, verify your ID, and enjoy {flexRate}% APY on a \${kycTrialAmount} trial bonus. After {kycTrialPeriod} days, we reclaim the bonus but you keep the interest. Start your investing journey for free today.</p>
      //   <p class="hightlight">Also, don't miss a chance to get 18% APR for your first investment of at least $1,000.</p>
      //   <p class="font-italic">This offer is for a limited time and only available to US citizens.</p>
      // `,
      desc: `
        <p>Grow your money with collateral-backed P2P lending and enjoy generous returns at triple our usual rate. <strong class="hightlight">Earn 18% APR</strong> when you invest your first $1,000 in any 30-day loan, backed by liquid collateral that is sold if borrowers default.</p>
        <p>Sign up now and you’ll also receive <strong class="hightlight">a free \${kycTrialAmount} trial</strong> of our anytime-withdrawal investment account. You earn 4% APY for 15 days and the interest is yours to keep (but not the \${kycTrialAmount}).</p>
        <p>See how rewarding collateral-backed lending can be. <strong>Open your free account today.</strong></p>
        <p class="font-italic">This offer is for a limited time and only available to US citizens.</p>
      `,
      howItWorks: {
        title: 'How It Works',
        data: {
          '0': {
            title: 'Step 1',
            desc: '<p>Sign up for your free MyConstant account.</p>'
          },
          '1': {
            title: 'Step 2',
            desc: '<p>We deposit your ${kycTrialAmount} free trial bonus to earn {flexRate}% APY over {kycTrialPeriod} days.</p>',
          },
          '2': {
            title: 'Step 3',
            desc: '<p>Watch your money grow, compounded and paid every second.</p>'
          },
          '3': {
            title: 'Step 4',
            desc: '<p>After {kycTrialPeriod} days, we reclaim the bonus but you keep the interest.</p>',
          },
          '4': {
            title: 'Step 5',
            desc: '<p>Keep the interest in your balance to earn 4% APY or withdraw it only after you\'ve made a USD deposit.</p>',
          }
        }
      },
      howWeProtectYou: {
        title: 'How We Protect You',
        data: {
          '0': {
            desc: '<p>Prime Trust is the accredited custodian of our USD reserve. When you deposit, your unloaned funds are stored across multiple bank accounts <strong>insured to a total of $130,000,000</strong>.</p>'
          },
          '1': {
            desc: '<p>When you invest in P2P lending through MyConstant, your investment is <strong>up to 200% backed by collateral</strong>. If borrowers default or their collateral falls too much in value, we sell their collateral to repay you.</p>'
          }
        }
      }
    },
    christmas: {
      title: `
        <p class="header">Get your free \${kYCTrialAmount} trial <br/>to celebrate our 2nd birthday</p>
      `,
      titleMobile: `
        <p class="header">Get your free \${kYCTrialAmount} trial <br/>to celebrate our 2nd birthday</p>
      `,
      desc: `
        <p>We’re another year older and giving away double bonuses to celebrate. Sign up and verify your ID (KYC) and <strong>your first investment of \${kYCTrialAmount} at 4% APY is on us.</strong> After 30 days, we reclaim the bonus but you keep the interest. All lending is backed by collateral, protecting your funds, and there are no fees. Claim your bonus now for double returns.</p>
        <p style="font-style: italic;">This offer is for a limited time and only available to US citizens.</p>
      `,
    },
    birthday: {
      title: `
        <p class="header">Limited time: Get double bonus<br/> of \${kYCTrialAmount} at 4% APY</p>
      `,
      titleMobile: `
        <p class="header">Limited time: Get double bonus<br/> of \${kYCTrialAmount} at 4% APY</p>
      `,
      desc: `
        <p>New year, bigger bonus! To celebrate our 2nd birthday, we’re doubling <strong>your free trial bonus to \${kYCTrialAmount} at 4% APY</strong> when you sign up and verify your ID (KYC). After 30 days, we take the bonus back but the interest is yours to keep or reinvest. All lending is backed by collateral. No fees. Sign up now and double your returns.</p>
        <p style="font-style: italic;">This offer is for a limited time and only available to US citizens.</p>
      `,
    },
    luckySpin: {
      result: {
        contentHtml: `
          <h1 class="last">Thank you for spinning our birthday<br/> Prize Wheel!</h1>
          <p class="clearBottom">We hope you’ve enjoyed your prizes.</p>
          <p class="clearBottom">Keep your eye out for other giveaways in the future.</p>
          <p>Who knows – maybe the Prize Wheel will stick around.</p>
          <p class="clearBottom" style="margin-bottom: 5px;">Have a fantastic 2022!</p>
          <p class="font-italic">The MyConstant Team</p>
        `,
        desc: {
          1: `
            <div>WE AWARDED</div>
            <div class="value">{spins}</div>
            <div>SPINS</div>
          `,
          2: `
            <div>WE RECEIVED</div>
            <div class="value">{wishes}</div>
            <div>SHARES ON SOCIAL MEDIA</div>
          `,
          3: `
            <div>CUSTOMERS WON</div>
            <div class="value">{points}</div>
            <div>MEMBERSHIP POINTS</div>
          `,
        },
        youtube: {
          title: 'Interview with Frank DiPalma.',
        },
      },
      prizeHistory: {
        gift: `{userName} won <span class="prize">+{reward}{unit}</span> {type} on their lucky spin`,
        gift_food: `{userName} won <span class="prize">+{reward} {unit}</span> {type} for their virtual pet on`,
        task: `<span class="name">{userName}</span> got <span class="prize">+{reward}{unit}</span> for {action}`,
      },
    },
  },
  form: {
    required: 'Required',
    invalidEmail: 'Invalid Email Address',
    unSupportFile: 'Unsupported Format',
    exceedSize: 'File too large'
  },
  badges: {
    title: 'Badges',
    historyTab: 'History',
    intro: {
      title: '<h1>Get the recognition you deserve with <br/>MyConstant Badges</h1>',
      desc: 'Earn badges and cash rewards of up to $100 when you use or promote our platform. From passing KYC to referring friends, there’s a badge to recognize all of your achievements. How many will you earn?',
      almostDoneTitle: 'Hi, {name}',
      almostDoneDesc: '<p>Earn some quick wins with the badges on the right.</p><p>View all of your earned badges and progress towards others below.</p>',
      congratTitle: 'Congratulations!',
      congratDesc: 'You\'ve earned all our badges. But the awards aren\'t over yet. Stay tuned for more badges and rewards that recognize your achievements.',
      earnDaily: 'Earned <strong>{amount} points</strong> for your Daily Login activity',
      claimedMessage: 'You have claimed your daily reward. Come back tomorrow for more!',
      see_term: 'See Terms and Conditions',
    },
    badge: {
      membership_gold: {
        title: 'Gold Membership',
        description: 'To qualify for badges and rewards, you must invest $25,000 in total within 180 days and keep that investment until the term ends (after leaving a review on Trustpilot). <a href="/membership">See more benefits.</a>',
        name: 'Gold Membership'
      },
      membership_platinum: {
        title: 'Platinum Membership',
        description: 'To qualify for badges and rewards, you must invest $50,000 in total within 180 days and keep that investment until the term ends (after leaving a review on Trustpilot). <a href="/membership">See more benefits.</a>',
        name: 'Platinum Membership'
      },
      membership_diamond: {
        title: 'Diamond Membership',
        description: 'To qualify for badges and rewards, you must invest $100,000 in total within 180 days and keep that investment until the term ends (after leaving a review on Trustpilot). <a href="/membership">See more benefits.</a>',
        name: 'Diamond Membership'
      },
    },
    group: {
      getting_started: 'Getting Started',
      warming_up: 'Warming Up',
      referring: 'Referring',
      membership: 'Membership',
      winning: 'Winning',
      spending: 'Spending',
      getting_started_desc: "It's easy to earn your first four badges – just set up your account, secure it, and deposit at least $100 to earn 4% APY. That's it!",
      referring_desc: 'We love it when you share your experiences with others. These badges reward you for referring friends and family, writing or streaming about us, or reviewing the platform for your community.',
      warming_up_desc: "These badges reward experimentation – they're all about getting to know our products and trying them out for the first time. Then, sharing your experiences with people and helping them sign up.",
      membership_desc: 'Invest or borrow regularly to become a Gold, Platinum, and then Diamond member. Each membership tier offers exclusive platform benefits and rewards. The higher your membership, the better the better the rewards, so start your membership journey now.',
      winning_desc: "Once you've conquered the previous levels, now it's time to prove you're a winner! These badges reward the biggest and best achievements, and in turn, offer some of the best rewards.",
      spending_desc: 'These badges reward spending. They’re all about spending the money that you have in your instant-access account on your debit card. The more you use the card, the more points you receive.',
    },
    detail: 'Details',
    share: 'Share',
    conditions: 'Conditions',
    term: `
      <h2 class="semiBold">Terms and Conditions</h2>
      <ol>
        <li><p>Any abuse of the MyConstant Badges reward program will result in immediate disqualification. Abuse includes spam, cheating, or any other fraud intended to earn badges illegitimately.</p></li> 
        <li><p>Any content you produce about MyConstant to claim a badge must not denigrate, disparage, or otherwise damage the MyConstant brand and you also consent to us using your content in promotional material and sharing across our website and social media platforms.</p></li> 
        <li><p>You earn a reward for each badge once only.</p></li> 
        <li><p>The maximum reward you can earn from the MyConstant Badges program is \${amount} in total.</p></li> 
        <li><p>Only one MyConstant account per badge. Duplicate MyConstant accounts are disqualified.</p></li> 
        <li><p>Badges are awarded retroactively but rewards apply to qualifying activities after July 16th 2020 only. For example, if you passed KYC before July 16th 2020, you'll earn the “Member” badge but not the reward.</p></li> 
        <li><p>MyConstant reserves the right to withhold rewards and change the terms and conditions of MyConstant Badges at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.</p></li> 
        <li><p>To be eligible for rewards, you must have passed KYC.</p></li> 
      </ol>
      <p class="bold effective">Effective as of {date}</p>
    `,
    request: 'Request',
    takeQuiz: 'Take the quiz',
    checkLater: 'Check later',
    submitForm: {
      errNeedKyc: 'You must pass KYC before you can request this badge. <a href="/me/profile">Submit your KYC documents or approval.</>',
      title: 'Submit the link to your review',
      titleAppster: 'Please enter your Apple ID / Google Play account',
      subtitle: "Thanks for requesting the {badgeName} badge. Please enter the link that supports your request below. We'll get back to you within two business days with a decision.",
      subtitleTrustpilot: `
          <p>Want to snag a Supporter badge ($1 value) and 5 FREE Lending Lottery tickets? It's easy! <a rel=\"nofollow\" href=\"https://www.trustpilot.com/review/myconstant.com\" className=\"underline\" target=\"_blank\">Just leave us a review on Trustpilot</a>. Then paste the link of your review below and click submit. Your rewards will be released to your account after being verified.</p>
          <p>You must also be an active MyConstant customer, with at least one deposit, invest, or borrow order in the past three months.</p>
        `,
      submit: 'Submit',
      cancel: 'Cancel',
      success: 'Submit proof successfully',
      errProofPending: `Your badge request is still under review. If you've not had a response in two business days, please contact <a href="mailto://tina@myconstant.com">tina@myconstant.com</a>.`,
      errCompletePrevious: 'To qualify for {current}, you need to complete {previous} first.'
    },
    congratulations: {
      getting_started_on_2fa: 'You\'ve won the Guardian and {rewardAmount} reward points.',
      getting_started_link_bank: 'You\'ve won the Booster and {rewardAmount} reward points.',
      activity_install_app: 'Thanks for downloading our app. You\'ve banked 1,000 membership points free!',
      activity_take_mini_course: 'You got 100% in our Mini Quiz – nice work! Here\'s {rewardAmount} membership points as reward.',
      membership_gold: 'You\'ve become Gold member and gotten 1,000 membership points. Keep investing and get more valuable gifts.',
      membership_platinum: 'You\'ve become Platinum member and a $50 reward is ready in your account. Keep investing and get more valuable gifts.',
      membership_diamond: 'You\'ve become Diamond member and a $50 reward is ready in your account. Keep investing and get more valuable gifts.',
    },
    submit: 'Submit',
    history: {
      title: 'Badges History',
      headers: {
        date: 'Date & time achieved',
        badgeName: 'Badge name',
        reward: 'Reward',
        category: 'Category',
      }
    },
  },
  saving: {
    invalid_payment_method: 'To deposit USD, you must be a KYC-verified US citizen. Please choose another currency to deposit.',
    makeLocalOrderFailed: "Sorry, we couldn't create your order this time. Please try again. If you continue experiencing problems, please contact us at hello@myconstant.com. Thank you.",
    invalid_amount: 'Invalid amount, please check your amount and try again',
    validation_error: 'Ensure that there are no more than 10 digits before the decimal point.',
    zelleOverLimit: `Sorry, Zelle restrictions mean we're unable to process any further Zelle deposit at the moment.` ,
    not_able_to_generate_ref_code: 'Oops, we couldn\'t generate a reference code for your deposit. Please refresh this page and try again. If the problem continues, drop us an email at hello@myconstant.com.',
  },
  send: {
    invalidBankAccountName: 'For your security, the beneficiary name must match the name you registered when signing up with MyConstant. Please enter a different name or email hello@myconstant.com if you need help',
    makeLocalOrderFailed: "Sorry, we couldn't create your order this time. Please try again. If you continue experiencing problems, please contact us at hello@myconstant.com. Thank you.",
    makeLocalOrderSuccess: 'Thanks! Your transfer is now processing.',
    zelleOverLimit: `Sorry, Zelle restrictions mean we're unable to process any further Zelle withdrawals at the moment. Please try again tomorrow or choose another withdrawal method. For more information, please check our <a href="/getPrices" target="_blank" class="underline">service times</a>. Thank you.` ,
    makeDirectOrderSuccess: 'Thanks! Your transfer is now processing.',
    makeDirectOrderFailed: "Sorry, we couldn't create your order this time. Please try again. If you continue experiencing problems, please contact us at hello@myconstant.com. Thank you.",
    transferConfirm: "We've already sent you a verification link by email. Please click this link to approve your transaction.",
    transferConfirmPrimeTrust: `Prime Trust, our custodian, has sent you a verification link by email. Please <strong>click this link to approve your transaction</strong> otherwise it'll be automatically cancelled.`,
    warningNonUSPrimeTrust:`
      <p><strong>Sorry, we’re currently unable to process USD withdrawals to non-US banks. </strong></p>
      <p>Due to an unusually high number of wire reversals, we’ve temporarily suspended USD withdrawals to banks outside of the US. <strong>If you’re a non-US resident, please note you can only withdraw to a US bank account</strong>. We’re working with Prime Trust, our custodian, to reinstate fiat withdrawals for non-US customers and will let you know as soon as service resumes.</p>
    `
  },
  callUs: {
    title: 'Call Us',
    header:'Set your preferred time',
    yourName:'Your name',
    phoneNumber:'Phone number',
    emailAddress:'Email address',
    content:'Tell us a little about your problem or question',
    contentDesc:'You want to ask about…',
    selectPreferredTime: 'Select your preferred time',
    selectHour: 'Hour',
    desc:'Click <strong>Book a call</strong> and we\'ll call you at the time specified above.',
    bookACall: 'Book a call',
    requestSuccess: 'Successfully Submitted',
    requestFailed: 'Failed To Submit',
  },
  helpAndSupport: {
    notFound: 'No results for',
    found: 'results for',
    search: 'Search results',
    searchPlaceholder: 'Search for an article or question',
    title: 'Help & Support',
    what: 'What would you like to do?',
    whatDesc: 'Click a button below to access step-by-step guides and tutorials',
    question: 'Have a specific question?',
    questionDesc: 'Click a topic below and we’ll answer it as best we can.',
    socials: {
      blog: {
        link: 'https://myconstant.com/blog/',
        title: 'Read our blog',
        desc: 'Discover the latest news, guides, and opinon from the team and guests.'
      },
      chat: {
        link: 'https://constant.zendesk.com/hc/en-us',
        title: 'Chat with us live',
        desc: 'Speak to our customer support team, day or night.'
      },
      email: {
        link: 'mailto:hello@myconstant.com',
        title: 'Send us an email',
        desc: 'We’d love to hear from you.'
      },
      fb: { link: 'https://www.facebook.com/myconstantp2p' },
      tw: { link: 'https://twitter.com/myconstantp2p' },
      linkedin: { link: 'https://www.linkedin.com/company/constantp2p/' }
    },
    announcements: 'Announcements',
    announcementsDesc: 'All you need to know, in one place.',
    helpAndSupport: 'Help and Support',
    categories: 'FAQ categories',
    form: {
      caption: "Can't find what you're looking for? Tell us what you need below.",
      email: 'Your email address',
      title: 'Subject',
      note: 'Description',
      submit: 'Submit',
      emailError: 'Requester email address is invalid.',
      titleError: 'Subject: cannot be blank',
      noteError: 'Description: cannot be blank',
      posts: {
        false: 'Uh oh! Something went wrong - ',
        retry: 'please try again.'
      }
    },
    bookingTitle: 'How can we help you today?',
    bookingDesc: 'Give us a ring on <br/><strong>+1 646 809 8338</strong> or we’ll call you with all the help and answers you need.',
    notification: '<strong>Want to find your own answers?</strong> Scroll down to find our guides, FAQs, and other resources',
    bookingCall: {
      title: 'When should we call you?',
      name: 'Your name',
      nameRequired: 'Requester name is required',
      phone: 'Phone number',
      phoneRequired: 'Requester phone is required',
      email: 'Email address',
      emailRequired: 'Requester email address is invalid',
      times: 'Select your preferred time',
      timesRequired: 'Please, select your preferred time',
      askFor: 'Tell us a little about your problem or question',
      askForRequired: 'Tell us a little about your problem or question',
      booking: 'Book a call',
      desc: 'Click <strong>Book a call</strong> and we’ll call you at the time specified above.',
    },
    noResults: 'Sorry! content not found',
    browseCategories: 'Browse categories',
    investLinksDesc: 'Below, you\'ll find guides and tutorials that cover all aspects of investing with MyConstant. Can\'t find what you\'re looking for? Please let us know! <a href="/help-and-support">Book a call</a> or email us on <a href="mailto://hello@myconstant.com">hello@myconstant.com</a> and we\'ll help you.',
    borrowLinksDesc: 'Below, you\'ll find guides and tutorials that cover all aspects of borrowing with MyConstant. Can\'t find what you\'re looking for? Please let us know! <a href="/help-and-support">Book a call</a> or email us on <a href="mailto://hello@myconstant.com">hello@myconstant.com</a> and we\'ll help you.',
  },
  contactSupport: {
    title: '<strong>Need help or <br />have a question?</strong>',
    callText: 'Call us on (+1) 646 809 8338',
    bookText: 'Book a call',
    chatText: 'Log in to Messenger',
    liveChatText: 'Chat',
    timeSupport: 'We\'re available <strong>Mon-Fri 9am-5pm</strong> and<br /> <strong>Mon-Thurs 7pm-4am PST</strong>'
  },
  withdrawFees: {
    title: 'Fee schedule and collateral rules',
    headers: {
      coin: ' ',
      network: 'Network',
      minAmount: 'Minimum withdrawal',
      fee: 'Withdrawal fee',
      price: 'Current price',
      min30Price: '30-day low',
      recallExcess: 'Recall excess threshold',
      updatedDate: 'Updated',
      ctv: 'CTV',
    },
    desc30DayLow: 'This is the cryptocurrency\'s lowest price over a rolling 30-day period.',
    descCtv: 'The Collateral to Value (CTV) ratio expresses the amount of collateral you need as a percentage of the loan amount. In other words, it\'s the reciprocal of the LTV (Loan to Value ratio).',
    descRecallExcess: 'You can only recall excess collateral once the CTV (Collateral to Value ratio) rises above this threshold.',
    noRecord: 'No record found'
  },
  miniQuiz: {
    title: 'MyConstant\'s mini-quiz',
    errorNoAnswer: 'Please choose your answer before submitting',
    endSessionMessage: 'You\'ve got {numberQuestion} questions right in our Mini Quiz and earned {reward} points. Keep going to earn another {remaining} points!',
  },
  exportInvestmentHistory: {
    dialogTitle: 'You can export up to six months of your investment history.',
    fromDate: 'From Date',
    toDate: 'To Date',
    download: 'Download',
    cancel: 'Cancel',
    noData: 'There’s no transaction history to export from {from} to {to}.\n\nPlease choose another time period.'
  },
  mobile_new_home: {
    "lb_interest_rate": "Interest rate",
    "lb_loan_term": "Loan term",
    "up_to": "Up to {value}%",
    'from': 'From {value}%',
  },
  productsAndBenefits: {
    title: 'Our products and benefits',
    desc: `Below is a handy comparison chart <br/>of our products and benefits.`,
    invest: 'INVEST',
    borrow: 'BORROW',
    benefit: 'BENEFITS',
    flex: 'Instant access',
    cryptoBacked: 'Fixed term',
    cryptoLend: 'Crypto Lend',
    lendingLottery: 'Lending Lottery',
    loanOriginator: `Loan Originator<br /><span class="soon">(coming soon)</span>`,
    cryptoBackedLoan: 'Crypto-backed loans',
    viewAll: 'Show all Our Products & Benefits',
    products: 'PRODUCTS',
  },
  statistic: {
    banner: {
      title: `
        <h1>Our results so far</h1>
        <p>Below, you’ll find our historical performance statistics. These numbers represent investor and borrower activity to date. Use these figures to gain a deeper understanding of the risk and rewards of using our collateral-backed investment platform.</p>
      `,
    },
    stats: {
      interest: 'Average interest earned',
      interestDesc: 'This is the average interest rate earned by our investors since launch, updated daily.',
      numInvestment: 'Average number of <br/> investments per customer',
      numInvestmentDesc: 'The average number of investments per customer since launch, updated daily.',
      sizeInvestment: 'Average size of investments',
      sizeInvestmentDesc: 'This is the average invested amount since launch, updated daily.',
      totalInterest: 'Total interest earned',
      totalInterestDesc: 'This is the total interest earned by all of our investors since launch, updated secondly.',
      defaultRate: 'Liquidation rate',
      defaultRateDesc: 'This is the percentage of loans that were liquidated due to borrower default or collateral devaluation since launch. This figure is updated daily.',
      recoveryRate: 'Recovery rate',
      recoveryRateDesc: 'This is the percentage of loan funds recovered as a result of collateral liquidation due to borrower default or collateral devaluation. This figure is updated daily.',
    },
  },
  giftCard: {
    title: 'Gift cards',
    intro: {
      content: `
        <p class="topText">Pay less for what you love</p>
        <h1>Save <span class="highlight">up to {rate}%</span> on gift cards*</h1>
        <h2>Choose from <span class="highlight">{numCollateral} retailers</span>.</h2>
        <h2 class="last"><span class="highlight">Instant</span> digital delivery.</h2>
        <p>Shop and save up to 3% at your favorite brands and restaurants with MyConstant Gift Cards. Choose an amount and retailer and get your card in seconds. No fees. No postage. Never pay full price again.</p>
        <p>The 3% saving applies to gift cards purchased before {date} and is paid instantly as cashback to your MyConstant balance.</p>
      `,
      desc: {
        1: 'Pay up to 3% less for your favorite brands',
        2: 'Instant payment through your MyConstant balance',
        3: 'Gift card code sent straight to your inbox',
      },
    },
    form: {
      title: 'Where do you want to shop?',
      retailer: 'Retailer',
      amount: 'Amount',
      youPay: 'You pay',
      cashbackNote: '${amount} will return to your MyConstant balance as cashback.',
      notEnoughBalance: 'Sorry, you don\'t have enough in your balance to order this gift card. Please deposit now and then return to this page and select your gift card again.',
    },
    successBox: {
      title: 'You saved ${savedAmount}!',
      subtitle: 'Nice work, {name}! Your gift card is on its way to your inbox now. In the meantime, view your order details below.',
      note: 'If you have any queries regarding your order, please contact our Customer Services team on <a href="mailto://hello@myconstant.com">hello@myconstant.com</a>',
      newOrder: 'Place new order',
    },
    history: {
      headers: {
        createdAt: 'Created At',
        name: 'Name',
        amount: 'Amount',
        quantity: 'Quantity',
        commission: 'Commission',
        status: 'Status',
      },
      noSpendings: 'Save up to 3% on MyConstant gift cards',
    },
  },
  registerAccreditation: {
    pending: 'Pending',
    approved: 'Approved',
    yourAccreditation: {
      date: 'Date',
      type: 'Type',
      option: 'Provide Evidence Method',
      status: 'Status',
      assets: 'Assets',
      income: 'Income',
      options: {
        '1': 'Send verification request to my lawyer, CPA, investment adviser or broker',
        '2': 'Upload document (in English or with English Translation)',
        '3': 'Send verification request to my lawyer, CPA, investment adviser or broker',
        '4': 'Upload a credit report stating your liabilities that is dated within the last 30 days.'
      },
    },
    netWorths: 'Networths',
    yes: 'Yes',
    no: 'No',
    agreement: 'I authorize AngelList in writing to periodically obtain my credit report for a transaction I am initiating. AngelList may use this data and share it with any issuer solely to verify my investor status in compliance with SEC requirements.',
    accreditation_evidence_location: 'Do you have a U.S. Social Security Number?',
    verifierRole: 'Verifier Role',
    verifierEmail: 'Verifier Email',
    accreditation: 'Accreditation',
    photoIncome1: 'Photo Income 2020',
    photoIncome2: 'Photo Income 2021',
    accreditationAlt: 'US law requires you to be accredited to invest in 506(c) fundraising offerings',
    title1: 'How are you accredited?',
    desc1: 'We require proof of accreditation from you that meets the more stringent standard the SEC has put in place – the 506(c) standard – which should work for all accredited deals, including ones that publicly discuss their financing.',
    income: {
      title: 'Income',
      alt: 'Valid for 1 tax year',
      description: 'Income of $200,000, or $300,000 with spouse, in each of the past 2 years and expecting the same or more this year.'
    },
    assets: {
      title: 'Assets',
      alt: 'Valid for 3 months',
      description: 'Net worth of at least $1,000,000, individually or joint with spouse, excluding your primary residence'
    },
    text1: 'Provide evidence of accreditation',
    text2: 'They\'ll be asked to confirm that they have seen evidence of your claimed status. No documentation required.',
    text3: 'W2, 1040, other IRS or foreign tax authority document containing salary for the past 2 years (2020 and 2021), or a letter from your lawyer, accountant, investment advisor, or investment broker',
    text4: 'Upload a statement from a financial institution, asset appraisals, or letter from your lawyer, accountant, investment advisor or investment broker indicating net assets',
    investment_advisor: 'Investment Adviser (SEC Registered)',
    broker: 'Investment Broker (SEC Registered)',
    accountant: 'Accountant (must be CPA)',
    lawyer: 'Lawyer',
    ssn: 'Social Security Number',
    accreditation_evidence_allow_credit_report: 'I authorize AngelList in writing to periodically obtain my credit report for a transaction I am initiating. AngelList may use this data and share it with any issuer solely to verify my investor status in compliance with SEC requirements.',
    totalDebt: 'Total Debt',
    photoEvidence: 'Photo evidence',
    photoCreditReport: 'Credit report',
    status: 'Status',
    alert: {
      success: 'Saving Form Submitted'
    },
    requireAccretationDialog: {
      title: 'Sorry, you\'re not accredited yet',
      titlePending: 'Sorry, your accreditation is still pending',
      message: 'To invest in Loan Originator loans, you must be an accredited investor. Please register your accreditation now.',
      messagePending: 'Please wait until your accreditation registration is complete before investing. You can track your registration status by clicking View registration status below.',
      confirmTitle: 'Register now',
      confirmTitlePending: 'View registration status',
    }
  },
  cryptoShort: {
    intro: {
      content: `
        <h2>Predict a bear market?</h2>
        <h1 class="hightlight">Earn big when prices drop.</h1>
        <h2 class="last">High risk. High reward.</h2>
      `,
    },
    form:  {
      liveDesc: 'This is your live account. When you open new Short Selling positions here, they are real orders and you stand to gain or lose depending on what the market does.',
      demoDesc: 'This is your demo Short Selling training account. Create virtual orders here and see how well you can predict the market! As these aren’t real orders, you won’t earn a profit or lose a cent. When you’re ready to try real Short Selling trading, click the “Live” tab at the top.',
      tooltipMarketPrice: 'Enable Use Market Price to open your short position immediately at the market price. Or, create a short-selling order by disabling this option and entering the price at which you’d like to short and your position will open automatically once that price is reached.',
      toolTipCollateral: 'The Loan to Value ratio (LTV) measures the maximum you can borrow against your USD collateral. For the purpose of shorting, you can borrow up to {upToRate}% of the value of your USD collateral. For example, if you have ${egBase} in your short-selling balance, you can short up to ${egUpTo} worth of the crypto in that pair.',
      toolTipTakeProfit: 'Take profit is the price at which you repay your shorted cryptocurrency. This should be at least 5% below the current market price. When the take profit price is reached, your shorted crypto will be repaid in its USD equivalent automatically from your short-selling balance. You keep the profits minus interest and matching fee.',
      toolTipStopLoss: 'The stop loss is the price at which you repay your shorted crypto to avoid accumulated losses. It should be at least 5% above the current market price. When the stop loss price is reached, your shorted crypto will be repaid in its USD equivalent automatically from your short-selling balance. This minimizes further losses in the event of price rises.',
      successShortMarket: 'Done! You successfully opened a {crypto} short position',
      successShort: 'Done! You’ve created an order to short {amount} {crypto} once its price reaches {price} USD. To amend or cancel this short-selling order, please visit your accounts page.',
      errorShort: 'Sorry, we can\'t create your order at the moment. Please try again in a few minutes. If you keep seeing this message, please email us at hello@myconstant.com.',
    },
    howItWorks: {
      0: {
        title: 'Choose the crypto you want to short',
        desc: 'We support BTC, ETH, and BNB pairs.'
      },
      1: {
        title: 'Transfer USD to your short-selling balance',
        desc: 'If you don\'t have USD in your account, you\'ll need to deposit first.',
      },
      2: {
        title: 'Set stop loss and take profit prices',
        desc: 'Select an upper and lower price point to protect your gains and limit losses.',
      },
      3: {
        title: 'We sell the crypto at market prices',
        desc: 'The proceeds go back to your USD short-selling balance.',
      },
      4: {
        title: 'Repay the crypto, pocketing any difference',
        desc: 'If your prediction pays off, you keep the profits.',
      },
    }
  },
  pairBalance: {
    header: {
      pair: 'Pair',
      availableBalance: 'Short-selling balance',
      collateralBalance: 'Collateral balance',
      cryptoBorrowed: 'Crypto borrowed',
      interest: 'Interest due',
      totalRepayable: 'Total repayable',
      collateralRate: 'Collateral rate',
      tpAndSl: 'TP / SL',
      PnL: 'Profit / Loss',
    },
    noRecord: 'No record found',
  },
  cryptoFuture: {
    intro: {
      content: `
        <h1>Turn price predictions <br /><span class="orange">into profit</span> with Futures.</h1>
      `,
      contentDemo: `
        <h1>Test your <span class="orange">prediction skills</span><br /> with your demo Futures account.</h1>
      `,
      desc: {
        '1': 'Start from just $1',
        '2': '50x leverage',
        '3': 'Repay early',
      }
    },
    form: {
      liveDesc: 'This is your live account. When you open new Futures positions here, they are real orders and you stand to gain or lose depending on what the market does.',
      demoDesc: 'This is your demo Futures training account. Create virtual orders here and see how well you can predict the market! As these aren’t real orders, you won’t earn a profit or lose a cent. When you’re ready to try real Futures trading, click the “Live” tab at the top.',
      predictionDesc: 'Select “Rise” if you think the price will rise or “Fall” if you think the price will fall. Your position will automatically close when the price changes by {tpRate}% in your predicted direction (to bank profits) or {slRate}% in the opposite direction (to minimize losses) unless you repay early.',
      amountDesc: 'To open a Futures position, you must have USDT or USDC available in your balance to use as collateral. You won’t be able to withdraw your collateral until your Futures position closes.',
      feeDesc: 'The fee is {feeRate}% of your total leveraged position. For example, if you open a position with ${egAmount} and {multipleRate}x leverage, your fee would be: {feeRate}% x ({multipleRate} x ${egAmount}) = ${egFee}.',
      winDesc: 'This is the amount of your profit at the point at which your position automatically closes. For a fall position, the position closes when the price falls by {futureRate}%. For a rise position, the position closes when the price rises by {futureRate}%. You can repay early to take profits sooner.',
      loseDesc: 'This is the amount of your loss at the point at which your position automatically closes. For a fall position, the position closes when the price rises by {futureRate}%. For a rise position, the position closes when the price falls by {futureRate}%. You can minimize losses by repaying early.',
      createOrderError: 'Sorry, we can\'t open your position at the moment. Please try again in a few minutes. If you keep seeing this message, please email us at hello@myconstant.com.',
    },
    howItWorks: {
      0: {
        title: 'Enter your prediction',
        desc: 'Choose a rise or fall prediction and then enter an amount of crypto to borrow in USD (this will be reserved in your balance as collateral).<br/>Min: {min} USD. Max: {max} USD.',
      },
      1: {
        title: 'We buy the crypto',
        desc: 'You borrow the crypto at current market price using {multipleRate}x leverage. This means you borrow {multipleRate}x more crypto than your USD collateral.',
      },
      2: {
        title: 'Cash out or repay',
        desc: 'You profit when the price changes up to {tpRate}% in your predicted direction. Cash out anytime by repaying the borrowed crypto amount in USD.<br />Your position will automatically close at {tpRate}% to bank profits or at {slRate}% in the opposite direction to minimize losses.'
      }
    },
    why: {
      0: {
        title: 'Easy to learn',
        desc: 'Learn the basics with your virtual account and then open a small Futures position to test your prediction skills.',
      },
      1: {
        title: 'Sensible limits',
        desc: 'No need to risk everything on one guess. Start from just ${min} up to a maximum of ${max} for each position (no more than ${maxSize} across your account).',
      },
      2: {
        title: 'Generous payouts',
        desc: 'Since Futures use {multipleRate}x leverage, you can earn up to 150% profit when the price changes up to {tpRate}% in your predicted direction',
      },
    },
    history: {
      headers: {
        id: '#',
        time: 'Created At',
        finishedAt: 'Finished At',
        symbol: 'Crypto',
        type: 'Prediction',
        amount: 'Amount',
        openPrice: 'Opening Price',
        currentPrice: 'Current Price',
        closePrice: 'Closing Price',
        fee: 'Fee',
        chart: 'Chart',
        currentPnl: 'Current PNL',
        pnl: 'PNL',
        status: 'Status',
      }
    },
  },
  budget: {
    dashboard: {
      title: "Dashboard",
      how_it_work: "How it work",
      opening_balance: "Opening balance",
      ending_balance: "Ending balance",
      opening_balance_desc_1: "This is your balance at the beginning of the selected period. Effective date: %{start_date}",
      opening_balance_desc_2: "This is your balance at the beginning of the selected period.",
      ending_balance_desc_1: "This is your balance at the ending of the selected period. Effective date: %{end_date}",
      ending_balance_desc_2: "This is your balance at the ending of the selected period.",
      net_income: "Net income",
      net_income_desc: "Your total income minus total expenses for the selected period.",
      budgets: "Budgets",
      budget_desc: "Below, you’ll see budgets for the selected period. Set budgets, overspend warnings, and reminders so you don’t fall back into bad habits.",
      no_suggest_desc: "Set budgets, overspend warnings, and reminders so you don’t fall back into bad habits.",
      top_categories: "Top Categories",
      top_categories_desc: "Below, you’ll see the top of categories that has biggest amount for the selected period.",
      filter: "Filter",
      apply: "Apply",
      close: "Close",
      select_time_range: 'Select time range',
      select_wallet: 'Select wallet',
      income: "Income",
      expense: "Expense",
      transactions: "Transactions",
      linked_banks: "Linked banks",
      about: "About",
      balance_report: "Balance Report",
      balance_report_desc: "Below, you’ll see transactions for the current period grouped by category. This helps identify overspend as well as which expenses to cut back on.",
      view_all: 'View all',
      transaction: "Totals per Category",
      transaction_desc: "Below, you’ll see transactions for the selected period. This helps identify overspend as well as which expenses to cut back on.",
      show_less: "Show less",
      no_budget: "There's no budget history",
      expense_chart_desc: 'Below, you’ll see expense transactions for the selected period grouped by category.',
      income_chart_desc: 'Below, you’ll see income transactions for the selected period grouped by category.'
    },
    transaction: {
      title: 'Transactions',
      summary: "Summary",
      summary_desc: "Your total income and expenses for the current and previous period, as well as your current balance.",
      group_title: "Totals per category",
      group_desc: "Below, you’ll see transactions for the current period grouped by category. This helps identify overspend as well as which expenses to cut back on.",
      edit: 'Edit',
      delete: "Delete",
      detail: "Transaction detail",
      confirm_delete_title: "Delete transaction",
      confirm_delete_desc: "Are you sure you want to delete this transaction?",
      delete_success: "Done! Your manual transaction was deleted.",
      processing_transaction: "You linked your bank account for budgeting. All your transactions will synchronies in the next ten minutes or so.",
      empty: "To view your transaction data, please <a>link your bank account</a> or <a>add transactions manually</a>",
      add_transaction: "Add transaction",
      amount: "Amount",
      category: "Category",
      note: "Note",
      date: "Date",
      add: "Add",
      select_category: "Select category",
      update: "Update",
      wallet: "Wallet",

    },
    wallet: {
      title: "Link banks",
      desc: "List of all linked bank accounts that have approved to sync transactions for personal budget feature.",
      add_wallet: "Link your banks",
      remove_wallet_title: "Are you sure you want to unlink this account?",
      remove_wallet_desc: "When you unlink your bank account, you won’t be able to track income and expenses automatically. Instead, you’ll have to key transactions in manually.",
      invalid_credential: "Sorry, we couldn't sync your transactions as your login details have changed. Please relink your bank account.",
      not_support: "Sorry, your bank doesn't support synchronized transactions. Please link another account.",
      unexpected_error: "Sorry, something went wrong. Please contact us on hello@myconstant.com and we'll fix this for you.",
      re_link_bank: "Re-link",
      error: "Error",
      confirm_link_wallet: "By linking your bank account, you authorize MyConstant to retrieve and then display your transaction history for the purpose of budgeting. Are you sure you want to continue?",
      link_another_bank: "Link another bank account",
      apply: "Apply",
      non_us_link_bank: "Unfortunately, Link bank account is not available for non-US customers."
    },
    landing: {
      title: 'Earn, save, and manage money <span style="color:#F5A623;">the easy way</span> with our free budgeting app.',
      desc1: "Acheieve your financial goals fast with Personal Budget. Sync budgets to your bank account so you never miss a transaction. Set up overspend reminders to stay on traget. No receipts. No spreadsheets. Available in the MyConstant app now. ",
      feature1: "Track your income and expenses automatically",
      feature2: "Stay focused on your goals with overspend reminders",
      feature3: "Generate financial reports for deeper insights",
      tips: {
        title: "How it work",
        desc: "Budgeting needn’t be all spreadsheets and penny-pinching. Get started with Personal Budget today and see how easy it is to earn, track, and manage your money. Your financial goals are just a few steps away!",
        tip_label_1: 'Link bank accounts',
        tip_desc_1: "Link up to two unique bank accounts to synchronize all incoming and outgoing transactions. When you open the app, your budgets will update automatically, with no input from you.",
        tip_label_2: "Create your budget",
        tip_desc_2: "Set up budgets for your financial goals, whether that’s a vacation, home improvements, wedding, or retirement planning. The choices are unlimited – and better yet – yours!",
        tip_label_3: "Track your progress",
        tip_desc_3: "Drop by the app once in a while to check in on your progress. With handy status bars, charts, and color-coded summaries, it takes just moments to spot your financial strengths (and weaknesses!)."
      },
      why: {
        title: "Why use Personal Budget?",
        desc: "Achieve your financial goals fast with these powerful benefits at zero cost to you.",
        label_1: "Save hours of time and effort",
        desc_1: "Link a bank account to synchronize transactions automatically. No need to keep receipts or enter details manually.",
        label_2: "Keep focused on your goals",
        desc_2: "Set budgets, overspend warnings, and reminders so you don’t fall back into bad habits.",
        label_3: "Tailor budgets to you",
        desc_3: "Create and customize categories for your income and expenses so your budgets match your lifestyle and financial goals.",
        label_4: "Stay motivated",
        desc_4: "With at-a-glance graphics showing your progress and available spending money, it’s easier than every to stay motivated.",
        label_5: "Identify problem spending",
        desc_5: "Generate monthly spending reports to gain deeper insight into where your money goes."
      },
      download: {
        title: "Start creating wealth today.\nDownload it now.",
        desc: 'Earn, save, and grow with Personal Budget: A world of <span style="color:#F5A623;">financial power</span> in your pocket.'
      },
      faq: {
        title: "Frequently-asked questions (FAQs)",
      },
      feature: {
        title: "Everything you need to achieve your financial goals",
        desc: "Stop scrimping and start saving with Personal Budget’s all-in-one money-management tools.",
        label_1: "Real-time tracking",
        desc_1: "Link a bank account and transactions synchronize automatically. No data entry required!",
        label_2: "Multi-budget",
        desc_2: "Create and customize multiple budgets for different purposes or financial goals.",
        label_3: "Graphs & Reports",
        desc_3: "View progress towards your goals as well as periodic income and expense reports and charts",
        label_4: "Overspend reminders",
        desc_4: "Receive a notification every time you spend over your budget, helping you stay on track."
      }
    },
    budgets: {
      title: "Budgets",
      desc: 'We reclaim the bonus but you keep the interest. This offer is for a limited time and only available to US citizens.',
      add_budget: '+ Add budget',
      amount: 'Amount',
      category: "Category",
      time_range: 'Time range',
      wallet: "Wallet"
    }
  },
  shortSimple: {
    intro: {
      content: `
        <h2>New to short selling?</h2>
        <h1 class="hightlight">Want a fast, easy way to start?</h1>
        <h2 class="last">Try simplified short-selling.</h2>
      `,
    },
    form:  {
      liveDesc: 'This is your live account. When you open new Short Selling positions here, they are real orders and you stand to gain or lose depending on what the market does.',
      demoDesc: 'This is your demo Short Selling training account. Create virtual orders here and see how well you can predict the market! As these aren’t real orders, you won’t earn a profit or lose a cent. When you’re ready to try real Short Selling trading, click the “Live” tab at the top.',
      tooltipMarketPrice: 'Enable Use Market Price to open your short position immediately at the market price. Or, create a short-selling order by disabling this option and entering the price at which you’d like to short and your position will open automatically once that price is reached.',
      toolTipCollateral: 'The Loan to Value ratio (LTV) measures the maximum you can borrow against your USD collateral. For the purpose of shorting, you can borrow up to {upToRate}% of the value of your USD collateral. For example, if you have ${egBase} in your short-selling balance, you can short up to ${egUpTo} worth of the crypto in that pair.',
      toolTipTakeProfit: 'Take profit is the price at which you repay your shorted cryptocurrency. This should be at least 5% below the current market price. When the take profit price is reached, your shorted crypto will be repaid in its USD equivalent automatically from your short-selling balance. You keep the profits minus interest and matching fee.',
      toolTipStopLoss: 'The stop loss is the price at which you repay your shorted crypto to avoid accumulated losses. It should be at least 5% above the current market price. When the stop loss price is reached, your shorted crypto will be repaid in its USD equivalent automatically from your short-selling balance. This minimizes further losses in the event of price rises.',
      successShortMarket: 'Done! You successfully opened a {crypto} short position',
      successShort: 'Done! You’ve created an order to short {amount} {crypto} once its price reaches {price} USD. To amend or cancel this short-selling order, please visit your accounts page.',
      errorShort: 'Sorry, we can\'t create your order at the moment. Please try again in a few minutes. If you keep seeing this message, please email us at hello@myconstant.com.',
    },
    howItWorks: {
      0: {
        title: 'Choose the crypto you want to short',
        desc: 'We support BTC, ETH, and BNB pairs.'
      },
      1: {
        title: 'Set stop loss and take profit prices',
        desc: 'Select an upper and lower price point to protect your gains and limit losses.',
      },
      2: {
        title: 'We sell the crypto at market prices',
        desc: 'The proceeds go back to your USD short-selling balance.',
      },
      3: {
        title: 'Repay the crypto, pocketing any difference',
        desc: 'If your prediction pays off, you keep the profits.',
      },
    }
  },
  luckyDraw: {
    pickingPeriodStartingDateDesc: 'This is the starting date of the next draw for which your tickets are valid. You have up until midnight (PST) on the Sunday before this date to choose your numbers, after which they\'ll be chosen by the system automatically so you don\'t miss the start of the weekly draw on Monday.',
    intro: {
      contentHtml: `
          <h1 class="last">Invest and win up to <span class="hightlight">$10 million</span><br/>Earn 1% APR and free <br />lottery tickets</h1>
          <p>Lend money to exchanges to earn 1% APR and a chance to win up to $10 million. Get one ticket for every $100 invested. Nightly draws at 9pm PST. Winners announced on Sundays. No fees.</p>
        `,
      desc: {
        1: '1,000s of cash prizes to be won',
        2: 'New draws every week',
        3: 'Earn up to 10x more than top US banks',
      },
      learnMore: 'Learn more here',
    },
    howItWorks: {
      title: 'How it works',
      desc: 'When you invest in Lending Lottery, your money supplies liquidity to cryptocurrency exchanges that pay you a 1% APR cut of trading fees.<br/><br/>You also earn a free lottery ticket for every $100 you invest. Draws are weekly, with new numbers announced at 9pm every night. Winners are announced on Sundays.',
      data: {
        0: {
          title: 'Deposit',
          desc: `
            <h2>Deposit</h2>
            <p>Deposit funds into your MyConstant balance.</p>
          `,
        },
        1: {
          title: 'Invest',
          desc: `
            <h2>Invest</h2>
            <p>Choose how much to invest. All Lending Lottery investments are for 30 days, and each ticket is valid for four consecutive weekly draws.</p>
          `,
        },
        2: {
          title: 'Pick your numbers',
          desc: `
            <h2>Pick your numbers</h2>
            <p>Pick your numbers or generate them at random. If you don’t pick your numbers within 14 days, we’ll generate them randomly for you.</p>
          `,
        },
        3: {
          title: 'Check your numbers',
          desc: `
            <h2>Check your numbers</h2>
            <p>Check your numbers daily or when the draw ends on Sunday at 9pm PST. Tickets issued midweek are valid from the following week’s draw.</p>
          `,
        },
        4: {
          title: 'Enjoy your winnings',
          desc: `
            <h2>Enjoy your winnings</h2>
            <p>Prizes are paid instantly to your MyConstant balance. Check your odds of winning below. (If you cancel your investment mid-term, you lose earned interest and tickets.)</p>
          `,
        }
      }
    },
    form: {
      maxTerm: 'Length of term',
      placeholder: { amount: 'How much do you want to lend?' },
      text1: 'Set your own lending terms',
      text2: 'As soon as our custodian (Prime Trust) receives your funds you\'ll get your tickets and start earning interest. You can cancel your investment at any time and receive your principal back, but you’ll lose all tickets, winnings, and interest earned.',
      btn1: 'Invest now',
    },
    history: {
      headers: {
        orderNo: '#',
        date: 'Draw started',
        numTicket: 'Numbers drawn',
        totalPrizes: 'Prizes won',
        status: 'Status',
      },
      status: {
        picked: 'Picked',
        closed: 'Closed',
      },
      noHistoryData: 'No data available'
    },
    activeTicket: {
      headers: {
        date: 'Valid from',
        numTicket: 'Number ticket',
        totalPrizes: 'You won',
        ticketType: 'Ticket type',
        investTicket: 'Invest ticket',
        investTicketDesc: 'The Invest ticket is the ticket you earn from investing in Lending Lottery, which can be used for 4 weeks (there’s a new drawing each week).',
        rewardTicket: 'Reward ticket',
        rewardTicketDesc: 'The reward ticket you earn from the lottery prize, or any promotion on the platform which can be used for only one week.',
      },
      noHistoryData: 'No data available'
    },
    investments: {
      termStatusFilter: {
        matched: 'Matched',
        closed: 'Done',
        cancelled: 'Cancelled',
      },
      headers: {
        createdDate: 'Created Date',
        amount: 'Principal',
        totalInterest: 'Total interest',
        interest: 'Interest to date',
        rate: 'Rate',
        term: 'Term',
        endTerm: 'Term ends',
        numTicket: 'Tickets'
      },
      termStatus: {
        created: 'Matched',
        closed: 'Done',
        voided: 'Cancelled',
        pending: 'Pending'
      },
      footer: { desc: 'Showing {start} - {end} of {total} selected entries' },
      noInvestment: 'Invest now for your chance to win up to $10 million',
      dialog: {
        cancelInvestment: {
          messageConfirm: 'Are you sure you want to cancel this investment? You will lose all tickets, winnings, and interest earned.',
          messageConfirmOldRule: 'Are you sure you want to cancel this investment that\'s giving you 2% APR? If you cancel, you\'ll lose all tickets, but still earn 1% APR and the prize to date.',
          confirm: 'Cancel investment',
          cancel: 'Keep investment'
        },
        autoRenewInvestment: {
          messageConfirm: {
            false: 'When your term ends, you can automatically reinvest your principal at the same rate and term to earn another set of lottery tickets. Would you like to reinvest your principal automatically?',
            true: 'Are you sure you want to disable Auto Reinvest? With Auto Reinvest, you earn new lottery tickets every time your term ends, giving you more chances to win the $10 million prize (and other cash rewards, too).'
          },
          confirm: 'Yes',
          cancel: 'Cancel',
          autoRenewInvestmentSuccess: {
            principal: 'Thank you. We will automatically reinvest your principal only at the same rate and term. You can pause auto reinvest or change its settings at any time from your accounts page.',
            disabled: 'Disabled Auto Reinvest successfully'
          },
          autoRenewInvestmentFailed: {
            true: 'Failed to disable Auto Reinvest',
            false: 'Failed to enable Auto Reinvest'
          }
        },
      },
      alert: {
        stopSavingSuccess: 'You successfully cancelled your investment.',
      },
      cancel: 'Cancel',
      autoRenew: 'Auto reinvest',
      heading: `Lending Lottery History`,
      heading_detail: 'Lending Lottery Detail',
      autoRenewDesc: 'Enable Auto Reinvest to reinvest your principal when your term ends and receive new lottery tickets (and more chances to win!).'
    },
    rewards: {
      status: {
        created: 'Pending',
        transferred: 'Paid',
        voided: 'Voided',
        cancelled: 'Canceled',
      }
    },
    tickets: {
      status: {
        created: 'Pending',
        picked: 'Active',
        voided: 'Canceled',
        closed: 'Closed',
        reversed: 'Reversed'
      }
    }
  },
  forum: {
    title: "Community",
    add_reaction: "Add reaction",
    copy_link: "Copy link",
    more_actions: "More actions",
    pin_topic_success: 'Your thread pin successfully',
    unpin_topic_success: 'Your thread unpin successfully',
    cancel: 'Cancel',
    save_changes: "Save changes",
    delete_reply_confirm: "Do you want to detele this message?",
    delete_reply_success_msg: 'Your message was deleted successfully',
    view_more_replies: "View more replies",
    author: "Author",
    cancel_create_topic_confirm: "Do you want to discard this change?",
    save_draft: "Save draft",
    discard_it: "Yes, discard it.",
    saved_topics: 'Saved threads',
    saved_topics_desc: "Threads you’ve saved for later viewing.",
    draft_topics: "Drafts",
    draft_topics_desc: "Continue working on threads you’ve not posted yet.",
    recent_topics: "Recently visited",
    recent_topics_desc: "Return to threads you’ve recently viewed.",
    create_active_topic_success: "Your thread was posted successfully",
    create_draft_topic_success: "You was created a draft thread successfully",
    update_draft_topic_success: "Your draft was updated successfully",
    public_topic_confirm: "Do you want to publish your thread <strong>{title}</strong>?",
    edit: "Edit",
    delete: "Delete",
    update_draft: "Update draft",
    pinned_topics: "Pinned threads",
    reply_in_topic: "replies in thread",
    last_topic: "Last thread: ",
    last_reply: "Last reply: ",
    file_large_too: "Uploaded file does not exceed {size} MB. Please select image another.",
    file_not_found: "Your file isn't exist",
    file_invalid: "Uploaded file is invalid. Please upload a image png, jpg, jpeg.",
    file_upload_fail: "Your file have been upload failed",
    insert: "Insert",
    notify_option_1: "All new threads, comments and replies",
    notify_option_2: "All new threads ",
    notify_option_3: "Only when I'm mentioned",
    all_thread: "All threads",
    unread_only: 'Unread only',
    load_more: "Load more",
    save_thread: "Add to saved threads",
    remove_save_thread: "Remove from save threads",
    share_thread: "Copy link to share thread",
    more_options: "More options",
    members: "Members",
    start_thread: "Start a thread",
    edit_thread: "Edit thread",
    duplicate_thread: "Duplicate thread",
    pin_thread: "Pin thread",
    remove_thread: "Remove thread",
    notification_settings: "Notification settings",
    copy_public_link: "Copy public share link",
    copy_public_link_desc: "Create new article in your drafts section where you can add details or edit article.",
    edit_comment: "Edit comment",
    remove_comment: "Remove comment",
    pin_comment: "Pin comment",
    copy_link_success: "Link copied",
    create_thread: 'Create thread',
    posting_to: "Posting to",
    subject: "Title",
    tags: "Tags",
    posting: "Posting...",
    post: "Post",
    view_drafts: "View drafts",
    delete_draft: "Delete draft",
    delete_draft_confirm: "You choose to delete a saved draft. You can't recover your draft once you delete it.",
    delete_thread: "Delete your thread",
    delete_thread_confirm: "Are you sure you want to delete your thread? You can’t undo this action",
    delete_draft_success: "Draft deleted",
    draft_saved: "Draft saved",
    edit_draft: "Edit draft",
    start_first_thread: "Start the first thread",
    start_first_thread_desc: "Create new article in your drafts section where you can add details or edit article.",
    welcome: "Welcom to the {forum_name} forum",
    welcome_desc: "Get set up and start having discussions here.",
    empty_desc: "No data available",
    saving: "Saving...",
    update: "Update",
    comment: "Comment",
    term_and_conditions: "Term & Conditions",
    threads: "threads",
    comments: "comments",
    activities: "Activities",
    search_results: "Search results",
    related_threads: "Related threads",
    all_threads: "All threads",
    all_threads_desc: "View all the current threads in the MyConstant forum.",
    turn_on_notification: "Turn on notifications",
    turn_off_notification: "Turn off notifications",
    turn_on_notification_desc: "You’ll receive notifications whenever someone replies to or comments on this thread.",
    turn_off_notification_desc: "You will don't recieve any notify on this thread.",
    delete_thread_success: "Your thread was deleted successfully.",
    popular_threads: "Popular threads",
    reply_comment: "Reply comment",
    my_topics: "My threads",
    my_topics_desc: "Get back to threads you've created.",
    validate_max_length_title: "Length must be less than 255 characters.",
    update_thread_success: "Your thread was updated successfully.",
    log_in: "Login",
    sign_up: "Create account",
    reply: "Reply",
    insert: "Insert",
    comment_on: "Comment on",
    add_favorite: "Add to favorite",
    download: "Download",
    gallery_view: "Gallery view",
    remove: "Remove",
    favorite: "Favorite",
    remove_favorite: "Remove favorite",
    prev_thread: "Previous thread",
    next_thread: "Next thread",
    notify_about: "Notify me about:",
    notify_comments: "All comments posted on this thread",
    notify_mention: "When I'm @mentioned, someone comments on my thread, or replies to my comment",
    notify_off: "Turn off notifications",
    save: "Save",
    comment_on: "Comment on",
    due_date: 'Due date',
    shedules: "Shedules"
  },
  cryptoSwap: {
    mctFeeExplain: 'Fee will be charge on your MCT balance. If MCT balance cannot cover fee, we\'ll automatically charge partially.',
  },
  short_selling: {
    crypto_balance: 'Crypto balance',
    short_selling_balance: 'Short-selling balance',
    top_up: 'Top up',
    recall: 'Recall',
    topup_success: "Done! You successfully transferred {amount} USD to your {pair} short-selling balance",
    recall_success: "Done! You successfully transferred {amount} USD from your {pair} short-selling balance"
  },
  submit_kyc: {
    step_1_title: 'One Final Step',
    step_1_subtitle: 'Before you can deposit and start earning, we need to do a quick ID check. Click the button below to get started.',
    step_1_desc: "To help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial instituitions to obtain, verify, and record information that identifies each person who opens an account. We will ask for your name, SSN, date of birth, and other information that will allow us to identify you. We may also ask for a copy of your ID or other identifying documents.",
    step_2_title: 'Are you a US citizen?',
    step_2_desc: 'This helps us decide the best way to verify your identity.',
    step_3_title: "Proof of ID",
    step_3_desc: "To comply with Know Your Customer (KYC) regulations, we need some information from you to verify your ID.",
    step_3_nonus_title: "Enter your ID details",
    step_3_nonus_desc: "Please check your information carefully and correct any errors to avoid delays to your application",
    step_4_title: 'Upload a photo of your ID',
    step_4_desc: 'Choose your ID type from the options below.',
    step_5_title: 'Enter your ID details',
    step_5_desc: 'Please check your information carefully and correct any errors to avoid delays to your application',
    step_6_title: "Enter your phone number",
    step_6_desc: "Your phone number is for customer support and account security only.",
    step_7_title: "Proof of address",
    step_7_desc: "Please provide your address below. Make sure it matches the address in the other documents.",
    step_8_title: "Upload proof of address",
    step_8_desc: "<p>You can upload a bank or credit card statement, phone bill, or a gas, water, or electricity bill. The address must match the one you entered on your account.</p> <p>Make sure the documents are dated within the last 90 days, and in JPG, JPEG, PNG or PDF form — smaller than 10MB.</p><p>Select a file / Snap a photo</p>",
    step_9_title: "We're checking your documents now!",
    step_9_desc: "Your documents and information have been well received. The verification process might take up to 7 business days. We’ll notify you the moment it’s complete.",
    step_10_title: "Continue Your Verification on Another Device",
    step_11_desc: "Please choose the state where you're taxed.",
    front_photo_title: 'The front of your ID',
    front_photo_desc: 'Please take a photo or scan the front of your ID and upload it here.',
    back_photo_title: 'The back of your ID.',
    back_photo_desc: 'Please take a photo or scan the back of your ID and upload it here. ',
    drag_drop_photo: 'Drag & drop or upload an image of {id_photo} here.',
    camera_instruction_desc: 'Place {id_photo} in the frame.',
    selfie_title: 'Upload a selfie',
    selfie_desc: 'Please take a photo or scan your selfie and upload it here.',
    step_4_select: 'Choose your ID type from the options below.',
    start_kyc_verify: 'Start KYC Verification',
    ssn_label: "What is your SSN (Social Security Number)?",
    choose_file: 'Choose file...',
    take_photo: 'Take photo',
    retake_photo: 'Retake photo',
    select: 'Select',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    upload_image: 'Upload image',
    continue: 'Continue',
    clear: 'Clear',
    webcam_error: 'We can not detect your camera. Please choose Upload instead.',
    first_name: 'First name',
    middle_name: "Middle name (Optional)",
    last_name: 'Last name',
    first_name_placeholder: 'Please enter your first name',
    last_name_placeholder: 'Please enter your last name',
    middle_name_placeholder:  'Please enter your middle name (optional)',
    dob: 'Date of birth',
    phone_number_label: " What is your phone number? ",
    country: 'Country',
    state: 'State/Province/Region',
    address: 'Address',
    city: 'City',
    proof_of_address_photo: "Photo of your proof of address",
    proof_of_address_upload: "Take a photo of your proof of address or upload it here.",
    ok: 'Ok',
    use_another_device: 'Continue verification another device',
    email_title: "Receive Link on Your Email",
    email_desc: "Enter your email address and we will send you a link to complete this verification on another device",
    qrcode_title: "QR Code",
    qrcode_desc: "Scan the QR code below and you’ll get a link to continue this verification on your mobile device.",
    link_title: "Link",
    link_desc: "Here is your link to complete the verification. Copy it and save to use anytime your want",
    your_email_address: "Your email address",
    send_link: "Send link",
    email_placeholder: "Please enter your email address",
    submitting: 'Submitting...',
    resent: 'Re-send',
    sent: 'Sent',
    date_of_birth: 'Date of Birth',
    zip_code: 'Zip code',
    review_image: 'Review image',
    try_difference_image: 'Upload a new image',
    look_good: 'Look good',
    updating: "Updating...",
    submit: 'Submit',
    passport_title: "Upload the bio page (photo page) of your passport",
    passport_desc: "Please photograph or scan a copy of the bio page of your passport.",
    save: "Save",
    id_number: "ID Number",
    capture_camera_instruction: '<strong>Ensure that the document meet all the standard below:</strong><ul><li>Readable, clear and well-lit</li><li>Not reflective, not blurry</li><li>No black and white images, no edited</li><li>Document is placed against a solid-colored background.</li></ul>'
  }
};

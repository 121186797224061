import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import messages from './messages';

const IntlCustomProvider = ({ children,  }) => {
  const finalLang = 'en';
  return (
    <IntlProvider locale={finalLang} messages={messages[finalLang]}>
      {children}
    </IntlProvider>
  );
};

const mapState = state => ({
  lang: state.langReducer.lang,
  countryCode: state.app.ipInfo?.country_code,
});
export default compose(
  withRouter,
  connect( mapState ),
)(IntlCustomProvider);
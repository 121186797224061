import en from './en';
import { convertLongKeyValues } from '../lib';

const messages = {
  en: Object.assign({}, convertLongKeyValues(en)),
};

if (APP_ENV.isLive) {
  messages.en = Object.assign({}, messages.en);
}

export default messages;
